import React from "react";
import ShareField from "#components/common/Share/ShareField";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { toRem } from "#shared/utils";
import useTranslation from "#utils/useTranslation";
import { Box, IconButton, Tooltip, Typography, styled } from "@mui/material";
import { ShareConfig, ShareTarget } from "#customTypes/share";

interface Props {
  config: ShareConfig;
  onSocialClick: (target: ShareTarget) => void;
}

export default function Share({ config, onSocialClick }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Typography variant="label2" fontWeight="420" mb={3}>
          {t("ui.modals.share_dialog.share_with_people")}
        </Typography>
        <Box display="flex" justifyContent="center" flexWrap="wrap" gap={4} mb={6}>
          {config.targets.map(({ id, label, icon, color }) => (
            <Tooltip key={id} title={label} placement="top">
              <SocialButton
                color="primary"
                sx={{ backgroundColor: color ? `${color} !important` : undefined }}
                onClick={() => onSocialClick(id)}
              >
                {icon}
              </SocialButton>
            </Tooltip>
          ))}
        </Box>
      </Box>
      <Box pb={3}>
        <Typography variant="label2" fontWeight="420" mb={3}>
          {t("ui.modals.share_dialog.or_copy_link")}
        </Typography>
        <ShareField url={config.url || ""} />
      </Box>
    </>
  );
}

const SocialButton = styled(IconButton)`
  width: ${toRem(40)};
  height: ${toRem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${rounding.full};
  padding: ${toRem(8)};
  color: ${colors.text.secondaryDark};
  flex-wrap: wrap;

  svg {
    color: ${colors.base.white};
    width: ${toRem(20)};
    height: ${toRem(20)};
  }
`;
