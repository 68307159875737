import React from "react";
import { UseVideoOutputProps, VideoProps } from "../../../../types";
import Wrapper from "./styled";
import classNames from "classnames";
import PlayVideoButton from "../../../../assets/svgs/play.svg?react";

type VideoInputProps = { useVideo: UseVideoOutputProps } & VideoProps;
/**
 * Remove after merging PLG9
 * @deprecated
 */
export default function Video({
  useVideo: { videoRef, useIsPlaying, firstPlay, togglePlay },
  videoSrc,
  editMode,
  videoWidth,
  videoHeight,
  manualPlay,
}: VideoInputProps) {
  const [isPlaying, setIsPlayingVideo] = useIsPlaying;
  return (
    <Wrapper
      className="video"
      onClick={() => {
        if (!manualPlay && !editMode) {
          togglePlay();
        }
      }}
      height={videoHeight}
      width={videoWidth}
    >
      <video
        ref={videoRef}
        key={videoSrc}
        width="100%"
        height="100%"
        muted
        preload="true"
        onLoadedData={firstPlay}
        onEnded={() => setIsPlayingVideo(false)}
        onPause={() => setIsPlayingVideo(false)}
        autoPlay
        loop
        playsInline
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!editMode && !isPlaying && (
        <div className={classNames("play", { isPlaying })}>
          <PlayVideoButton />
        </div>
      )}
    </Wrapper>
  );
}
