import { ChannelsApi } from "#apis/channels/api";
import { ChannelsQueryKeys } from "#apis/channels/queryKeys";
import { ChannelListItem } from "#customTypes/channel";
import { AppQueryOptions } from "#customTypes/reactQuery";
import { useQuery } from "@tanstack/react-query";

interface Props {
  options?: AppQueryOptions<ChannelListItem[], string[]>;
}

export default function useGetMyOwnedChannels({ options }: Props = {}) {
  return useQuery({
    queryKey: ChannelsQueryKeys.owned,
    queryFn: () => ChannelsApi.getOwnedChannels(),
    staleTime: Infinity,
    ...options,
  });
}
