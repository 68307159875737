import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";
import colors from "../../../theme/colors";

const inputBaseOverrides = (theme: Theme): ComponentOverrides<"MuiInputBase"> => ({
  styleOverrides: {
    root: {
      transition: "background-color 0.2s ease-in-out",
      color: colors.text.secondaryDark,
      "&.MuiOutlinedInput-root-MuiSelect-root.MuiInputBase-root.MuiOutlinedInput-root": {
        backgroundColor: "#F2F2F2!important",
      },
      ".MuiInputBase-inputMultiline": {
        fontSize: theme.typography.body4.fontSize,
        lineHeight: theme.typography.body4.lineHeight,
        padding: 0,
      },
      "&.MuiOutlinedInput-root": {
        background: colors.base.white,

        "&.MuiInputBase-adornedEnd": {
          paddingRight: "8px",
        },
      },
    },
    sizeSmall: {
      minHeight: "40px",
    },
  },
});

export default inputBaseOverrides;
