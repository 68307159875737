import React from "react";
import BuilderDesktopSteps from "#containers/BriefBuilder/Header/Steps/components/BuilderDesktopSteps";
import BuilderMobileSteps from "#containers/BriefBuilder/Header/Steps/components/BuilderMobileSteps";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { Divider } from "@mui/material";
import NavigationItem from "#containers/layout/Navigation/components/NavigationItem";
import { NavigationAction } from "#constants/navigation";
import IconExit from "#assets/svgs/exit.svg?react";
import { WrapperFilter } from "#components/pages/Feed/ChannelsSidebar/commonStyled";
import useTranslation from "#utils/useTranslation";
import { useBriefBuilderNavigate } from "#containers/BriefBuilder/BriefBuilderNavigateProvider";
import { BriefBuilderStep } from "#customTypes/briefBuilder";
import useBriefBuilderSteps from "#containers/BriefBuilder/hooks/useBriefBuilderSteps";

interface Props {
  onDiscard: () => void;
}

export default function BriefBuilderSteps({ onDiscard }: Props) {
  const { t } = useTranslation();
  const { isTabletOrMobile } = useMediaQueryProvider();

  const { getStepUrl } = useBriefBuilderSteps();
  const { builderNavigate } = useBriefBuilderNavigate();

  const handleNavigateToStep = (step: BriefBuilderStep) => {
    const url = getStepUrl(step);

    if (!url) {
      throw new Error(`No URL found for step ${step}`);
    }

    builderNavigate(url);
  };

  if (isTabletOrMobile) {
    return (
      <BuilderMobileSteps onStepClick={handleNavigateToStep}>
        <Divider sx={{ my: 4 }} />
        <WrapperFilter>
          <NavigationItem
            item={{
              id: NavigationAction.exit_builder,
              name: t("pages.brief_builder.exit_builder"),
              LogoActive: () => <IconExit />,
              LogoInactive: () => <IconExit />,
            }}
            handleNavigationItemClick={onDiscard}
          />
        </WrapperFilter>
      </BuilderMobileSteps>
    );
  }

  return <BuilderDesktopSteps onStepClick={handleNavigateToStep} />;
}
