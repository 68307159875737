import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";

const formHelperTextOverrides = (
  theme: Theme
): ComponentOverrides<"MuiFormHelperText"> => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.label3.fontSize,
      lineHeight: theme.typography.label3.lineHeight,
      fontWeight: 500,
      marginLeft: 0,
      marginTop: "6px",
    },
  },
});

export default formHelperTextOverrides;
