import { ComponentOverrides } from "../../types";
import colors from "../../../theme/colors";

const tableHeadOverrides = (): ComponentOverrides<"MuiTableHead"> => ({
  styleOverrides: {
    root: {
      backgroundColor: colors.base.surface1,
    },
  },
});

export default tableHeadOverrides;
