// modules
import React from "react";
import { useLocation } from "react-router-dom";

// components
import Header from "#components/common/layout/Header";

import { useDispatch } from "react-redux";
import { setLocale } from "#features/locale/localeSlice";
import { AppDispatch } from "../../../store";
import GodViewBanner from "#containers/GodView/GodViewBanner/GodViewBanner";

type HeaderProps = {
  isProtectedPage?: boolean;
  isLoginPage?: boolean;
};

export default function HeaderContainer({
  isProtectedPage = false,
  isLoginPage,
}: HeaderProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const isSigningPage = pathname.includes("signin");

  return (
    <>
      <Header
        isProtectedPage={isProtectedPage}
        isSigningPage={isLoginPage !== undefined ? isLoginPage : isSigningPage}
        onSelectLocale={(locale) => dispatch(setLocale(locale))}
      />
      <GodViewBanner />
    </>
  );
}
