import { styled } from "@mui/material/styles";

export default styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
`;
