import EmptyState from "#components/common/EmptyState";
import { useBriefBuilder } from "#containers/BriefBuilder/BriefBuilderProvider";
import {
  useFetchBuilderLockQuery,
  useReleaseBuilderLockMutation,
  useRequestBuilderLockQuery,
} from "#features/briefBuilder/briefBuilderAPI";
import useTranslation from "#utils/useTranslation";
import React from "react";
import { ReactNode, useEffect } from "react";
import IconLock from "#assets/svgs/lock-solid.svg?react";
import { useSelectorTyped } from "#utils/index";
import { selectUser } from "#features/user/userSlice";
import { styled } from "@mui/material";

interface Props {
  children: ReactNode;
}

const EXTEND_LOCK_INTERVAL = 1 * 60 * 1000; // 1 minute

export default function BuilderLock({ children }: Props) {
  const { t } = useTranslation();
  const { brief, briefRef } = useBriefBuilder();
  const user = useSelectorTyped(selectUser);

  const tabId = user.profile.userRef || "";

  const { data: lockInfo } = useFetchBuilderLockQuery(briefRef, { skip: !brief });

  const isLocked = !lockInfo || (lockInfo.locked && lockInfo.user?.tabId !== tabId);

  useRequestBuilderLockQuery(
    { briefRef, tabId },
    { skip: isLocked || !brief, pollingInterval: EXTEND_LOCK_INTERVAL }
  );

  const [releaseLock] = useReleaseBuilderLockMutation();

  useEffect(() => {
    // Release lock on unmount
    return () => {
      releaseLock({ briefRef, tabId });
    };
  }, []);

  if (isLocked) {
    return (
      <Wrapper>
        <EmptyState
          title={t("pages.brief_builder.lock.title")}
          description={t(
            lockInfo?.user?.id !== user.profile.userId
              ? "pages.brief_builder.lock.description_user"
              : "pages.brief_builder.lock.description",
            {
              user: lockInfo?.user?.displayName,
            }
          )}
          color="dark"
          icon={<IconLock />}
        />
      </Wrapper>
    );
  }

  return children;
}

const Wrapper = styled("div")`
  height: calc(100dvh - var(--header-height));
  display: flex;
  justify-content: center;
  align-items: center;
`;
