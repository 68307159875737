import React, { ReactNode } from "react";
import { Typography } from "@mui/material";
import CustomModal from "../../ui/Modal";
import ModalContent from "../../ui/Modal/ModalContent";
import ModalFooter from "../../ui/Modal/ModalFooter";
import ModalHeader from "../../ui/Modal/ModalHeader";
import colors from "../../../config/theme/colors";

interface Props {
  title: string;
  content: string | ReactNode;
  cancelButtonText?: string;
  isOpen: boolean;
  onClose: (success: boolean) => void;
  onClosed?: () => void;
  children?: ReactNode;
}

export default function InfoDialog({
  title,
  content,
  cancelButtonText,
  isOpen,
  onClose,
  onClosed,
  children,
}: Props) {
  const handleClose = () => onClose(false);

  return (
    <CustomModal
      onClose={handleClose}
      onClosed={onClosed}
      open={isOpen}
      Header={<ModalHeader title={title} />}
      Footer={
        <ModalFooter
          cancelButtonText={cancelButtonText}
          onCancelButtonClick={handleClose}
        />
      }
      Content={
        <ModalContent>
          <Typography variant="body4" color={colors.text.secondaryDark}>
            {content}
          </Typography>
          {children}
        </ModalContent>
      }
    />
  );
}
