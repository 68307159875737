import React from "react";
import BuilderSelectItem, {
  BuilderSelectItemProps,
} from "#components/pages/BriefBuilder/common/BuilderSelectItem";
import { FormField } from "#shared/components/ui";
import { Box, FormHelperText, styled } from "@mui/material";

interface RadioProps {
  type: "radio";
  value: string;
  onChange: (value: string) => void;
}

interface CheckboxProps {
  type: "checkbox";
  value: string[];
  onChange: (value: string[]) => void;
}

type Props = {
  items: BuilderSelectItemProps[];
  label?: string;
  error?: string;
  disabled?: boolean;
} & (RadioProps | CheckboxProps);

export default function BuilderSelect({
  items,
  label,
  error,
  disabled,
  type = "radio",
  value,
  onChange,
}: Props) {
  const handleChange = (val: string) => {
    if (type === "radio") {
      if (value === val) {
        return;
      }
      (onChange as RadioProps["onChange"])(val);
    } else {
      let newValue: string[];
      if (value.includes(val)) {
        newValue = (value as string[]).filter((v) => v !== val);
      } else {
        newValue = [...value, val];
      }
      (onChange as CheckboxProps["onChange"])(newValue);
    }
  };

  return (
    <FormField label={label}>
      <Wrapper>
        {items.map((item) => (
          <BuilderSelectItem
            key={item.value}
            {...item}
            type={type}
            selected={
              type === "radio" ? item.value === value : value.includes(item.value)
            }
            disabled={disabled || item.disabled}
            onClick={handleChange}
          />
        ))}
      </Wrapper>
      {error && (
        <FormHelperText className="muiSelect" error>
          {error}
        </FormHelperText>
      )}
    </FormField>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  > span {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;
