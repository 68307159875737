import React, { ForwardedRef, forwardRef } from "react";
import { styled } from "@mui/material/styles";

type WrapperItemProps = {
  id: string;
  children: React.ReactNode;
};

const Wrapper = styled("div")`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  //[SC-12679] avoid dark border at the top
  & .media {
    border-bottom: 0.063rem solid white;
  }
`;

const WrapperItem = forwardRef(
  (props: WrapperItemProps, itemRef: ForwardedRef<HTMLDivElement>) => {
    const { id, children } = props;
    return (
      <Wrapper id={id} className="item" ref={itemRef}>
        {children}
      </Wrapper>
    );
  }
);
WrapperItem.displayName = "WrapperItem";
export default WrapperItem;
