import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";

const inputLabelOverrides = (theme: Theme): ComponentOverrides<"MuiInputLabel"> => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.label2.fontSize,
      lineHeight: theme.typography.label2.lineHeight,
    },
  },
});

export default inputLabelOverrides;
