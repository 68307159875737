import { useLocation } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactGA from "react-ga4";

export default function useAnalytics(
  trackingId: string
): Dispatch<SetStateAction<boolean | undefined>> {
  const [cookieConsentChoice, setCookieConsentChoice] = useState<boolean | undefined>();
  const location = useLocation();

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent") || "false";

    if (!trackingId || !(cookieConsentChoice || JSON.parse(cookieConsent))) return;

    ReactGA.initialize(trackingId);
    //need for development
    /*ReactGA.initialize(trackingId, {
      gaOptions: {
        debug_mode: true,
        testMode: true,
      },
    });*/
    let currentTitle = "My Bulbshare";
    if (location.pathname.startsWith("/feed/brief-")) {
      currentTitle = "Brief Screen";
    } else if (location.pathname.startsWith("/feed")) {
      currentTitle = "Brief Feed Screen";
    } else if (location.pathname.startsWith("/channel")) {
      currentTitle = "Brand Profile Screen";
    } else if (location.pathname.startsWith("/survey")) {
      currentTitle = "Survey Screen";
    }

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: currentTitle,
    });
  }, [location.pathname, cookieConsentChoice]);

  return setCookieConsentChoice;
}
