import { styled } from "@mui/material";

const Wrapper = styled("div")<{ height?: string }>(
  ({ height = "100%" }) => `
   width: 100%;
   height: ${height};
   display: flex;
   overflow: scroll;
   flex-direction: column;
`
);

const WrapperGallery = styled("div")`
  width: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 0.625rem;

  & .video {
    position: relative;
    & > video {
      object-fit: cover;
      border-radius: 0.625rem;
    }
  }

  & .wrapperImage:after,
  .video:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0.625rem;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }

  & .wrapperImage:hover:after,
  .video:hover:after {
    opacity: 1;
    cursor: pointer;
  }
`;

const WrapperImage = styled("div")<{
  imagePath: string;
  imageWidth?: string;
  imageHeight?: string;
}>(
  ({ imagePath, imageWidth = "100%", imageHeight }) => `
        display: flex;
        justify-content: center;
        position: relative;
        object-fit: cover;
        background-size: cover;
        background-position: center;
        flex: 1;
        min-height: ${imageHeight};
        align-items: stretch;
        width: ${imageWidth};
        flex-basis: ${imageWidth};
        flex-grow: 1;
        background: url('${imagePath}') center/cover;
        border-radius: .625rem;

    & .overlay {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Avenir-heavy;
      bottom: 0;
      border-radius: .625rem;
      color: white;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;

      & .text {
        justify-content: center;
        margin: 1rem;
      }
    }
`
);

const WrapperLoading = styled("div")<{ width: string }>(
  ({ width }) => `
    display: flex;
    position: absolute;
    font-family: Avenir-heavy;
    align-items: center;
    justify-content: center;
    z-index: 2;
    bottom: 0;
    border-radius: .625rem;
    color: white;
    width: ${width};
    height: 100%;
      
      & .text {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 1rem;
        padding: 1rem;
      }
`
);

export { Wrapper, WrapperGallery, WrapperImage, WrapperLoading };
