import { useMemo } from "react";
import { useParams } from "react-router";
import { OrganisationStatus, OrganisationPositionType } from "../../../types/types";
import { useSelectorTyped } from "#utils/index";
import { selectBusinessSettings } from "#features/businessSettings/businessSettingsSlice";
import { useGlobalAppProvider } from "#containers/Global/GlobalAppProvider";

export default function useSelectedOrganisation() {
  const { positions } = useGlobalAppProvider();
  const params = useParams() as { organisationId?: string };

  const businessSettings = useSelectorTyped(selectBusinessSettings);

  const defaultOrganisationId = useMemo(() => {
    if (!positions) return -1;

    // If user has previously selected organisation, return as default organisation
    if (
      businessSettings.selectedOrganisationId !== null &&
      positions.find(
        (c) =>
          c.organisation.id === businessSettings.selectedOrganisationId &&
          c.organisation.status === OrganisationStatus.VERIFIED
      )
    ) {
      return businessSettings.selectedOrganisationId;
    }

    // If user has any owned organisation, return as default organisation
    const firstOwnedOrganisation =
      positions.find(
        (c) =>
          c.organisation.status === OrganisationStatus.VERIFIED &&
          [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(c.id)
      )?.organisation.id ?? -1;

    if (firstOwnedOrganisation !== -1) {
      return firstOwnedOrganisation;
    }

    // Return first verified organisation as default organisation
    return (
      positions.find((c) => c.organisation.status === OrganisationStatus.VERIFIED)
        ?.organisation.id ?? -1
    );
  }, [positions, params.organisationId]);

  const organisationId = useMemo(() => {
    if (!positions || !params.organisationId) return defaultOrganisationId;

    return (
      positions?.find((c) => c.organisation.id === Number(params.organisationId))
        ?.organisation.id ?? -1
    );
  }, [positions, params.organisationId, defaultOrganisationId]);

  const organistaion = useMemo(() => {
    if (!positions) return undefined;

    return positions.find((c) => c.organisation.id === organisationId)?.organisation;
  }, [positions, organisationId]);

  return { defaultOrganisationId, organisationId, organistaion };
}
