import { Theme } from "@mui/material";
import colors from "../../theme/colors";
import rounding from "../../theme/rounding";
import { ComponentOverrides } from "../types";

const chipOverrides = (theme: Theme): ComponentOverrides<"MuiChip"> => ({
  styleOverrides: {
    root: {
      borderRadius: rounding.default,
      fontSize: theme.typography.label3.fontSize,
      lineHeight: theme.typography.label3.lineHeight,
      fontFamily: "Avenir",
      fontWeight: 500,
    },
    label: {
      padding: "8px 10px",
    },
    filledPrimary: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    filledSecondary: {
      color: colors.text.secondaryDark,
      backgroundColor: colors.grey[100],
    },
    colorSecondary: {
      color: colors.text.tertiaryDark,
    },
    deleteIcon: {
      color: colors.base.white,
      fontSize: "0.875rem",
    },
  },
});

export default chipOverrides;
