declare const window: any;

interface AppLinkBriefUrlArgs {
  oneLinkURL: string;
  briefRef: string | null;
  token: string | null | undefined;
}

export function getAppLinkBriefUrl(args: AppLinkBriefUrlArgs): string | null {
  if (!window.AF_SMART_SCRIPT) return null;
  const AF_SMART_SCRIPT = window.AF_SMART_SCRIPT;

  if (typeof AF_SMART_SCRIPT.generateOneLinkURL !== "function") return null;

  const mediaSource = {
    keys: ["wta_brief"],
    defaultValue: "brief",
  };
  const deepLinkValue =
    args.briefRef !== null
      ? {
          keys: [],
          defaultValue: `brief/${args.briefRef}`,
        }
      : undefined;

  let afCustom = null;
  if (args.token) {
    afCustom = [
      {
        paramKey: "deep_link_sub1",
        keys: [],
        defaultValue: args.token,
      },
    ];
  }

  const result = AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL: args.oneLinkURL,
    afParameters: {
      mediaSource: mediaSource,
      deepLinkValue: deepLinkValue,
      afCustom: afCustom,
    },
  });

  if (!result || !result.clickURL) return null;

  return result.clickURL;
}

export function displayAppLinkBriefQRCode(eid: string): void {
  if (!window.AF_SMART_SCRIPT) return;
  const AF_SMART_SCRIPT = window.AF_SMART_SCRIPT;

  if (typeof AF_SMART_SCRIPT.displayQrCode !== "function") return;

  AF_SMART_SCRIPT.displayQrCode(eid);
}
