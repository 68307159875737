import { useCreateTheme } from "#components/common/theme";
import ChannelDetails from "#components/pages/ChannelDetails";
import useSelectedChannel from "#components/pages/ChannelDetails/useSelectedChannel";
import ResidentialLayout from "#containers/layout/ResidentialLayout";
import { Box, ThemeProvider } from "@mui/material";
import React from "react";
import { Navigate, Outlet } from "react-router";

export default function WrapperChannelRoute() {
  const { data, isLoading, isFetching, isError } = useSelectedChannel();

  const theme = useCreateTheme({ primaryColor: data?.channelsThemes.primaryColor });

  const channelDataFetched = !isLoading && !isFetching && !!data;
  const isRemoved = data && data.isRemoved;

  if (isError || isRemoved) {
    return <Navigate to="/" />;
  }

  return (
    <ResidentialLayout fullWidth>
      <ThemeProvider theme={theme}>
        <ChannelDetails channel={data} isLoading={isLoading || isFetching} />
        {channelDataFetched && (
          <React.Suspense>
            <Box p={[4, 0]}>
              <Outlet />
            </Box>
          </React.Suspense>
        )}
      </ThemeProvider>
    </ResidentialLayout>
  );
}
