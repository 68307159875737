import { ComponentOverrides } from "../../types";
import colors from "../../../theme/colors";
import { Theme } from "@mui/material";

const accordionSummaryOverrides = (
  theme: Theme
): ComponentOverrides<"MuiAccordionSummary"> => ({
  styleOverrides: {
    root: {
      padding: "8px 16px",
      backgroundColor: colors.base.white,
      border: "none",
      minHeight: "3rem",

      "&.Mui-expanded": {
        backgroundColor: colors.base.surface1,
        minHeight: "3rem",
      },
    },
    content: {
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      margin: 0,

      "&.Mui-expanded": {
        margin: 0,
      },
    },
  },
});

export default accordionSummaryOverrides;
