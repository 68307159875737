import { Box, Skeleton } from "@mui/material";
import React from "react";

export default function ChannelsSkeleton() {
  const skeleton = (
    <Box display="flex" flexDirection="row" alignItems="center" gap={3} mb={2}>
      <Skeleton variant="circular" animation="wave" width={32} height={32} />
      <Box flex="1">
        <Skeleton variant="text" animation="wave" width="30%" height={24} />
        <Skeleton variant="text" animation="wave" width="50%" height={18} />
      </Box>
    </Box>
  );

  return (
    <Box mb={3}>
      {[...Array(5)].map((_, index) => (
        <React.Fragment key={index}>{skeleton}</React.Fragment>
      ))}
    </Box>
  );
}
