import useToast from "#shared/hooks/useToast";
import useTranslation from "#utils/useTranslation";
import { useCallback } from "react";

function useCopyToClipboard() {
  const { t } = useTranslation();
  const toast = useToast();

  const copy = useCallback(async (value: string, successText?: string) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(value);
        toast.success({
          message: successText || t("ui.toasts.copy_to_clipboard"),
        });

        return;
      }
      // eslint-disable-next-line no-empty
    } catch {}

    toast.error({ message: t("ui.toasts.copy_to_clipboard_error") });
  }, []);

  return copy;
}

export default useCopyToClipboard;
