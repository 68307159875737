import { css, styled } from "@mui/material";
import theme from "#shared/config/theme";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { alphaColor, toRem } from "#shared/utils";

export const ChannelHeaderWrapper = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${toRem(260)};
  border-radius: ${theme.rounding.default};
  border: 1px solid ${theme.colors.base.outline1};
  overflow: hidden;

  --channel-header-height: ${toRem(140)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    border-radius: 0;
    border: none;
  }
`;

export const ChannelBannerWrapper = styled("div", {
  shouldForwardProp: (name) => name !== "placeholder",
})<{ placeholder?: boolean }>(
  ({ theme, placeholder }) => css`
    width: 100%;
    height: var(--channel-header-height);
    background: rgba(241, 241, 242, 0.9);

    ${placeholder &&
    css`
      background: ${theme.palette.primary.main};
      background: linear-gradient(
        190deg,
        ${theme.palette.primary.main} 0%,
        ${theme.palette.primary.light} 100%
      );
    `}
  `
);

export const ChannelBannerImage = styled("img")`
  width: 100%;
  height: var(--channel-header-height);
  object-fit: cover;
`;

export const ChannelLogoWrapper = styled("div", {
  shouldForwardProp: (name) => name !== "placeholder",
})<{ placeholder?: boolean }>(
  ({ theme, placeholder }) => css`
    position: absolute;
    top: calc(var(--channel-header-height) - ${toRem(30)});
    left: ${toRem(20)};
    width: ${toRem(60)};
    height: ${toRem(60)};
    border-radius: ${rounding.full};
    background: ${colors.base.surface1};
    box-shadow: 0px 0px 0px 3px ${alphaColor(colors.base.white, 0.5)};
    overflow: hidden;
    z-index: 1;

    ${placeholder &&
    css`
      background-color: ${theme.palette.primary.main};
    `}

    & > div {
      width: 100%;
      height: 100%;
    }
  `
);

export const ChannelContentWrapper = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${toRem(40)} ${toRem(20)} ${toRem(20)} ${toRem(20)};
`;

export const ChannelActions = styled("div")`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: ${toRem(32)};
`;
