import { styled } from "@mui/material/styles";

type WrappedItemsProps = {
  items_count: number;
  dimensions_container: {
    width: number;
    height: number;
  };
  background?: string;
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const WrapperCarousel = styled("div")`
  width: 100%;
  height: 100%;
`;

const WrapperItems = styled("div")(
  ({
    dimensions_container,
    items_count,
    background = "black",
  }: WrappedItemsProps) => `
    width: ${dimensions_container.width * items_count}px;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    background-color: ${background};
    
    & > .item {
        display: inline-flex;
        width: ${dimensions_container.width}px;
        height: 100%;
    }
`
);

const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  gap: 0.2rem;

  button {
    cursor: pointer;
    padding: 0.4rem;
    margin: 0px;
    border: none;
    background: transparent;
  }
`;

export { Wrapper, WrapperItems, WrapperCarousel, WrapperButtons };
