import { styled } from "@mui/material/styles";
import baseTheme from "../../../../config/theme";
import { alphaColor } from "../../../../utils";

const DropzoneWrapper = styled("label")<{
  hasError?: boolean;
  showOverlay?: boolean;
  disabled?: boolean;
}>(({ theme, hasError, showOverlay, disabled }) => {
  const disabledStyles = disabled ? `pointer-events: none;` : "";
  const overlayStyles = showOverlay
    ? `
      border: none;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${baseTheme.colors.base.transparent};
        transition: background 0.2s ease-in-out;
      }

      &:hover {
        &:after {
          background: ${alphaColor(baseTheme.colors.base.white, 0.1)};
        }
      }
  `
    : "";

  return `
    --dropzone-border-color: ${
      hasError ? baseTheme.colors.error.default : baseTheme.colors.input.border
    };
  
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${baseTheme.colors.input.background};
    border: 1px solid var(--dropzone-border-color);
    border-radius: ${baseTheme.rounding.default};
    padding: 1.875rem;
    transition: border-color 0.2s ease-in-out;
    cursor: pointer;
  
    ${disabledStyles}
  
    
  
    &:hover {
      border-color: ${theme.palette.primary.main};
    }
  
    ${overlayStyles}
  
    input[type="file"] {
      display: none;
    }
  
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  `;
});

export { DropzoneWrapper };
