import { client } from "#apis/axios";
import {
  AdminOrganisation,
  SearchOrganisationsRequest,
  SearchOrganisationsResponse,
} from "#customTypes/admin";

export namespace SuperAdminApi {
  const BASE_PREFIX = "/superadmin";

  export const searchOrganisations = async (
    params: SearchOrganisationsRequest
  ): Promise<SearchOrganisationsResponse> => {
    const { data } = await client.get<SearchOrganisationsResponse>(
      `${BASE_PREFIX}/search-organisations`,
      { params }
    );

    return data;
  };

  export const getOrganisation = async (id: number): Promise<AdminOrganisation> => {
    const { data } = await client.get<AdminOrganisation>(
      `${BASE_PREFIX}/organisation/${id}`
    );

    return data;
  };
}
