import React, { useRef } from "react";
import { AIChatTemplateForm } from "#components/common/AIChat/hooks/useAIChatTemplateForm";
import { FormFields, Slider, TextField } from "#shared/components/ui";
import { useFormContext } from "#shared/context/FormContext";
import useTranslation from "#utils/useTranslation";
import BuilderMultiselect from "#components/pages/BriefBuilder/common/BuilderMultiselect";
import useBuilderGenders from "#components/pages/BriefBuilder/Audience/hooks/useBuilderGenders";
import useBuilderCountries from "#components/pages/BriefBuilder/Audience/hooks/useBuilderCountries";
import { styled } from "@mui/material";
import {
  BRIEF_AUDIENCE_AGE_MAX,
  BRIEF_AUDIENCE_AGE_MIN,
} from "#components/pages/BriefBuilder/Audience/utils";
import useBuilderGeneralOptions from "#components/pages/BriefBuilder/General/hooks/useBuilderGeneralOptions";
import BuilderSelect from "#components/pages/BriefBuilder/common/BuilderSelect";

export default function BriefTemplateForm() {
  const { t } = useTranslation();
  const { formValues, formErrors, setValue, setValues } =
    useFormContext<AIChatTemplateForm>();
  const defaultAge = useRef<[number, number]>([formValues.minAge, formValues.maxAge]);

  const genders = useBuilderGenders();
  const countries = useBuilderCountries();
  const { responseOptions } = useBuilderGeneralOptions({ minimal: true });

  return (
    <StyledFormFields>
      <TextField
        id="title"
        name="title"
        label={t("ui.ai_chat.form.template.title")}
        value={formValues.title}
        error_message={formErrors.title}
        multiline
        onChange={(e) => setValue("title", e.target.value)}
      />
      <TextField
        id="prompt"
        name="prompt"
        label={t("ui.ai_chat.form.template.prompt")}
        value={formValues.prompt}
        error_message={formErrors.prompt}
        onChange={(e) => setValue("prompt", e.target.value)}
        multiline
        minRows={3}
      />
      <BuilderMultiselect
        id="gender"
        label={t("pages.brief_builder.steps.audience.form.gender.label")}
        placeholder={t("form.select")}
        optional
        options={genders}
        value={formValues.gender}
        hasError={!!formErrors.gender}
        onChange={(val) => setValue("gender", val)}
      />
      <Slider
        id="age"
        label={t("pages.brief_builder.steps.audience.form.age.label")}
        name="age"
        min={BRIEF_AUDIENCE_AGE_MIN}
        max={BRIEF_AUDIENCE_AGE_MAX}
        defaultValue={defaultAge.current}
        onChange={(val) => {
          setValues({
            minAge: val[0],
            maxAge: val[1],
          });
        }}
      />
      <BuilderMultiselect
        id="country"
        label={t("pages.brief_builder.steps.audience.form.country.label")}
        placeholder={t("form.select")}
        optional
        options={countries}
        value={formValues.countries}
        onChange={(val) => setValue("countries", val)}
        hasError={!!formErrors.countries}
      />
      <BuilderSelect
        value={formValues.responseType}
        type="radio"
        label={t("ui.ai_chat.form.template.response_type")}
        items={responseOptions}
        onChange={(val) => setValue("responseType", val)}
      />
    </StyledFormFields>
  );
}

const StyledFormFields = styled(FormFields)`
  min-width: 400px;
  padding-right: 1rem;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-width: 100%;
    padding-right: 0;
  }
`;
