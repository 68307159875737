import IconCommentsDuotone from "#assets/svgs/comments-duotone.svg?react";
import IconPieChartDuotone from "#assets/svgs/pie-chart-duotone.svg?react";
import IconPhotoFilmDuotone from "#assets/svgs/photo-film-duotone.svg?react";
import { BriefResponseType, BriefTargetingType } from "#customTypes/briefBuilder";
import { useMemo } from "react";
import useTranslation from "#utils/useTranslation";
import { BuilderSelectItemProps } from "#components/pages/BriefBuilder/common/BuilderSelectItem";
import useBriefBuilderPermissions from "#containers/BriefBuilder/hooks/useBriefBuilderPermissions";
import useGetOrganisationProjects from "#apis/projects/hooks/useGetOrganisationProjects";
import { useBriefBuilder } from "#containers/BriefBuilder/BriefBuilderProvider";
import { SelectOption } from "#shared/types/form";

interface Props {
  minimal?: boolean;
}

export default function useBuilderGeneralOptions({ minimal = false }: Props = {}) {
  const { t } = useTranslation();
  const { brief } = useBriefBuilder();
  const { isBusinessOrganisation } = useBriefBuilderPermissions();

  const { data: projectsData } = useGetOrganisationProjects({
    params: { organisationId: brief?.organisationId || 0 },
    options: { enabled: !!brief?.organisationId },
  });

  function getBusinessFeatures(isBusinessOrganisation: boolean) {
    if (isBusinessOrganisation || minimal) {
      return {};
    }

    return {
      locked: true,
      tooltip: t("pages.brief_builder.tooltips.business_locked"),
    };
  }

  const projectsOptions: SelectOption[] = useMemo(() => {
    if (!projectsData?.projects) {
      return [];
    }

    return projectsData.projects.map((project) => ({
      value: project.id,
      label: project.name || "",
    }));
  }, [projectsData]);

  const responseOptions: BuilderSelectItemProps[] = useMemo(
    () => [
      {
        title: t("pages.brief_builder.steps.general.form.response.comments.title"),
        description: !minimal
          ? t("pages.brief_builder.steps.general.form.response.comments.description")
          : undefined,
        value: BriefResponseType.Comment,
        Icon: IconCommentsDuotone,
        iconSize: "large",
      },
      {
        title: t("pages.brief_builder.steps.general.form.response.survey.title"),
        description: !minimal
          ? t("pages.brief_builder.steps.general.form.response.survey.description")
          : undefined,
        value: BriefResponseType.Survey,
        Icon: IconPieChartDuotone,
        iconSize: "large",
        ...getBusinessFeatures(isBusinessOrganisation),
      },
      {
        title: t("pages.brief_builder.steps.general.form.response.bulbshares.title"),
        description: !minimal
          ? t("pages.brief_builder.steps.general.form.response.bulbshares.description")
          : undefined,
        value: BriefResponseType.Bulbshare,
        Icon: IconPhotoFilmDuotone,
        iconSize: "large",
        ...getBusinessFeatures(isBusinessOrganisation),
      },
    ],
    [isBusinessOrganisation]
  );

  const targetingOptions: BuilderSelectItemProps[] = useMemo(
    () => [
      {
        title: t("pages.brief_builder.steps.general.form.targeting.everyone.title"),
        description: t(
          "pages.brief_builder.steps.general.form.targeting.everyone.description"
        ),
        value: BriefTargetingType.Everyone,
      },
      {
        title: t("pages.brief_builder.steps.general.form.targeting.segment.title"),
        description: t(
          "pages.brief_builder.steps.general.form.targeting.segment.description"
        ),
        value: BriefTargetingType.Segment,
        ...getBusinessFeatures(isBusinessOrganisation),
      },
    ],
    [isBusinessOrganisation]
  );

  return {
    responseOptions,
    targetingOptions,
    projectsOptions,
  };
}
