import { Theme } from "@mui/material";
import { ComponentOverrides } from "../types";
import rounding from "../../theme/rounding";
import colors from "../../theme/colors";

const sliderOverrides = (theme: Theme): ComponentOverrides<"MuiSlider"> => ({
  styleOverrides: {
    valueLabel: {
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      borderRadius: rounding.default,
      backgroundColor: colors.base.black,
    },
  },
});

export default sliderOverrides;
