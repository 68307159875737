import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ChannelsQueryKeys } from "#apis/channels/queryKeys";
import useChannelPermissions from "#components/pages/ChannelDetails/useChannelPermissions";
import CreateChannelStepOne from "#components/pages/Feed/ChannelsSidebar/CreateChannel/steps/CreateChannelStepOne";
import CreateChannelStepTwo from "#components/pages/Feed/ChannelsSidebar/CreateChannel/steps/CreateChannelStepTwo";
import useCreateChannelForm from "#components/pages/Feed/ChannelsSidebar/CreateChannel/useCreateChannelForm";
import useCreateChannelOrganisations from "#components/pages/Feed/ChannelsSidebar/CreateChannel/useCreateChannelOrganisations";
import { getChannelUrl } from "#components/routing/utils";
import { BaseModalProps, ModalCloseReason } from "#customTypes/modals";
import { IMAGE_TYPES } from "#customTypes/types";
import { useCreateChannelMutation } from "#features/channel/channelsAPI";
import { selectUser } from "#features/user/userSlice";
import { LoadingButton } from "#shared/components/ui";
import CustomModal from "#shared/components/ui/Modal";
import ModalContent from "#shared/components/ui/Modal/ModalContent";
import ModalFooter from "#shared/components/ui/Modal/ModalFooter";
import ModalHeader from "#shared/components/ui/Modal/ModalHeader";
import colors from "#shared/config/theme/colors";
import { FormProvider } from "#shared/context/FormContext";
import useToast from "#shared/hooks/useToast";
import { convertImageUrlToFile } from "#shared/utils";
import getResourcesURL from "#utils/getResourcesURL";
import { appendParamsToUrl } from "#utils/serviceHelpers";
import { GaEvents, gaTrackEvent } from "#utils/useGA";
import useTranslation from "#utils/useTranslation";

enum CreateChannelStep {
  SelectOrganisation = 1,
  ChannelForm = 2,
}

export default function CreateChannelModal({ isOpen, onClose }: BaseModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();

  const { canCreateChannelForOrganisation } = useChannelPermissions();
  const { skipSelectOrganisationStep, verifiedOrganisations, getDefaultOrganisation } =
    useCreateChannelOrganisations();

  const [currentStep, setCurrentStep] = useState<CreateChannelStep>(
    !skipSelectOrganisationStep
      ? CreateChannelStep.SelectOrganisation
      : CreateChannelStep.ChannelForm
  );

  const showBackButton = currentStep > 1 && !skipSelectOrganisationStep;

  const [createChannel, { isLoading }] = useCreateChannelMutation();

  const defaultOrganisationId = getDefaultOrganisation();

  const form = useCreateChannelForm({
    organisationId: defaultOrganisationId,
    banner: "",
    logo: "",
    name: "",
    description: "",
    interests: [],
  });

  const { formValues, validate, resetForm } = form;

  const canCreateChannel = canCreateChannelForOrganisation(formValues.organisationId);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const item = verifiedOrganisations.find(
      (item) => item.organisation.id === formValues.organisationId
    );

    const defaultLogoUrl = item ? item.organisation.logo : user.profile.avatar;

    const logoSrc = getResourcesURL(defaultLogoUrl, IMAGE_TYPES.USER_AVATAR);

    if (logoSrc) {
      form.setValue("logo", appendParamsToUrl(logoSrc, { upload: true }));
    } else {
      form.setValue("logo", "");
    }
  }, [formValues.organisationId, verifiedOrganisations, user.profile.avatar, isOpen]);

  useEffect(() => {
    if (skipSelectOrganisationStep) {
      setCurrentStep(CreateChannelStep.ChannelForm);
    }
  }, [skipSelectOrganisationStep]);

  const handleCreateChannel = async () => {
    if (!validate()) {
      return;
    }

    const logo =
      !formValues.logo.startsWith("https://") || formValues.logo.includes("upload=true")
        ? await convertImageUrlToFile(formValues.logo)
        : undefined;

    const banner = !formValues.banner.startsWith("https://")
      ? await convertImageUrlToFile(formValues.banner)
      : undefined;

    try {
      gaTrackEvent(
        canCreateChannel ? GaEvents.createChannel : GaEvents.requestChannel,
        "",
        formValues.name
      );

      const data = await createChannel({
        organisationId:
          formValues.organisationId > 0 ? formValues.organisationId : undefined,
        name: formValues.name,
        description: formValues.description,
        interests: formValues.interests,
        logo,
        banner,
      }).unwrap();
      queryClient.invalidateQueries({ queryKey: ChannelsQueryKeys.owned });
      toast.success({
        message: t(
          !canCreateChannel
            ? "ui.modals.create_channel.toasts.request_success"
            : "ui.modals.create_channel.toasts.create_success"
        ),
      });

      const channelUrl = getChannelUrl(data.channelRef);
      navigate(canCreateChannel ? channelUrl : "/");

      onClose(ModalCloseReason.Confirm);
    } catch (error) {
      if ((error as Error).message.includes("reached the daily limit")) {
        toast.error({ message: t("ui.modals.create_channel.toasts.daily_limit") });
        return;
      }

      toast.error();
    }
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleClose = () => {
    onClose(ModalCloseReason.Dismiss);
  };

  const handleClosed = () => {
    if (!skipSelectOrganisationStep) {
      setCurrentStep(CreateChannelStep.SelectOrganisation);
    }

    resetForm({ organisationId: defaultOrganisationId });
    onClose(ModalCloseReason.Dismiss);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
    resetForm({ organisationId: defaultOrganisationId });
  };

  const modalContent = () => {
    if (currentStep === CreateChannelStep.ChannelForm) {
      return <CreateChannelStepTwo />;
    }

    return <CreateChannelStepOne />;
  };

  const modalFooter = () => {
    if (currentStep === CreateChannelStep.ChannelForm) {
      return (
        <ModalFooter
          sx={{ flexDirection: "column" }}
          AdditionalElements={
            <Box width="100%">
              <LoadingButton
                variant="contained"
                loading={isLoading}
                fullWidth
                disabled={!formValues.name || !formValues.interests.length}
                onClick={handleCreateChannel}
              >
                {canCreateChannel
                  ? t("ui.modals.create_channel.buttons.create")
                  : t("ui.modals.create_channel.buttons.request")}
              </LoadingButton>
              {!canCreateChannel && (
                <Typography
                  variant="label3"
                  textAlign="center"
                  color={colors.text.secondaryDark}
                  sx={{ mt: 3 }}
                >
                  {t("ui.modals.create_channel.request_disclaimer")}
                </Typography>
              )}
            </Box>
          }
        />
      );
    }

    return (
      <ModalFooter
        onCancelButtonClick={handleClosed}
        confirmButtonText={t("ui.buttons.next")}
        confirmButtonProps={{
          disabled: formValues.organisationId < 0,
        }}
        onConfirmButtonClick={handleNext}
      />
    );
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={handleClose}
      onClosed={handleClosed}
      Header={
        <ModalHeader
          title={t("ui.modals.create_channel.title")}
          onBack={showBackButton ? handleBack : undefined}
          onClose={handleClose}
        >
          {!skipSelectOrganisationStep && (
            <Typography variant="label3" mt={1}>
              {t("ui.modals.create_channel.steps_label", {
                current: currentStep,
                total: 2,
              })}
            </Typography>
          )}
        </ModalHeader>
      }
      Footer={modalFooter()}
      Content={<FormProvider value={form}>{modalContent()}</FormProvider>}
    />
  );
}
