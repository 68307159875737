import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";
import colors from "../../../theme/colors";

const formLabelOverrides = (theme: Theme): ComponentOverrides<"MuiFormLabel"> => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.label3.fontSize,
      lineHeight: theme.typography.label3.lineHeight,
      color: colors.text.primaryDark,
    },
  },
});

export default formLabelOverrides;
