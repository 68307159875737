import { styled } from "@mui/material";
import Tabs from "@mui/material/Tabs";

export default styled(Tabs)(
  ({ theme }) => `
  background: #0562DC;
  border-radius: 10px;
  width: 100%;
  
  & .tab {
    opacity: 1;
    min-width: 0;
    padding: 0;
  }

  .MuiTab-root {
    text-transform: none;
  }

  ${theme.breakpoints.down("sm")} {
    .MuiTabs-scrollButtons {
      display: flex;
    } 
  }
  
  
  & .chip {
    border-radius: 6px;
    margin: 0px 10px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    height: 1.813rem;
    
    &.selected {
        color: black;
        background: #FFFFFF;
    }
  }
  
  & .MuiTabs-flexContainer {
    justify-content: flex-start;
  }
  
  
  & .MuiTouchRipple-root { display: none; }
`
);
