import { styled } from "@mui/material/styles";
import PopperMui from "@mui/material/Popper";

const Popper = styled(PopperMui)`
  z-index: 1;

  &[data-popper-placement*="left-start"],
  &[data-popper-placement*="right-start"] {
    top: -0.5rem !important;
    & .MuiPopper-arrow {
      margin-top: 0.5rem;
    }
  }

  &[data-popper-placement*="left-end"],
  &[data-popper-placement*="right-end"] {
    bottom: -0.5rem !important;
    & .MuiPopper-arrow {
      margin-top: -0.5rem;
    }
  }

  &[data-popper-placement*="right"] {
    & > div {
      margin-left: 0.5rem;
    }
    & .MuiPopper-arrow {
      &::before {
        transform: rotate(135deg);
      }

      margin-left: -1.47rem;
    }
  }

  &[data-popper-placement*="left"] {
    & > div {
      margin-right: 0.5rem;
    }
    & .MuiPopper-arrow {
      right: 0;
      margin-right: -0.9rem;

      &::before {
        transform: rotate(-45deg);
      }
    }
  }

  &[data-popper-placement*="top"] {
    & > div {
      margin-bottom: 0.5rem;
    }

    & .MuiPopper-arrow {
      bottom: 0;
      margin-bottom: 0.3rem;
      &::before {
        transform: rotate(45deg);
      }
    }
  }

  &[data-popper-placement*="bottom"] {
    & > div {
      margin-top: 0.5rem;
    }
    & .MuiPopper-arrow {
      margin-top: -0.3rem;
      &::before {
        transform: rotate(-135deg);
      }
    }
  }
`;

const Wrapper = styled("div")`
  border: 0.063rem solid #d4d4d5;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(34 36 38 / 12%),
    0 0.125rem 0.625rem 0 rgb(34 36 38 / 15%);
  background: white;
`;

const Arrow = styled("div")`
  width: 3rem;
  &::before {
    content: "";
    margin: auto;
    display: block;
    background: #ffffff;
    width: 0.71428571em;
    height: 0.71428571em;
    z-index: 2;
    -webkit-box-shadow: 1px 1px 0 0 #d4d4d5;
    box-shadow: 1px 1px 0 0 #d4d4d5;
  }
`;

export { Popper, Arrow, Wrapper };
