import { Theme } from "@mui/material";
import { ComponentOverrides } from "../types";
import { getBaseInputStyles } from "../../ui/utils";

const textFieldOverrides = (theme: Theme): ComponentOverrides<"MuiTextField"> => ({
  styleOverrides: {
    root: {
      ".MuiOutlinedInput-root": {
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.error.main} !important`,
        },

        ".MuiOutlinedInput-notchedOutline": {
          ...getBaseInputStyles(theme).base,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          ...getBaseInputStyles(theme).focused,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          ...getBaseInputStyles(theme).focused,
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          ...getBaseInputStyles(theme).disabled,
        },
      },
    },
  },
});

export default textFieldOverrides;
