import React, { useMemo, useState } from "react";
import EditorPluginWrapper from "#components/common/Editor/components/EditorPluginWrapper";
import ButtonTabs, { ButtonTab } from "#shared/components/ui/ButtonTabs";
import IconUnsplash from "#assets/svgs/unsplash.svg?react";
import IconImage from "#assets/svgs/image.svg?react";
import EditorImageUpload from "#components/common/Editor/extensions/ImageUpload/Upload/components/EditorImageUpload";
import { Editor, NodeViewWrapper } from "@tiptap/react";
import { Box, styled } from "@mui/material";
import EditorImageUnsplash from "#components/common/Editor/extensions/ImageUpload/Unsplash/EditorImageUnsplash";
import useTranslation from "#utils/useTranslation";

enum Tab {
  Upload = "upload",
  Unsplash = "unsplash",
}

export interface EditorImageProps {
  editor: Editor;
  getPos: () => number;
}

export default function EditorImageUploadView(props: EditorImageProps) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Upload);

  const tabs: ButtonTab[] = useMemo(
    () => [
      {
        id: Tab.Upload,
        label: t("ui.labels.upload"),
        icon: <IconImage />,
      },
      {
        id: Tab.Unsplash,
        label: t("ui.labels.unsplash"),
        icon: <IconUnsplash />,
      },
    ],
    []
  );

  const content = () => {
    switch (selectedTab) {
      case Tab.Upload:
        return <EditorImageUpload {...props} />;
      case Tab.Unsplash:
        return <EditorImageUnsplash {...props} />;
      default:
        return "not implemented";
    }
  };

  return (
    <NodeViewWrapper>
      <EditorPluginWrapper>
        <ButtonTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onChange={(val) => setSelectedTab(val as Tab)}
        />
        <ContentWrapper>{content()}</ContentWrapper>
      </EditorPluginWrapper>
    </NodeViewWrapper>
  );
}

const ContentWrapper = styled(Box)`
  margin-top: 1rem;
`;
