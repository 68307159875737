import useBriefBuilderSteps from "#containers/BriefBuilder/hooks/useBriefBuilderSteps";
import { Box, Drawer, IconButton, Typography, styled } from "@mui/material";
import React, { ReactNode, useState } from "react";
import MenuIcon from "#assets/svgs/menu.svg?react";
import { StepBars, Steps } from "#shared/components/ui";
import { Step } from "#shared/types/steps";
import { BriefBuilderStep } from "#customTypes/briefBuilder";

interface Props {
  children?: ReactNode;
  onStepClick: (step: BriefBuilderStep) => void;
}

export default function BuilderMobileSteps({ children, onStepClick }: Props) {
  const [open, setOpen] = useState(false);

  const { steps, currentStep } = useBriefBuilderSteps();

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const handleStepClick = (step: Step) => {
    closeDrawer();
    onStepClick(step.id as BriefBuilderStep);
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        <IconButton onClick={openDrawer}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" fontFamily="Avenir-heavy">
          {currentStep?.title}
        </Typography>
      </Box>

      <StepBars steps={steps} sx={{ position: "absolute", bottom: "-1px", left: 0 }} />

      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <DrawerWrapper>
          <Steps
            steps={steps}
            onClick={handleStepClick}
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          />
          {children}
        </DrawerWrapper>
      </Drawer>
    </>
  );
}

const DrawerWrapper = styled(Box)`
  box-sizing: border-box;
  min-width: min(17.5rem, calc(70vw));
  padding: 1rem;
`;
