import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import { identifyPathname } from "#utils/routeHelpers";
import { useIsMobileApp } from "#utils/useIsMobileApp";
import { useLocation } from "react-router";

export default function useMagicButtonVisibility() {
  const { pathname } = useLocation();
  const { approvedChannels } = useMyOwnedChannels();
  const { isMobileApp } = useIsMobileApp();

  const isSurveyPreview = identifyPathname.isSurveyPreview(pathname);
  const isBriefBuilder = identifyPathname.isBriefBuilder(pathname);
  const isSmartBriefBuilder = identifyPathname.isSmartBriefBuilder(pathname);
  const isBriefDetails = identifyPathname.isBriefDetails(pathname);

  const visible =
    !isMobileApp &&
    !isBriefBuilder &&
    !isSmartBriefBuilder &&
    !isSurveyPreview &&
    !isBriefDetails &&
    approvedChannels.length > 0;

  return {
    visible,
  };
}
