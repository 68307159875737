import { useNavigate } from "react-router";

const useNavigateToChannel = () => {
  const navigate = useNavigate();

  const navigateToChannel = (brandRef?: string) => {
    if (!brandRef) {
      return;
    }

    const trimmedRef = brandRef.replace("brand-", "");

    navigate(`/channel/${trimmedRef}`);
    window.scroll({ top: 0 });
  };

  return navigateToChannel;
};

export default useNavigateToChannel;
