import TrackingCallbackImage from "#components/TrackingCallbackImage";
import {
  WrapperPage,
  WrapperContent,
  WrapperPageContent,
} from "#containers/layout/styled";
import React, { ReactNode } from "react";
import DesktopSideNavigation from "#components/pages/Feed/ChannelsSidebar/DesktopChannels";
import { Box } from "@mui/material";
import SuggestedChannels from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels";
import { useLocation } from "react-router";
import { isFeedPage } from "#utils/feed";
import useGodView from "#containers/GodView/useGodView";

interface Props {
  fullWidth?: boolean;
  children: ReactNode;
}

export default function ResidentialLayout({ fullWidth, children }: Props) {
  const { pathname } = useLocation();
  const { isEnabled: isGodViewEnabled } = useGodView();

  return (
    <WrapperPage>
      <WrapperContent fullWidth={fullWidth}>
        <Box>
          <DesktopSideNavigation />
        </Box>
        <WrapperPageContent>{children}</WrapperPageContent>
        <Box>
          {isFeedPage(pathname) && !isGodViewEnabled && (
            <SuggestedChannels type="desktop" />
          )}
        </Box>
      </WrapperContent>
      <TrackingCallbackImage />
    </WrapperPage>
  );
}
