import React from "react";
import { NodeViewWrapper, NodeViewWrapperProps } from "@tiptap/react";
import { useMemo } from "react";
import { EditorMenuOption } from "#components/common/Editor/types";
import { EDITOR_AI_TONE_OPTIONS } from "#components/common/Editor/constants";
import { Box, Button, styled } from "@mui/material";
import AIGenerateWidget from "#components/common/Editor/components/AIGenerateWidget";
import EditorMenuDropdown from "#components/common/Editor/components/EditorMenuDropdown";
import IconMicrophone from "#assets/svgs/microphone.svg?react";
import useMagicWriter from "#components/common/Editor/extensions/MagicWriter/useMagicWriter";
import colors from "#shared/config/theme/colors";
import useTranslation from "#utils/useTranslation";

export default function MagicWriterView({
  editor,
  node,
  getPos,
  deleteNode,
}: NodeViewWrapperProps) {
  const { t } = useTranslation();
  const {
    data,
    isGenerating,
    previewText,
    generateText,
    handlePromptChange,
    handleConfirm,
    handleDiscard,
    handleSelectTone,
  } = useMagicWriter({ editor, node, getPos, deleteNode });

  const currentTone = EDITOR_AI_TONE_OPTIONS.find((t) => t.value === data.tone);

  const toneOptions: EditorMenuOption[] = useMemo(
    () => [
      {
        id: "none",
        label: t("ui.tiptap_editor.menu.selection.ai_menu.change_tone.none"),
        type: "option" as EditorMenuOption["type"],
        selected: !data.tone,
        onClick: handleSelectTone(undefined),
      },
      ...EDITOR_AI_TONE_OPTIONS.map((tone) => ({
        id: tone.value,
        label: t(tone.label),
        type: "option" as EditorMenuOption["type"],
        selected: tone.value === data.tone,
        onClick: handleSelectTone(tone.value),
      })),
    ],
    [handleSelectTone, data.tone]
  );

  return (
    <NodeViewWrapper>
      <AIGenerateWidget
        prompt={data.text}
        isGenerating={isGenerating}
        type="write"
        setPrompt={handlePromptChange}
        onConfirm={handleConfirm}
        onDiscard={handleDiscard}
        onGenerate={generateText}
        PreviewElement={
          !!previewText && (
            <PreviewContainer dangerouslySetInnerHTML={{ __html: previewText }} />
          )
        }
        FooterElement={
          <EditorMenuDropdown
            items={toneOptions}
            AnchorElement={
              <Button
                variant="outlined"
                size="small"
                startIcon={<IconMicrophone />}
                sx={{ minWidth: 0 }}
                disabled={isGenerating}
              >
                {currentTone?.label
                  ? t(currentTone.label)
                  : t("ui.tiptap_editor.menu.selection.ai_menu.change_tone.title")}
              </Button>
            }
          />
        }
      />
    </NodeViewWrapper>
  );
}

const PreviewContainer = styled(Box)`
  position: relative;
  max-height: 14rem;
  overflow-y: auto;
  padding: 0.75rem 1rem;
  border-left: 4px solid ${colors.base.outline1};
  font-size: ${({ theme }) => theme.typography.label2.fontSize};
`;
