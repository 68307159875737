// modules
import React from "react";
import { FormattedMessage } from "react-intl";

// assets
import StoreQRCode from "../../../assets/svgs/qr-code.svg?react";
import AppStore from "../../../assets/svgs/button-app-store.svg?react";
import GooglePlay from "../../../assets/svgs/button-google-play.svg?react";

// styled
import {
  ButtonsWrapper,
  DownloadHeader,
  DownloadContent,
  DownloadText,
  WrapperDownload,
} from "./styled";

// utils
import { ComposedHookDownload } from "../../../types/hooks";
import BriefLinkQRCode from "./BriefLinkQRCode";

export type DownloadViewProps = {
  useDownload: ComposedHookDownload;
};

const qrWrapperId = "appLinkQRCodeContainer";
const prefixIntl = "pages.download";

export default function DownloadView({
  useDownload: { isLoading, link, joinBriefId, redirectUrlQRCode, redirectUrlStore },
}: DownloadViewProps) {
  const shouldRenderCustomQR = !!(joinBriefId || isLoading || link);

  return (
    <WrapperDownload>
      <DownloadHeader>
        <FormattedMessage id={`${prefixIntl}.header`} />
      </DownloadHeader>
      <DownloadContent>
        <DownloadText>
          <FormattedMessage id={`${prefixIntl}.scan`} />
        </DownloadText>
        <a className="qrCode" href={redirectUrlQRCode}>
          {shouldRenderCustomQR ? (
            <BriefLinkQRCode link={link} qrWrapperId={qrWrapperId} />
          ) : (
            <StoreQRCode />
          )}
        </a>
        <DownloadText>
          <FormattedMessage id={`${prefixIntl}.download`} />
        </DownloadText>
        <ButtonsWrapper>
          <a href={redirectUrlStore}>
            <AppStore />
          </a>
          <a href={redirectUrlStore}>
            <GooglePlay />
          </a>
        </ButtonsWrapper>
      </DownloadContent>
    </WrapperDownload>
  );
}
