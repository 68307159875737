import React, { useMemo, useState } from "react";
import { UnsplashPhoto, UnsplashPhotoOrientation } from "#customTypes/unsplash";
import useTranslation from "#utils/useTranslation";
import { RadioOption } from "#shared/types/form";
import { Box, ImageList, Skeleton, styled } from "@mui/material";
import UnsplashImagePreview from "#components/pages/BriefBuilder/Brief/steps/Cover/components/UnsplashImagePreview";
import SearchField from "#components/common/Search/SearchField";
import { RadioSelect } from "#shared/components/ui/Radio";
import InfiniteLoader from "#components/pages/ChannelCommunity/SearchMembers/grid/InfiniteLoader";
import { useBriefBuilder } from "#containers/BriefBuilder/BriefBuilderProvider";
import useSearchUnsplashPhotos from "#apis/unsplash/hooks/useSearchUnsplashPhotos";
import { mapPaginatedData } from "#utils/paginationUtils";

interface Props {
  disabled?: boolean;
  onImageClick: (val: UnsplashPhoto) => void;
}

export default function BuilderUnsplashSelector({ disabled, onImageClick }: Props) {
  const { t } = useTranslation();
  const { brief } = useBriefBuilder();

  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<UnsplashPhotoOrientation>(
    UnsplashPhotoOrientation.All
  );

  const defaultSearch = useMemo(() => {
    if (brief?.generatedKeyConcept) {
      return brief.generatedKeyConcept;
    }

    if (!brief?.title) {
      return "survey";
    }

    const words = brief?.title.split(" ");

    if (words.length < 2) {
      return "survey";
    }

    const sortedWords = words.sort((a, b) => b.length - a.length);
    return sortedWords.slice(0, 2).join(" ");
  }, [brief]);

  const {
    data,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useSearchUnsplashPhotos({
    params: {
      query: search || defaultSearch,
      orientation: filter !== UnsplashPhotoOrientation.All ? filter : undefined,
    },
    options: { enabled: !disabled },
  });

  const photos = useMemo(() => mapPaginatedData(data), [data]);

  const filters: RadioOption[] = useMemo(
    () => [
      {
        value: UnsplashPhotoOrientation.All,
        label: t("pages.brief_builder.steps.cover.modals.select_unsplash.filters.all"),
      },
      {
        value: UnsplashPhotoOrientation.Landscape,
        label: t(
          "pages.brief_builder.steps.cover.modals.select_unsplash.filters.landscape"
        ),
      },
      {
        value: UnsplashPhotoOrientation.Portrait,
        label: t(
          "pages.brief_builder.steps.cover.modals.select_unsplash.filters.portrait"
        ),
      },
      {
        value: UnsplashPhotoOrientation.Squarish,
        label: t("pages.brief_builder.steps.cover.modals.select_unsplash.filters.square"),
      },
    ],
    []
  );

  const handleImageClick = (id: string) => {
    const selectedImage = photos.find((item) => item.id === id);

    if (!selectedImage) {
      return;
    }

    onImageClick(selectedImage);
  };

  const content = () => {
    if (isLoading) {
      return Array.from({ length: 12 }).map((_, index) => (
        <Skeleton key={index} variant="rounded" height={120} />
      ));
    }

    return photos.map((item) => (
      <UnsplashImagePreview key={item.id} image={item} onClick={handleImageClick} />
    ));
  };

  return (
    <>
      <SearchField
        value={search}
        onChange={setSearch}
        placeholder={t("pages.brief_builder.steps.cover.modals.select_unsplash.search")}
        loading={isRefetching}
        sx={{ mb: 4 }}
      />
      <RadioSelect
        value={filter}
        onChange={(val) => setFilter(val as UnsplashPhotoOrientation)}
        options={filters}
        name="organisation-filter"
        sx={{ mb: 2 }}
      />
      <Box className="unsplash-list-wrapper">
        <InfiniteLoader
          delay={1500}
          hasNextPage={hasNextPage}
          isLoadingNextPage={isFetchingNextPage}
          loadNextPage={fetchNextPage}
        >
          <ImagesGrid cols={3} rowHeight={120} gap={8}>
            {content()}
          </ImagesGrid>
        </InfiniteLoader>
      </Box>
    </>
  );
}

const ImagesGrid = styled(ImageList)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: repeat(2, 1fr) !important;
  }
`;
