import React from "react";
import { Tab, Tabs, styled } from "@mui/material";

import IconLock from "../../../assets/svgs/lock.svg?react";
import colors from "../../../config/theme/colors";
import rounding from "../../../config/theme/rounding";
import Tooltip from "../Tooltip";

export type ButtonTab = {
  id: string;
  label: string;
  icon?: string | React.ReactElement;
  locked?: boolean;
  tooltip?: string;
  route?: string;
  hidden?: boolean;
};

type Props = {
  tabs: ButtonTab[];
  selectedTab?: string;
  onChange: (selectedTabId: string) => void;
};

export default function ButtonTabs({ tabs, selectedTab, onChange }: Props) {
  const handleTabChange = (_event: React.SyntheticEvent, value: string) => {
    const tabId = tabs.find((tab) => tab.id === value)?.id;

    if (tabId) {
      onChange(tabId);
    }
  };

  return (
    <StyledTabsComponent
      value={selectedTab}
      variant="fullWidth"
      onChange={handleTabChange}
    >
      {tabs.map((tab) => (
        <StyledTabComponent
          key={tab.id}
          value={tab.id}
          disabled={tab.locked}
          label={
            <Tooltip
              title={tab.tooltip}
              hidden={!tab.tooltip}
              key={tab.id}
              enterTouchDelay={tab.locked ? 0 : undefined}
            >
              <span>{tab.label}</span>
            </Tooltip>
          }
          iconPosition="start"
          icon={tab.locked ? <IconLock /> : tab.icon}
          sx={{ pointerEvents: "auto !important" }}
          disableRipple
        />
      ))}
    </StyledTabsComponent>
  );
}

const StyledTabsComponent = styled(Tabs)`
  border: 1px solid ${colors.base.outline1};
  border-radius: ${rounding.default};
  min-height: 32px;
  background: ${colors.base.white};

  .MuiTabs-flexContainer {
    position: relative;
    z-index: 1;
  }

  .MuiTabs-indicator {
    margin-top: 0;
    height: 32px;
    border-radius: ${rounding.lg};
  }

  .MuiTabs-scroller {
    &::before {
      display: none;
    }
  }
`;

const StyledTabComponent = styled(Tab)`
  background-color: ${colors.base.transparent};
  color: ${colors.text.tertiaryDark};
  font-size: ${({ theme }) => theme.typography.label3.fontSize};
  line-height: ${({ theme }) => theme.typography.label3.lineHeight};
  font-weight: 400;
  padding: 0 10px;
  transition: color 0.3s ease-in-out;
  min-height: 32px;
  min-width: 72px;
  border-bottom: none;

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  &.Mui-disabled {
    color: ${colors.text.disabledLight};
  }

  .MuiTab-iconWrapper {
    height: 14px;
    width: 14px;
  }
`;
