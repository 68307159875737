import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { FormErrors, FormOptions } from "../../types/form";

type FormType<T> = {
  formValues: T;
  formErrors: FormErrors<T>;
  touchedState: Record<keyof T, boolean>;
};

export type FormReturnType<T> = {
  hasError: boolean;
  hasChanges: boolean;
  formErrors: FormErrors<T>;
  formValues: T;
  getValue: (fieldName: keyof T) => any;
  setValue: (fieldName: keyof T, value: any) => void;
  setValues: (newValues: Partial<T>) => void;
  setDefaultValues: (newValues: T) => void;
  updateDefaultValues: () => void;
  getError: (fieldName: keyof T) => string | undefined;
  setError: (fieldName: keyof T, errorMessage: string) => void;
  setErrors: (errors: Record<keyof T, string>) => void;
  validate: (values?: T) => boolean;
  validateField: (fieldName: keyof T, value: T[keyof T], skipTouched?: boolean) => void;
  resetForm: (data?: Partial<T>) => void;
};

const useForm = <T>(defaultValues?: T, options?: FormOptions<T>): FormReturnType<T> => {
  const { validateFn, validateOnChange } = options || {};
  const defaultValuesRef = useRef<T>();
  const [version, setVersion] = useState(1);

  const [formData, setFormData] = useState<FormType<T>>({
    formValues: defaultValues || ({} as T),
    formErrors: {},
    touchedState: {} as Record<keyof T, boolean>,
  });

  useEffect(() => {
    if (defaultValues && !defaultValuesRef.current) {
      setFormData((prev) => ({
        formValues: defaultValues,
        formErrors: prev.formErrors,
        touchedState: Object.keys(defaultValues).reduce((acc, key) => {
          acc[key as keyof T] = false;
          return acc;
        }, {} as Record<keyof T, boolean>),
      }));
      defaultValuesRef.current = defaultValues;
    }
  }, [defaultValues]);

  const getValue = useCallback(
    (field: keyof T) => {
      return formData.formValues[field];
    },
    [formData]
  );

  const setValue = useCallback(
    (field: keyof T, value: T[keyof T]) => {
      const errors: FormErrors<T> = formData.formErrors;

      if (validateOnChange) {
        errors[field] = validateField(field, value, true);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        formValues: { ...prevFormData.formValues, [field]: value },
        formErrors: errors,
      }));
    },
    [validateOnChange, formData.formErrors]
  );

  const setValues = useCallback((values: Partial<T>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      formValues: { ...prevFormData.formValues, ...values },
    }));
  }, []);

  const setDefaultValues = useCallback((values: T) => {
    defaultValuesRef.current = values;
    setValues(values);
    validate(values);
  }, []);

  const getError = useCallback(
    (field: keyof T) => {
      return formData.formErrors[field];
    },
    [formData]
  );

  const setError = useCallback((field: keyof T, error: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      formErrors: { ...prevFormData.formErrors, [field]: error },
    }));
  }, []);

  const setErrors = useCallback((errors: FormErrors<T>) => {
    const filteredErrors = Object.entries(errors).filter(([, val]) => !!val);

    setFormData((prevFormData) => ({
      ...prevFormData,
      formErrors: { ...(Object.fromEntries(filteredErrors) as FormErrors<T>) },
    }));
  }, []);

  const validateField = useCallback(
    (fieldName: keyof T, value: T[keyof T], skipTouched?: boolean) => {
      let error = "";

      if ((skipTouched || formData.touchedState[fieldName]) && validateFn) {
        error = validateFn(fieldName, value);
      }

      return error;
    },
    [formData.touchedState, validateFn]
  );

  const validate = useCallback(
    (values?: T) => {
      const errors: FormErrors<T> = {};
      const valuesToValidate = values || formData.formValues;

      for (const fieldName in valuesToValidate) {
        const error = validateField(fieldName, valuesToValidate[fieldName], true);
        if (error) {
          errors[fieldName] = error;
        }
      }

      setFormData((prev) => ({ ...prev, formErrors: errors }));
      return !Object.values(errors).filter((val) => !!val).length;
    },
    [validateField, formData.formValues]
  );

  const resetForm = useCallback((data?: Partial<T>) => {
    if (!defaultValuesRef.current) {
      return;
    }

    setFormData((prev) => ({
      formValues: { ...(defaultValuesRef.current as T), ...data },
      formErrors: {},
      touchedState: Object.keys(prev.touchedState).reduce((acc, key) => {
        acc[key as keyof T] = false;
        return acc;
      }, {} as Record<keyof T, boolean>),
    }));
  }, []);

  const updateDefaultValues = useCallback(() => {
    if (!validate()) {
      return;
    }

    defaultValuesRef.current = formData.formValues;
    setVersion((prev) => prev + 1);
  }, [formData.formValues, validate]);

  const hasChanges = useMemo(() => {
    if (!defaultValuesRef.current) {
      return false;
    }

    return (
      JSON.stringify(formData.formValues) !== JSON.stringify(defaultValuesRef.current)
    );
  }, [formData.formValues, version]);

  const hasError = useMemo(() => {
    return !!Object.values(formData.formErrors).filter((val) => !!val).length;
  }, [formData.formErrors, formData.formValues]);

  return {
    hasError,
    hasChanges,
    formErrors: formData.formErrors,
    formValues: formData.formValues,
    resetForm,
    getValue,
    setValue,
    setValues,
    setDefaultValues,
    updateDefaultValues,
    getError,
    setError,
    setErrors,
    validate,
    validateField,
  };
};

export default useForm;
