import React, { ReactNode, useEffect, useRef } from "react";
import { Backdrop, Dialog, DialogProps } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { BottomSheet } from "react-spring-bottom-sheet";
import classNames from "classnames";
import useIsFirstRender from "../../../hooks/useIsFirstRender";

import { useModalProvider } from "./ModalProvider";
import { useMediaQueryProvider } from "../../../context/MediaQueryProvider";
import { MODAL_DEFAULT_Z_INDEX } from "./modalConstants";

type CustomModalProps = {
  open: boolean;
  disableDismiss?: boolean;
  isCustom?: boolean;
  backgroundColor?: string;
  Content: ReactNode;
  Header?: ReactNode;
  Footer?: ReactNode;
  onClose: () => void;
  onClosed?: () => void;
  className?: string;
} & Omit<DialogProps, "onClose" | "children">;

export default function CustomModal({
  open,
  disableDismiss,
  isCustom,
  Content,
  Header,
  Footer,
  onClose,
  onClosed,
  ...modalProps
}: CustomModalProps) {
  const { isMobile } = useMediaQueryProvider();
  const { openModal, closeModal } = useModalProvider();
  const isFirstRender = useIsFirstRender();

  const modalIdRef = useRef(uuidv4());

  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    if (open) {
      openModal(modalIdRef.current);
    } else {
      closeModal(modalIdRef.current);
    }

    return () => {
      closeModal(modalIdRef.current);
    };
  }, [open]);

  if (isMobile) {
    return (
      <BottomSheet
        open={open}
        onDismiss={!disableDismiss ? onClose : undefined}
        header={Header}
        footer={Footer}
        className={classNames(
          "react-bottom-sheet",
          modalProps.className,
          !disableDismiss && "-dismissable"
        )}
        scrollLocking={false}
        blocking={false}
        sibling={
          <Backdrop
            open={open}
            onClick={onClose}
            sx={{ zIndex: MODAL_DEFAULT_Z_INDEX }}
          />
        }
        onSpringEnd={(event) => {
          if (event.type === "CLOSE") {
            onClosed?.();
          }
        }}
      >
        {Content}
      </BottomSheet>
    );
  }

  return (
    <Dialog
      {...modalProps}
      onClose={!disableDismiss ? onClose : undefined}
      onTransitionExited={onClosed}
      disableScrollLock
      open={open}
    >
      {Header}
      {Content}
      {Footer}
    </Dialog>
  );
}
