import React, { useState } from "react";
import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import { Box, Button, styled } from "@mui/material";
import useAIChatInput from "#components/common/AIChat/hooks/useAIChatInput";
import { IMAGE_TYPES } from "#customTypes/types";
import useTranslation from "#utils/useTranslation";
import { ChannelListItem } from "#customTypes/channel";
import AIChatChannelListItem from "#components/common/AIChat/components/actions/AIChatChannelListItem";

const LIMIT = 4;

interface Props {
  disabled: boolean;
}

export default function AIChatChannelList({ disabled }: Props) {
  const { t } = useTranslation();
  const { handleSelectChannel } = useAIChatInput();
  const { approvedBusinessChannels } = useMyOwnedChannels();

  const [expanded, setExpanded] = useState(false);

  const handleSubmit = (channel: ChannelListItem) => {
    setExpanded(false);
    handleSelectChannel(channel.channelRef, channel.name);
  };

  return (
    <>
      <Wrapper>
        {approvedBusinessChannels
          .slice(0, !expanded ? LIMIT : approvedBusinessChannels.length)
          .map((channel) => (
            <AIChatChannelListItem
              key={channel.channelRef}
              channel={channel}
              disabled={disabled}
              onClick={handleSubmit}
            />
          ))}
      </Wrapper>
      {approvedBusinessChannels.length > LIMIT && (
        <ButtonWrapper>
          <Button
            variant="cta"
            disabled={disabled}
            onClick={() => setExpanded(!expanded)}
            sx={{ mx: "auto" }}
          >
            {!expanded ? t("ui.buttons.show_more") : t("ui.buttons.show_less")}
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
}

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  gap: 0.5rem;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
