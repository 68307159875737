import React from "react";

// utils
import Compass from "#assets/svgs/compass.svg?react";
import Comment from "#assets/svgs/comment.svg?react";
import ProfileMenu from "#components/common/layout/Header/ProfileMenu";

import { styled } from "@mui/material/styles";
import MobileProfileMenu from "#components/common/layout/Header/SideMenu/MobileProfileMenu";

const Wrapper = styled("div")(
  ({ theme }) => `
       & .desktop {   
        ${theme.breakpoints.down("md")} {
          display:none !important;
        }
       }
       
       & .tablet {
        display: flex;
        align-items: center;  
          ${theme.breakpoints.up("md")} {
            display: none;
          }
          
          & .getTheApp {
          margin-right: 1.25rem;
       }
  `
);

type ProfileIconsProps = {
  onClickIcon: () => void;
};

export default function ProfileIcons({ onClickIcon }: ProfileIconsProps) {
  return (
    <Wrapper>
      <div className="desktop">
        <Compass onClick={onClickIcon} />
        <Comment onClick={onClickIcon} />
        <ProfileMenu />
      </div>
      <div className="tablet">
        <MobileProfileMenu />
      </div>
    </Wrapper>
  );
}
