import React, { useMemo } from "react";
import colors from "#shared/config/theme/colors";
import { Box, Button, Chip, styled, Typography } from "@mui/material";
import button from "#shared/config/theme/button";
import IconMagicWrite from "#assets/svgs/magic-write.svg?react";
import IconMagicImage from "#assets/svgs/magic-image.svg?react";
import IconRotateLeft from "#assets/svgs/rotate-left.svg?react";
import IconClose from "#assets/svgs/close.svg?react";
import IconCheck from "#assets/svgs/check.svg?react";
import { FormFields, FormLabel, TextField } from "#shared/components/ui";
import { v4 as uuid } from "uuid";
import useTranslation from "#utils/useTranslation";
import rounding from "#shared/config/theme/rounding";
import AIButton from "#components/common/Editor/components/AIGenerateWidget/AIButton";
import ModalHeader from "#shared/components/ui/Modal/ModalHeader";
import { useTiptapEditorProvider } from "#components/common/Editor/context/TiptapEditorProvider";

interface Props {
  PreviewElement: React.ReactNode;
  FooterElement: React.ReactNode;
  prompt: string;
  isGenerating: boolean;
  isLoading?: boolean;
  type: "write" | "image";
  setPrompt: (prompt: string) => void;
  onPromptFocus?: () => void;
  onGenerate: () => void;
  onConfirm: () => void;
  onDiscard: () => void;
}

export default function AIGenerateWidget({
  PreviewElement,
  FooterElement,
  prompt,
  isGenerating,
  isLoading = false,
  type,
  setPrompt,
  onGenerate,
  onConfirm,
  onDiscard,
  onPromptFocus,
}: Props) {
  const { t } = useTranslation();
  const { isMobileView } = useTiptapEditorProvider();

  const isPreview = !!PreviewElement;
  const textareaId = useMemo(() => uuid(), []);
  const generateDisabled = prompt.length < 3;

  return (
    <Panel className="-widget">
      <PannelInner>
        <Header
          title={t(
            type === "image"
              ? "ui.tiptap_editor.magic_widget.image.title"
              : "ui.tiptap_editor.magic_widget.write.title"
          )}
          inline
          sx={{ pointerEvents: "none" }}
        >
          {type === "image" && (
            <Chip
              color="warning"
              size="small"
              label={t("ui.labels.beta")}
              sx={{
                ".MuiChip-label": {
                  userSelect: "none",
                },
              }}
            />
          )}
        </Header>
        {PreviewElement && (
          <Box>
            <FormLabel label={t("ui.tiptap_editor.magic_widget.preview")} />
            <PreviewContainer>{PreviewElement}</PreviewContainer>
          </Box>
        )}
        <FormFields>
          <TextField
            id={textareaId}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onFocus={onPromptFocus}
            label={t("ui.tiptap_editor.magic_widget.label")}
            placeholder={t(
              type === "image"
                ? "ui.tiptap_editor.magic_widget.image.placeholder"
                : "ui.tiptap_editor.magic_widget.write.placeholder"
            )}
            autoFocus
            required
            multiline
            minRows={3}
          />
        </FormFields>
        {type === "image" && (
          <Typography
            variant="label3"
            color={colors.text.tertiaryDark}
            fontStyle="italic"
            component="span"
          >
            {t("ui.tiptap_editor.magic_widget.image.disclaimer")}
          </Typography>
        )}
        <PanelFooter>
          <Box className="footer-buttons" display="flex" gap={3}>
            {FooterElement}
            {isPreview && (
              <StyledButton
                variant={"outlined"}
                size="small"
                onClick={onGenerate}
                disabled={isLoading || isGenerating || generateDisabled}
                startIcon={!isMobileView && <IconRotateLeft />}
                sx={{ minWidth: [0, button.width.s] }}
              >
                {!isMobileView ? t("ui.buttons.regenerate") : <IconRotateLeft />}
              </StyledButton>
            )}
          </Box>
          <Box className="footer-buttons" display="flex" gap={3}>
            {!isPreview ? (
              <AIButton
                variant="contained"
                size="small"
                onClick={onGenerate}
                loading={false}
                disabled={isGenerating || generateDisabled}
                startIcon={type === "write" ? <IconMagicWrite /> : <IconMagicImage />}
                sx={{ minWidth: button.width.s }}
              >
                {t("ui.buttons.generate")}
              </AIButton>
            ) : (
              <>
                <StyledButton
                  variant={isMobileView ? "outlined" : "text"}
                  size="small"
                  onClick={onDiscard}
                  sx={{ minWidth: button.width.xs }}
                >
                  {!isMobileView ? t("ui.buttons.discard") : <IconClose />}
                </StyledButton>
                <SyledAIButton
                  variant="contained"
                  size="small"
                  onClick={onConfirm}
                  loading={isLoading}
                  disabled={isLoading || isGenerating || generateDisabled}
                  sx={{ minWidth: [0, button.width.s] }}
                >
                  {!isMobileView ? t("ui.buttons.insert") : <IconCheck />}
                </SyledAIButton>
              </>
            )}
          </Box>
        </PanelFooter>
      </PannelInner>
    </Panel>
  );
}

const Panel = styled(Box)`
  --background: ${colors.grey[50]};
  --border-width: 2px;

  position: relative;
  border-radius: 8px;
  padding: var(--border-width);
  border: var(--border-width) transparent;
  border-radius: ${rounding.default};
  background-image: linear-gradient(var(--background), var(--background)),
    ${colors.gradient.ai.value};
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const PannelInner = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

const PanelFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  margin-top: 0.5rem;
`;

const Header = styled(ModalHeader)`
  padding: 0;
  background: transparent;
  border: none;

  h5 {
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
    line-height: ${({ theme }) => theme.typography.h5.lineHeight};
    font-weight: 500 !important;
  }
`;

const PreviewContainer = styled(Box)`
  margin-top: 0.5rem;
`;

const StyledButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 42px;
    height: 42px;
    min-width: 0 !important;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const SyledAIButton = styled(AIButton)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 42px;
    height: 42px;
    min-width: 0 !important;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;
