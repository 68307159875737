import { ComponentOverrides } from "../../types";

const inputOverrides = (): ComponentOverrides<"MuiInput"> => ({
  styleOverrides: {
    root: {
      // remove underline
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
});

export default inputOverrides;
