import useGetMyFollowedChannels from "#apis/channels/hooks/useGetMyFollowedChannels";
import useGodView from "#containers/GodView/useGodView";
import { mapPaginatedData } from "#utils/paginationUtils";
import { useCallback, useMemo } from "react";

export default function useMyFollowedChannels() {
  const { isEnabled: isGodViewEnabled } = useGodView();
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetMyFollowedChannels({ options: { enabled: !isGodViewEnabled } });

  const channels = useMemo(() => {
    // Filter out duplicate channels
    return mapPaginatedData(data).filter(
      (channel, index, self) => index === self.findIndex((t) => t.id === channel.id)
    );
  }, [data]);

  const isChannelMember = useCallback(
    (channelId: number) => {
      return channels.some((channel) => channel.id === channelId);
    },
    [channels]
  );

  return {
    channels,
    totalItems: channels.length,
    isChannelMember,
    isLoading: isLoading,
    isFetchingNextPage,
    hasNextPage,
    loadNextPage: fetchNextPage,
  };
}
