import React, { useEffect, useRef } from "react";
import Toast from "../Toast";
import { css, Portal, styled } from "@mui/material";
import { useToastProvider } from "../../../context/ToastProvider";

const ToastContainer = () => {
  const { toasts, removeAllToasts, removeToast } = useToastProvider();

  const canCloseRef = useRef(false);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (toasts.length > 0) {
      canCloseRef.current = false;
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
      closeTimeoutRef.current = setTimeout(() => {
        canCloseRef.current = true;
      }, 500);
    }
  }, [toasts]);

  const handleClose = (id: string) => {
    removeToast(id);
  };

  const handleClickAway = () => {
    if (!canCloseRef.current) {
      return;
    }

    removeAllToasts();
  };

  return (
    <Portal>
      <Wrapper onClick={handleClickAway}>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            {...toast}
            open={true}
            onClose={() => handleClose(toast.id)}
          />
        ))}
      </Wrapper>
    </Portal>
  );
};

const Wrapper = styled("div")(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1800;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;

    .MuiSnackbar-root {
      position: relative;
    }

    ${theme.breakpoints.down("md")} {
      left: 0;
      transform: none;
      width: 100%;

      .MuiSnackbar-root {
        width: calc(100% - 1rem);
      }
    }
  `
);

export default ToastContainer;
