import { AI_CHAT_STATIC_MESSAGES } from "#components/common/AIChat/consts";
import { useAIChatProvider } from "#components/common/AIChat/context/AIChatProvider";
import useAIChatSubmitPrompt from "#components/common/AIChat/hooks/useAIChatSubmitPrompt";
import { AIChatRole, AIChatStep } from "#components/common/AIChat/types";

export default function useAIChatInput() {
  const {
    prompt,
    loading,
    stopGenerating,
    history,
    step,
    channel,
    setPrompt,
    setStep,
    setChannel,
    addMessages,
  } = useAIChatProvider();

  const { submitChannelPrompt, submitTextPrompt } = useAIChatSubmitPrompt();

  const handleSubmit = async () => {
    if (!prompt) return;

    const content = prompt.trim();
    setPrompt("");

    switch (step) {
      case AIChatStep.BriefChat:
        await submitTextPrompt(content);
        break;
      default:
        handleSubmitWelcome(content);
        break;
    }
  };

  const handleSubmitWelcome = (content: string) => {
    const step = channel ? AIChatStep.ConfirmChannel : AIChatStep.SelectChannel;
    const message = channel
      ? AI_CHAT_STATIC_MESSAGES.ConfirmChannel(channel.channelName || "")
      : AI_CHAT_STATIC_MESSAGES.ChannelList;

    addMessages([
      {
        role: AIChatRole.User,
        content,
      },
      message,
    ]);
    setStep(step);
  };

  /* Actions event handlers */

  const handleSelectChannel = async (
    channelRef: string,
    channelName: string,
    isConfirm = false
  ) => {
    addMessages([
      isConfirm
        ? AI_CHAT_STATIC_MESSAGES.ApproveChannel
        : AI_CHAT_STATIC_MESSAGES.SelectChannel(channelName),
    ]);
    setChannel({ channelRef, channelName });

    await submitChannelPrompt(history[0].content, channelRef);
  };

  const handleChangeChannel = () => {
    addMessages([
      AI_CHAT_STATIC_MESSAGES.ChangeChannel,
      AI_CHAT_STATIC_MESSAGES.ChannelList,
    ]);
    setStep(AIChatStep.SelectChannel);
  };

  return {
    prompt,
    loading,
    stopGenerating,
    setPrompt,
    channel,
    history,
    step,
    inputDisabled: [AIChatStep.SelectChannel, AIChatStep.ConfirmChannel].includes(step),
    handleSubmit,
    handleSelectChannel,
    handleChangeChannel,
  };
}
