import { useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";

import { useSelectorTyped } from "../../utils";

import { selectUser } from "../../features/user/userSlice";
import { useLazyFetchOneTimeUseTokenQuery } from "../../features/oneTimeUseToken/oneTimeUseTokenAPI";

interface UseFetchQRTokenArgs {
  briefRef: string | null;
}

export default function useFetchQRToken(args: UseFetchQRTokenArgs) {
  const user = useSelectorTyped(selectUser);

  const shouldFetch = !!(user.isLoggedIn && args.briefRef);

  const [intervalDelay, setIntervalDelay] = useState<number | null>(null);

  const [fetch, lazyTokenQuery] = useLazyFetchOneTimeUseTokenQuery();

  const fetchToken = async (token: string) => {
    fetch({ cookies: { user: token }, token: lazyTokenQuery.data }, false);
  };

  /* start periodic token fetch */
  useInterval(() => user.token && fetchToken(user.token), intervalDelay);

  /* only start token fetch after user is logged in and we have the briefRef */
  useEffect(() => {
    if (shouldFetch && user.token) {
      fetchToken(user.token);
      setIntervalDelay(5000);
    }
  }, [shouldFetch, user]);

  return {
    token: lazyTokenQuery.data,
    willFetch: shouldFetch,
    isError: lazyTokenQuery.isError,
  };
}
