import { styled } from "@mui/material";

export const Button = styled("button")`
  border-radius: 50%;
  cursor: default;
  height: 2.3rem;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.75;
  }
`;
