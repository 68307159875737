import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";
import rounding from "../../../theme/rounding";
import colors from "../../../theme/colors";

const paginationItemOverrides = (
  theme: Theme
): ComponentOverrides<"MuiPaginationItem"> => ({
  styleOverrides: {
    root: {
      width: "40px",
      height: "40px",
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      padding: "8px 16px",
      borderRadius: rounding.lg,
      color: colors.text.secondaryDark,
      "&:hover": {
        backgroundColor: theme.palette.primary.ghost,
      },
      "&.Mui-selected": {
        color: colors.base.white,
        backgroundColor: theme.palette.primary.main,

        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  },
});

export default paginationItemOverrides;
