import { ImageOrientation } from "../types/index";

export type MediaMetadata = {
  extension: string;
  width: number;
  height: number;
  size: number;
};

export const LANDSCAPE_THRESHOLD_RATIO = 1.2;

export async function getImageMetadata(url: string): Promise<MediaMetadata> {
  const image = new Image();
  image.crossOrigin = "Anonymous";

  return new Promise((resolve, reject) => {
    image.onload = async function () {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;

      if (!context) {
        return;
      }

      context.drawImage(image, 0, 0);

      try {
        const metadata: MediaMetadata = {
          extension: getFileExtension(image.src),
          width: image.width,
          height: image.height,
          size: await getImageFileSize(image.src),
        };
        resolve(metadata);
      } catch (error) {
        console.error(error);
        reject(new Error("Failed to extract image metadata."));
      }
    };

    image.onerror = function () {
      reject(new Error("Failed to load the image."));
    };

    image.src = url;
  });
}

export async function resizeImage(
  url: string,
  maxWidth: number,
  maxHeight: number
): Promise<File> {
  const image = new Image();
  image.crossOrigin = "Anonymous";

  return new Promise((resolve, reject) => {
    image.onload = async function () {
      let width = image.width;
      let height = image.height;

      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;

        if (width > maxWidth) {
          width = maxWidth;
          height = width / aspectRatio;
        }

        if (height > maxHeight) {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;

      if (!context) {
        reject(new Error("Failed to create canvas context."));
        return;
      }

      context.drawImage(image, 0, 0, width, height);

      try {
        const originalExtension = getFileExtension(url);

        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], `image.${originalExtension}`, {
              type: `image/${originalExtension}`,
              lastModified: Date.now(),
            });
            resolve(file);
          } else {
            reject(new Error("Failed to convert canvas to blob."));
          }
        }, `image/${originalExtension}`);
      } catch (error) {
        console.error(error);
        reject(new Error("Failed to resize the image."));
      }
    };

    image.onerror = function () {
      reject(new Error("Failed to load the image."));
    };

    image.src = url;
  });
}

export async function convertImageUrlToFile(url: string): Promise<File | undefined> {
  if (!url) {
    return undefined;
  }

  const fullUrl = url.includes("blob:") ? url : `${url}?timestamp=${Date.now()}`;

  const response = await fetch(fullUrl, {
    mode: "cors",
    cache: "no-cache",
    headers: {
      Origin: window.location.origin,
    },
  });

  const blob = await response.blob();
  const file = new File([blob], `image.png`, {
    type: `image/png`,
    lastModified: Date.now(),
  });
  return file;
}

async function getImageFileSize(url: string): Promise<number> {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob.size;
}

function getFileExtension(url: string): string {
  const match = url.match(/\.([a-z]+)(?:[?#]|$)/i);
  return match ? match[1] : "";
}

// Using image instead of fetch here to avoid possible multiple requests
const getImageResolution = (src: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = (err) => reject(err);
    img.src = src;
  });
};

export async function getImageOrientation(src: string): Promise<ImageOrientation> {
  const { width, height } = await getImageResolution(src);
  const aspectRatio = width / height;

  if (aspectRatio >= LANDSCAPE_THRESHOLD_RATIO) {
    return ImageOrientation.Landscape;
  }

  if (aspectRatio < LANDSCAPE_THRESHOLD_RATIO && aspectRatio > 1) {
    return ImageOrientation.Squarish;
  }

  return ImageOrientation.Portrait;
}
