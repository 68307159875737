import { styled as MuiStyled } from "@mui/material/styles";
import { Snackbar } from "@mui/material";
import { SnackbarProps } from "@mui/material";

const WrapperCookieConsent = MuiStyled("div")(
  ({ theme }) => `
    display: flex;
     ${theme.breakpoints.down("md")} {
        flex-direction: column;
    }
`
);

const WrapperButtons = MuiStyled("div")(
  ({ theme }) => `
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-top: 2.6rem;

    ${theme.breakpoints.down("lg")} {
      margin-top: 3.3rem;
    }
    
    ${theme.breakpoints.down("md")} {
        justify-content: flex-start;
        margin-top: 2.6rem;
    }

    ${theme.breakpoints.down("sm")} {
        justify-content: flex-start;
        margin-top: 1.9rem;
    }
`
);

const WrapperTextContent = MuiStyled("div")(
  ({ theme }) => `
     ${theme.breakpoints.up("md")} {
       width: 50%;
    }
`
);

const StyledSnackBar = MuiStyled(Snackbar)<SnackbarProps>(
  ({ theme }) => `
  &.MuiSnackbar-root {
     width: 100%;
     height: 7.5rem;
     background-color: black;
     justify-content: left;
     transition: all .3s ease-out;
     align-items: flex-start;
     bottom: 0;
     ${theme.breakpoints.down("lg")} {
        height: 9rem;
    }
    ${theme.breakpoints.down("md")} {
        height: 14rem;
    }
    ${theme.breakpoints.down("sm")} {
        left: 0;
        height: 15rem;
    }
    
    
    .MuiSnackbarContent-root {
        background-color: black;
        width: 100%;
        .MuiSnackbarContent-message {
             width: 100%;
             button:first-of-type {
                 margin-right: 1rem;
             }
        }
    }
  }
`
);

export { StyledSnackBar, WrapperTextContent, WrapperButtons, WrapperCookieConsent };
