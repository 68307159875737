import { GetChannelMembersRequest, SearchChannelsRequest } from "#customTypes/channel";
import { QueryKeyParamsFromRequest } from "#customTypes/reactQuery";
import { createQueryKey, QueryKeyEntity } from "#utils/queryKeys";

export const ChannelsQueryKeys = {
  searchChannels: (params?: QueryKeyParamsFromRequest<SearchChannelsRequest>) =>
    createQueryKey([QueryKeyEntity.channels, "search"], params),
  getMembers: (params?: QueryKeyParamsFromRequest<GetChannelMembersRequest>) =>
    createQueryKey([QueryKeyEntity.channels, "members"], params),
  search: [QueryKeyEntity.channels, "search"],
  owned: [QueryKeyEntity.channels, "my-owned"],
  followed: [QueryKeyEntity.channels, "my-followed"],
  suggested: [QueryKeyEntity.channels, "suggested"],
  members: [QueryKeyEntity.channels, "members"],
};
