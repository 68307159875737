import ModalContent from "#shared/components/ui/Modal/ModalContent";
import useChannelPermissions from "#components/pages/ChannelDetails/useChannelPermissions";
import {
  CreateChannelFormField,
  CreateChannelFormType,
} from "#components/pages/Feed/ChannelsSidebar/CreateChannel/useCreateChannelForm";
import {
  IMAGE_TYPES,
  OrganisationPositionType,
  OrganisationStatus,
} from "#customTypes/types";
import { selectUser } from "#features/user/userSlice";
import { Box, Typography, styled } from "@mui/material";
import SelectCard from "#shared/components/ui/SelectCard";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { useFormContext } from "#shared/context/FormContext";
import { alphaColor } from "#shared/utils";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Profile from "#assets/svgs/profile.svg";
import getResourcesURL from "#utils/getResourcesURL";
import DefaultChannelImage from "#assets/svgs/default_channel.svg";
import { useGlobalAppProvider } from "#containers/Global/GlobalAppProvider";
import useGodView from "#containers/GodView/useGodView";

export default function CreateChannelStepOne() {
  const intl = useIntl();
  const user = useSelector(selectUser);

  const { hasPersonalChannel } = useChannelPermissions();
  const { isEnabled: isGodViewEnabled } = useGodView();
  const { positions } = useGlobalAppProvider();

  const { formValues, setValue } = useFormContext<CreateChannelFormType>();

  const filteredOrganisations =
    positions?.filter(
      (c) =>
        [OrganisationPositionType.Owner, OrganisationPositionType.Admin].includes(c.id) &&
        c.organisation.status === OrganisationStatus.VERIFIED
    ) || [];

  const selectItem = (id: number, avatar: string, name: string) => {
    const logoSrc = getResourcesURL(avatar, IMAGE_TYPES.USER_AVATAR);

    return (
      <SelectCard
        selected={formValues.organisationId === id}
        onClick={() => setValue(CreateChannelFormField.organisationId, id)}
      >
        <Box display="flex" alignItems="center" height={[46, 60]} gap={[2, 4]}>
          <StyledOrganisationAvatar
            src={logoSrc || DefaultChannelImage}
            alt={name}
            sx={{ flexShrink: 0 }}
          />
          <Typography
            variant="h5"
            fontFamily="Avenir-heavy"
            color={colors.text.primaryDark}
          >
            {name}
          </Typography>
        </Box>
      </SelectCard>
    );
  };

  return (
    <ModalContent>
      <Typography variant="label2" color={colors.text.secondaryDark} mb={4}>
        <FormattedMessage id="ui.modals.create_channel.step_one.description" />
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {filteredOrganisations.map(({ organisation }) => (
          <Box key={organisation.id}>
            {selectItem(organisation.id, organisation.logo, organisation.name)}
          </Box>
        ))}
        {!hasPersonalChannel &&
          !isGodViewEnabled &&
          selectItem(
            0,
            user.profile.avatar || Profile,
            intl.formatMessage({ id: "pages.feed.filters.personal" })
          )}
      </Box>
    </ModalContent>
  );
}

const StyledOrganisationAvatar = styled("img")`
  width: 3rem;
  max-height: 3rem;
  object-fit: contain;
  border-radius: ${rounding.default};
  border: 3px solid ${alphaColor(colors.base.white, 0.6)};
  background: ${colors.base.white};
  flex-shrink: 0;
  object-fit: cover;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 2rem;
  }
`;
