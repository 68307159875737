import React from "react";
import { useIntl } from "react-intl";
import { styled } from "@mui/material";

// components
import Language from "#components/common/i18n/Language";

// utils
import { useSelectorTyped } from "../../../../../utils";
import QuestionMark from "#components/common/layout/Header/QuestionMark";
import { selectLocale } from "#features/locale/localeSlice";

const Wrapper = styled("div")`
  display: flex;
  gap: 16px;
`;

type LoginIconsProps = {
  shouldRenderLanguageMenu: boolean;
  onSelectLocale: (locale: string) => void;
};

export default function LoginIcons({
  onSelectLocale,
  shouldRenderLanguageMenu,
}: LoginIconsProps) {
  const locale = useSelectorTyped(selectLocale);
  const intl = useIntl();
  return (
    <Wrapper>
      {shouldRenderLanguageMenu && (
        <Language
          buttonLabel={intl.formatMessage({
            id: `locales.${locale}`,
          })}
          onSelectLocale={(locale) => onSelectLocale(locale)}
        />
      )}

      <QuestionMark background={"black"} />
    </Wrapper>
  );
}
