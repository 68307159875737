/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useCallback,
  useEffect,
  useState,
  ReactNode,
  useMemo,
  useContext,
  createContext,
} from "react";
import {
  AIChatChannel,
  AIChatMessageProps,
  AIChatRole,
  AIChatStep,
} from "#components/common/AIChat/types";
import { delay } from "#components/common/AIChat/utils";
import useChannelPermissions from "#components/pages/ChannelDetails/useChannelPermissions";
import useSelectedChannel from "#components/pages/ChannelDetails/useSelectedChannel";
import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";

interface Props {
  step: AIChatStep;
  prompt: string;
  loading: boolean;
  history: AIChatMessageProps[];
  channel?: AIChatChannel;
  controller: AbortController;
  setPrompt: (prompt: string) => void;
  setStep: (step: AIChatStep) => void;
  addMessages: (messages: AIChatMessageProps[]) => void;
  setChannel: (channel: AIChatChannel) => void;
  setLoading: (loading: boolean) => void;
  stopGenerating: () => void;
  reset: () => void;
}

const AIChatContext = createContext<Props>({
  step: AIChatStep.Welcome,
  prompt: "",
  loading: false,
  history: [],
  controller: new AbortController(),
  setPrompt: () => {},
  setStep: () => {},
  addMessages: () => {},
  setLoading: () => {},
  setChannel: () => {},
  stopGenerating: () => {},
  reset: () => {},
});

type ProviderProps = {
  children: ReactNode;
};

export function AIChatProvider({ children }: ProviderProps) {
  const { data: channelData, channelRef } = useSelectedChannel();
  const { canEditChannel } = useChannelPermissions(channelRef);
  const { approvedBusinessChannels } = useMyOwnedChannels();

  const [controller, setController] = useState<AbortController>(new AbortController());

  const [step, setStep] = useState<AIChatStep>(AIChatStep.Welcome);
  const [history, setHistory] = useState<AIChatMessageProps[]>([]);
  const [channel, setChannel] = useState<AIChatChannel | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");

  useEffect(() => {
    // Skip if chat is already in progress
    if (history.length > 0) {
      return;
    }

    setDefaultState();
  }, [channelData, approvedBusinessChannels, canEditChannel]);

  const addMessages = useCallback(async (messages: AIChatMessageProps[]) => {
    const delayMessages =
      messages.length > 1 && messages.some((m) => m.role === AIChatRole.Assistant);

    if (!delayMessages) {
      setHistory((prev) => [...prev, ...messages]);
      return;
    }

    // If there are multiple messages and some are from Assistant (FE generated), fake a loading delay
    for (const [index, message] of messages.entries()) {
      setHistory((prev) => [...prev, message]);

      // If there is a next message, add a loading state
      if (messages[index + 1]) {
        setLoading(true);
        await delay(1000);
        setLoading(false);
      }
    }
  }, []);

  const stopGenerating = useCallback(() => {
    if (!loading) {
      return;
    }

    controller.abort();
    setLoading(false);
    setController(new AbortController());
  }, [loading]);

  const setDefaultState = useCallback(() => {
    setPrompt("");
    setStep(AIChatStep.Welcome);
    setHistory([]);
    setChannel(undefined);
    setLoading(false);

    // If user has only one channel, set it as the default channel
    if (approvedBusinessChannels.length === 1) {
      const channel = approvedBusinessChannels[0];
      setChannel({ channelRef: channel.channelRef, channelName: channel.name });
      setStep(AIChatStep.BriefChat);
      return;
    }

    // If user is on a channel page, set it as the default channel
    if (channelData?.isVerified && canEditChannel) {
      setChannel({
        channelRef: channelData.channelRef,
        channelName: channelData.name,
      });
    }
  }, [channelData, approvedBusinessChannels, canEditChannel]);

  const providerValue = useMemo(
    () => ({
      prompt,
      step,
      loading,
      history,
      channel,
      controller,
      setPrompt,
      setStep,
      setChannel,
      addMessages,
      setLoading,
      stopGenerating,
      reset: setDefaultState,
    }),
    [
      prompt,
      step,
      loading,
      history,
      channel,
      setPrompt,
      setStep,
      setChannel,
      addMessages,
      setLoading,
      stopGenerating,
      setDefaultState,
    ]
  );

  return (
    <AIChatContext.Provider value={providerValue}>{children}</AIChatContext.Provider>
  );
}

export const AIChatConsumer = AIChatContext.Consumer;

export function useAIChatProvider(): Props {
  const context = useContext(AIChatContext);
  if (!context) {
    throw new Error("useBriefActionsProvider must be used within a BriefActionsProvider");
  }
  return context;
}
