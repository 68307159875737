import { useGetWlaOrganisation } from "#apis/organisations/hooks/useGetWlaOrganisation";
import { getIsWlaApp, getOrganisationDomain } from "#utils/organisation";

export default function useWlaOrganisation() {
  const url = typeof window !== "undefined" ? window.location.href : "";
  const domainOrganisation = getOrganisationDomain(url);

  const {
    data: organisation,
    isFetching,
    isLoading,
  } = useGetWlaOrganisation({
    params: { url },
  });

  const isWla = getIsWlaApp(url);

  return { organisation, domainOrganisation, isWla, isFetching, isLoading };
}
