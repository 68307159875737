import { QueryClient, useQuery } from "@tanstack/react-query";
import { AppQueryOptions } from "#customTypes/reactQuery";
import {
  GetWlaOrganisationRequest,
  GetWlaOrganisationResponse,
} from "#customTypes/organisation";
import { OrganisationsQueryKeys } from "#apis/organisations/queryKeys";
import { OrganisationsApi } from "#apis/organisations/api";
import { DEFAULT_ORGANISATION_DOMAIN, getOrganisationDomain } from "#utils/organisation";

interface Props {
  params: GetWlaOrganisationRequest;
  options?: AppQueryOptions<GetWlaOrganisationResponse, any>;
}

const getWlaDomain = (url: string | undefined) => {
  return getOrganisationDomain(url) || DEFAULT_ORGANISATION_DOMAIN;
};

export const useGetWlaOrganisation = ({ params, options }: Props) => {
  const domain = getWlaDomain(params.url);

  return useQuery({
    queryKey: OrganisationsQueryKeys.wla,
    queryFn: () => OrganisationsApi.getWLAOrganisation(domain),
    ...options,
    staleTime: Infinity,
  });
};

export const prefetchWlaOrganisation = async (
  serverQueryClient: QueryClient,
  params: GetWlaOrganisationRequest
) => {
  const domain = getWlaDomain(params.url);

  await serverQueryClient.prefetchQuery({
    queryKey: OrganisationsQueryKeys.wla,
    queryFn: () => OrganisationsApi.getWLAOrganisation(domain),
    staleTime: Infinity,
  });

  return serverQueryClient.getQueryData<GetWlaOrganisationResponse>(
    OrganisationsQueryKeys.wla
  );
};
