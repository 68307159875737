import { Box, Skeleton, styled } from "@mui/material";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import React from "react";

export default function ChannelsBoxSkeleton() {
  const skeleton = (
    <Wrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flex="1"
          gap={3}
          mb={2}
        >
          <Skeleton variant="circular" animation="wave" width={32} height={32} />
          <Box flex="1">
            <Skeleton variant="text" animation="wave" width="60%" height={24} />
            <Skeleton variant="text" animation="wave" width="30%" height={18} />
          </Box>
        </Box>
        <Skeleton variant="rounded" width={40} height={10} sx={{ mt: -4 }} />
      </Box>
      <Skeleton variant="rounded" width="100%" height={16} />
    </Wrapper>
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {[...Array(4)].map((_, index) => (
        <React.Fragment key={index}>{skeleton}</React.Fragment>
      ))}
    </Box>
  );
}

const Wrapper = styled(Box)`
  padding: 1rem;
  background: ${colors.grey[50]};
  border-radius: ${rounding.default};
`;
