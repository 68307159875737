import React, { useState, useCallback } from "react";
import CustomTextField, { CustomTextFieldProps } from "./CustomTextField";
import { InputAdornment, CircularProgress, Tooltip, styled } from "@mui/material";
import DotIcon from "@mui/icons-material/FiberManualRecord";
import CheckIcon from "@mui/icons-material/Check";
import colors from "../../../config/theme/colors";

type AutoSaveTextFieldProps = CustomTextFieldProps & {
  value: string;
  defaultValue: string;
  onSave: (value: string) => Promise<void>;
};

export default function AutoSaveTextField({
  value,
  defaultValue,
  onSave,
  onChange,
  ...props
}: AutoSaveTextFieldProps) {
  const [state, setState] = useState<"idle" | "changed" | "saving" | "saved">("idle");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === defaultValue) {
      setState("idle");
    }

    setState("changed");
    onChange?.(event);
  };

  const handleSave = useCallback(async () => {
    if (state !== "changed") {
      return;
    }

    setState("saving");

    try {
      await onSave(value);
      setState("saved");
    } catch (error) {
      console.error("Failed to save:", error);
      setState("changed");
    }
  }, [value, props]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  return (
    <CustomTextField
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleSave}
      onKeyDown={handleKeyDown}
      disabled={props.disabled || state === "saving"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {state === "saving" ? (
              <Tooltip title="Your changes are being saved, please wait.">
                <IconWrapper>
                  <CircularProgress size={20} />
                </IconWrapper>
              </Tooltip>
            ) : state === "saved" ? (
              <Tooltip title="Your changes have been saved.">
                <IconWrapper>
                  <StyledCheckIcon />
                </IconWrapper>
              </Tooltip>
            ) : state === "changed" ? (
              <Tooltip title="You have unsaved changes. Click anywhere outside to save.">
                <IconWrapper>
                  <StyledDotIcon />
                </IconWrapper>
              </Tooltip>
            ) : null}
          </InputAdornment>
        ),
      }}
    />
  );
}

const IconWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const StyledDotIcon = styled(DotIcon)`
  color: ${colors.grey[300]};
  width: 10px;
  height: 10px;
  cursor: pointer;

  &:hover {
    color: ${colors.grey[500]};
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: ${colors.grey[300]};
  cursor: pointer;

  &:hover {
    color: ${colors.success.default};
  }
`;
