import ConfirmDialog from "#shared/components/ui/Dialog/ConfirmDialog";
import { BaseModalProps, ModalCloseReason } from "#customTypes/modals";
import { Box } from "@mui/material";
import { TextField } from "#shared/components/ui";
import useTranslation from "#utils/useTranslation";
import React, { ReactNode, useEffect, useState } from "react";

interface Props extends BaseModalProps {
  title: string;
  content: string | ReactNode;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  onConfirm: (reason: string) => Promise<void>;
}

export default function ReportDialog({
  title,
  content,
  label,
  placeholder,
  isLoading,
  isOpen,
  onConfirm,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const [reportReason, setReportReason] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setReportReason("");
    }
  }, [isOpen]);

  return (
    <ConfirmDialog
      title={title}
      content={content}
      isOpen={isOpen}
      confirmButtonText={t("ui.buttons.report")}
      confirmButtonDisabled={!reportReason}
      onClose={() => onClose(ModalCloseReason.Dismiss)}
      onConfirm={() => onConfirm(reportReason)}
      isLoading={isLoading}
    >
      <Box mt={4}>
        <TextField
          label={label}
          value={reportReason}
          placeholder={placeholder}
          onChange={(e) => setReportReason(e.target.value)}
          multiline
          minRows={3}
          maxRows={5}
          maxLength={250}
        />
      </Box>
    </ConfirmDialog>
  );
}
