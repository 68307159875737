import React from "react";
import useNavigateToChannel from "#components/pages/Feed/CardFeed/useNavigateToChannel";
import IconSettings from "#assets/svgs/settings.svg?react";
import { FormattedMessage } from "react-intl";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import colors from "#shared/config/theme/colors";
import { CHANNELS_INITIAL_COUNT } from "#constants/channel";
import ChannelsScrollable from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels/components/ChannelsScrollable";
import { ChannelListItem } from "#customTypes/channel";

interface Props {
  channels: ChannelListItem[];
  isLoadingChannels: boolean;
  onSelectInterests: () => void;
}

export default function SuggestedChannelsMobile({
  channels,
  isLoadingChannels,
  onSelectInterests,
}: Props) {
  const navigateToChannel = useNavigateToChannel();

  const content = () => {
    if (isLoadingChannels) {
      return <Skeleton variant="rounded" width="100%" height={96} />;
    }

    if (!channels?.length) {
      return (
        <Typography variant="label3" color={colors.text.secondaryDark}>
          <FormattedMessage id="pages.feed.filters.suggested_channels.no_channels" />
        </Typography>
      );
    }

    return (
      <div key={`filters_feed_private`} className="private">
        <ChannelsScrollable
          channels={channels.slice(0, CHANNELS_INITIAL_COUNT)}
          onClickChannel={(id) => navigateToChannel(id)}
        />
      </div>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography
          variant="label3"
          fontFamily="Avenir-heavy"
          color={colors.text.secondaryDark}
        >
          <FormattedMessage id="pages.feed.filters.suggested_channels.title" />
        </Typography>

        <IconButton size="small" sx={{ mt: "-0.25rem" }} onClick={onSelectInterests}>
          <IconSettings />
        </IconButton>
      </Box>
      {content()}
    </>
  );
}
