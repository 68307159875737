/* SAFARI doesn't allow .jpg files with only MIME types
 * we need to add file extensions too */
export const getExtensionFromMimeType = (mimeTypes: string) =>
  mimeTypes
    .split(",")
    .map((m) => {
      const extenstion = m.split("/")[1];
      if (extenstion) {
        return `.${extenstion}`;
      }
    })
    .filter(Boolean)
    .join(",");
