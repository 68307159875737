import colors from "./colors";
import rounding from "./rounding";
import effects from "./effects";
import button from "./button";

export default {
  colors,
  rounding,
  effects,
  button,
};
