import { ComponentOverrides } from "../../types";
import colors from "../../../theme/colors";
import rounding from "../../../theme/rounding";

const tableContainerOverrides = (): ComponentOverrides<"MuiTableContainer"> => ({
  styleOverrides: {
    root: {
      borderRadius: rounding.default,
      border: `1px solid ${colors.grey[300]}`,
      boxShadow: "none",
    },
  },
});

export default tableContainerOverrides;
