import { styled } from "@mui/material";

export default styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  & > div {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 1rem;
  }
`;
