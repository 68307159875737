import React from "react";
import { Box, SxProps, Theme, styled } from "@mui/material";
import { Step } from "../../../types/steps";
import classNames from "classnames";
import colors from "../../../config/theme/colors";

interface Props {
  steps: Step[];
  sx?: SxProps<Theme>;
}

export default function StepBars({ steps, sx }: Props) {
  return (
    <Wrapper sx={sx}>
      {steps.map((step) => (
        <StepBar
          key={step.id}
          className={classNames({
            "--active": step.active,
            "--completed": step.completed && !step.active,
          })}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.125rem;
`;

const StepBar = styled(Box)`
  background-color: ${colors.base.outline1};
  height: 2px;
  border-radius: 2px;
  flex: 1;
  transition: background-color 0.5s ease-in-out;

  &.--active {
    background-color: ${colors.base.black};
  }

  &.--completed {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
