import generatePresignedUrl, {
  PresignedUrlType,
} from "#apis/upload/generatePresignedUrl";
import uploadPresignedFile from "#apis/upload/uploadPresignedFile";
import { BriefContentImagePresignedUrlData } from "#customTypes/briefBuilder";
import { resizeImage } from "#shared/utils";

export async function uploadEditorImage(
  url: string,
  briefRef: string
): Promise<{ id: string; url: string }> {
  const resizedImage = await resizeImage(url, 700, 700);

  const presignedUrl = await generatePresignedUrl<BriefContentImagePresignedUrlData>({
    type: PresignedUrlType.BriefContentPhoto,
    body: { briefRef },
  });

  if (!presignedUrl?.briefBuilderMediaId) {
    throw new Error("Presigned URL is not generated");
  }

  await uploadPresignedFile({
    data: presignedUrl,
    file: resizedImage,
  });

  return {
    id: presignedUrl.briefBuilderMediaId,
    url: presignedUrl.briefBuilderMediaUrl,
  };
}
