import { Theme } from "@mui/material";
import colors from "../../theme/colors";
import { ComponentOverrides } from "../types";
import rounding from "../../theme/rounding";
import { alphaColor } from "../../../utils";

const switchOverrides = (theme: Theme): ComponentOverrides<"MuiSwitch"> => ({
  styleOverrides: {
    root: {
      width: "40px",
      height: "24px",
      padding: 0,
      borderRadius: rounding.full,
    },
    switchBase: {
      color: colors.grey[100],

      "& + .MuiSwitch-track": {
        backgroundColor: colors.grey[600],
        borderColor: colors.grey[600],
        opacity: 1,
        transition: "background-color 0.2s ease-in-out",
      },

      "&:hover": {
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.light,
        },
      },

      "&.Mui-checked": {
        transform: "translateX(1rem)",
        color: colors.grey[100],

        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          opacity: 1,
        },
      },

      "&.Mui-disabled": {
        "& + .MuiSwitch-track": {
          backgroundColor: colors.base.outline1,
          borderColor: colors.base.outline1,
          opacity: 1,
        },

        "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: alphaColor(theme.palette.primary.main, 0.2),
          borderColor: alphaColor(theme.palette.primary.main, 0.2),
          opacity: 1,
        },

        "& .MuiSwitch-thumb": {
          position: "relative",
          backgroundColor: colors.base.white,

          "&:before": {
            content: "''",
            position: "absolute",
            width: "9px",
            height: "10px",
            left: "5.75px",
            top: "5px",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.3125 2.5V3.75H6.4375V2.5C6.4375 1.63672 5.73828 0.9375 4.875 0.9375C4.01172 0.9375 3.3125 1.63672 3.3125 2.5ZM2.375 3.75V2.5C2.375 1.11914 3.49414 0 4.875 0C6.25586 0 7.375 1.11914 7.375 2.5V3.75H8C8.68945 3.75 9.25 4.31055 9.25 5V8.75C9.25 9.43945 8.68945 10 8 10H1.75C1.06055 10 0.5 9.43945 0.5 8.75V5C0.5 4.31055 1.06055 3.75 1.75 3.75H2.375ZM1.4375 5V8.75C1.4375 8.92188 1.57812 9.0625 1.75 9.0625H8C8.17188 9.0625 8.3125 8.92188 8.3125 8.75V5C8.3125 4.82812 8.17188 4.6875 8 4.6875H1.75C1.57812 4.6875 1.4375 4.82812 1.4375 5Z" fill="${encodeURIComponent(
              alphaColor(theme.palette.primary.main, 0.2)
            )}"/></svg>')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          },
        },
      },
    },
    thumb: {
      width: "20px",
      height: "20px",
      marginTop: "-7px",
      marginLeft: "-7px",
      boxShadow: "none",
    },
    colorSecondary: {
      "& + .MuiSwitch-track": {
        backgroundColor: colors.brand[400],
        borderColor: colors.brand[400],
      },
    },
  },
});

export default switchOverrides;
