import React from "react";
import { RadioOption, RadioValue } from "../../../types/form";
import { RadioGroup, FormControlLabel, SxProps, Theme } from "@mui/material";
import Radio from "./Radio";

interface Props<T> {
  value: T;
  options: RadioOption<T>[];
  name?: string;
  onChange: (value: T) => void;
  sx?: SxProps<Theme>;
}

export default function RadioSelect<T extends RadioValue>({
  value,
  name,
  options,
  onChange,
  sx,
}: Props<T>) {
  return (
    <RadioGroup
      row
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      name={name}
      aria-labelledby={name}
      sx={sx}
    >
      {options.map(({ value, label, Icon }) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<Radio size="small" />}
          label={
            <>
              {Icon && <Icon />}
              {label}
            </>
          }
        />
      ))}
    </RadioGroup>
  );
}
