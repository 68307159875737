import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";

const StyledPlaceholder = styled("div")`
  color: #919191;
`;

export default function Placeholder({ children }: { children: ReactNode }) {
  return <StyledPlaceholder>{children}</StyledPlaceholder>;
}
