import React from "react";
import { Button } from "#shared/components/ui/Button";
import { FormattedMessage } from "react-intl";
import EmailButton from "./styled";

type EmailCustomButtonProps = {
  title: string;
  link: string;
};

export default function EmailCustomButton({ title, link }: EmailCustomButtonProps) {
  const intlPrefix = "pages.email_login.confirm";
  return (
    <EmailButton.Wrapper>
      {title && (
        <Button fullWidth variant="contained" onClick={() => window.open(link)}>
          <FormattedMessage id={`${intlPrefix}.button.open`} /> {" " + title}
        </Button>
      )}
    </EmailButton.Wrapper>
  );
}
