import { UnsplashApi } from "#apis/unsplash/api";
import { UnsplashQueryKeys } from "#apis/unsplash/queryKeys";
import { AppInfiniteQueryOptions, AppInfiniteQueryProps } from "#customTypes/reactQuery";
import {
  SearchUnsplashPhotosRequest,
  SearchUnsplashPhotosResponse,
} from "#customTypes/unsplash";
import { getInfiniteQueryOptions } from "#utils/paginationUtils";
import { useInfiniteQuery } from "@tanstack/react-query";

type Params = AppInfiniteQueryProps<SearchUnsplashPhotosRequest>;

interface Props {
  params: Params;
  options?: AppInfiniteQueryOptions<
    SearchUnsplashPhotosResponse,
    typeof UnsplashQueryKeys.searchPhotos
  >;
}

const PAGE_SIZE = 12;

export default function useSearchUnsplashPhotos({ params, options }: Props) {
  return useInfiniteQuery({
    queryKey: UnsplashQueryKeys.searchPhotos(params),
    queryFn: ({ pageParam }) =>
      UnsplashApi.searchPhotos({
        ...params,
        page: pageParam,
        pageSize: PAGE_SIZE,
      }),
    ...getInfiniteQueryOptions(),
    ...options,
    placeholderData: (prev) => prev,
  });
}
