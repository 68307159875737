export enum ModalCloseReason {
  Dismiss,
  Cancel,
  Confirm,
}

export interface BaseModalProps {
  isOpen: boolean;
  onClose: (reason?: ModalCloseReason) => void;
}
