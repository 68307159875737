import { styled } from "@mui/material";
import { List } from "@mui/material";

const ModalSelectList = styled(List)`
  max-height: 400px;
  overflow: auto;
  margin-inline: calc(var(--modal-padding, 1rem) * -1);
  margin-bottom: calc(var(--modal-padding, 1rem) * -1);
  border-bottom: none;
  border-inline: none;
  border-radius: 0;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-height: 300px;
  }

  .MuiMenuItem-root {
    padding-inline: var(--modal-padding);
  }
`;

export default ModalSelectList;
