import { Dispatch, RefObject, SetStateAction, useRef, useState } from "react";

export default function useCustomPopper<T>(): [
  RefObject<T>,
  boolean,
  Dispatch<SetStateAction<boolean>>
] {
  const anchorRef = useRef<T>(null);
  const [open, setOpen] = useState<boolean>(false);

  return [anchorRef, open, setOpen];
}
