import colors from "../../theme/colors";
import { ComponentOverrides } from "../types";

const listOverrides = (): ComponentOverrides<"MuiList"> => ({
  styleOverrides: {
    root: {
      border: `1px solid ${colors.base.outline1}`,
      borderRadius: "0.625rem",
      padding: 0,
      backgroundColor: colors.base.white,
    },
  },
});

export default listOverrides;
