import React from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { FormattedMessage } from "react-intl";

import CustomPopper from "#shared/components/ui/Popper";
import useCustomPopper from "#shared/components/ui/Popper/useCustomPopper";
import { Button } from "./styles";
import QuestionMarkIcon from "./QuestionMarkIcon";
import { Organisation } from "../../../../../types/db";
import { AvenirTitle, PopperMenuWrapper } from "../../../styled";
import { getOrganisationKeyValue } from "../../../../../utils/organisation";
import { LINK_FALLBACKS } from "#constants/common";
import useWlaOrganisation from "#utils/useWlaOrganisation";

type OrganisationLinkKeys = keyof Pick<
  Organisation,
  "supportURL" | "privacyPolicy" | "termsOfUse"
>;

interface Link {
  id: string;
  WLAId?: string;
  href: string;
  organisationKey: OrganisationLinkKeys;
}

const links: Link[] = [
  {
    id: "header.menu.terms",
    href: LINK_FALLBACKS.terms,
    organisationKey: "termsOfUse",
  },
  {
    id: "header.menu.privacy",
    href: LINK_FALLBACKS.privacy,
    organisationKey: "privacyPolicy",
  },
  {
    id: "header.menu.support",
    href: LINK_FALLBACKS.faq,
    organisationKey: "supportURL",
  },
];

type QuestionMarkProps = {
  background: string;
} & React.SVGProps<SVGSVGElement>;

export default function QuestionMark({ background = "#F2C94C" }: QuestionMarkProps) {
  const [anchorRef, open, setOpen] = useCustomPopper<HTMLButtonElement>();

  const { organisation } = useWlaOrganisation();

  return (
    <>
      <Button
        sx={{ height: "2.125rem", width: "2.125rem" }}
        ref={anchorRef}
        onClick={() => setOpen(true)}
      >
        <QuestionMarkIcon background={background} />
      </Button>
      <CustomPopper
        anchorEl={anchorRef.current}
        open={open}
        onClickAway={() => setOpen(false)}
        placement="bottom-end"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [4, 0],
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              padding: -6,
            },
          },
        ]}
      >
        <PopperMenuWrapper>
          <AvenirTitle
            className="title"
            fontFamily="Avenir-heavy"
            fontSize=".875rem"
            lineHeight={".5rem"}
          >
            <FormattedMessage id="header.menu.help" />
          </AvenirTitle>
          <Divider />
          <MenuList sx={{ border: "none" }}>
            {links.map((li) => {
              const link = getOrganisationKeyValue(organisation, li.organisationKey);
              return (
                <div key={li.id}>
                  <MenuItem
                    sx={{ padding: "0px 32px 0px 12px" }}
                    component="a"
                    key={li.id}
                    href={link || li.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemText>
                      <AvenirTitle
                        className="menuTitle"
                        fontFamily="Avenir"
                        fontSize=".875rem"
                        fontWeight={500}
                        lineHeight="2.625rem"
                      >
                        <FormattedMessage id={li.id} />
                      </AvenirTitle>
                    </ListItemText>
                  </MenuItem>
                </div>
              );
            })}
          </MenuList>
        </PopperMenuWrapper>
      </CustomPopper>
    </>
  );
}
