import { ToastStateOptions } from "../types/toast";
import { useCallback, useState } from "react";
import { useToastProvider } from "../context/ToastProvider";
import { v4 as uuidv4 } from "uuid";

export default function useToast() {
  const { options: contextOptions, addToast, removeToast } = useToastProvider();

  const [lastToastId, setLastToastId] = useState<string | null>(null);

  const open = useCallback((options: Omit<ToastStateOptions, "id">) => {
    const id = uuidv4();
    setLastToastId(id);
    addToast({ id, ...options });
  }, []);

  const success = useCallback(
    (options: Omit<ToastStateOptions, "id" | "type">) => {
      open({
        ...options,
        type: "success",
        message: options.message || "",
      });
    },
    [open]
  );

  const warn = useCallback(
    (options: Omit<ToastStateOptions, "id" | "type">) => {
      open({
        ...options,
        type: "warning",
        message: options.message || "",
      });
    },
    [open]
  );

  const error = useCallback(
    (options?: Omit<ToastStateOptions, "id" | "type">) => {
      open({
        ...options,
        type: "error",
        message: options?.message || contextOptions.defaultErrorMessage || "",
      });
    },
    [open, contextOptions.defaultErrorMessage]
  );

  const close = useCallback(() => {
    if (!lastToastId) {
      return;
    }
    removeToast(lastToastId);
  }, [lastToastId]);

  return {
    open,
    success,
    error,
    warn,
    close,
  };
}
