import React, { useState } from "react";

import { PopperProps, ClickAwayListener } from "@mui/material";

// styled
import { Popper, Wrapper, Arrow } from "./styled";

type CustomPopover = PopperProps & {
  hideArrow?: boolean;
  onClickAway: () => void;
};

export default function CustomPopper({
  open = false,
  anchorEl,
  placement,
  hideArrow = false,
  sx,
  onClickAway,
  children,
  modifiers = [],
}: CustomPopover) {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      modifiers={[
        ...modifiers,
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
      sx={sx}
    >
      <ClickAwayListener touchEvent={false} onClickAway={onClickAway}>
        <Wrapper className="popper-wrapper">
          <>
            {!hideArrow && (
              <Arrow ref={(ref) => setArrowRef(ref)} className="MuiPopper-arrow" />
            )}
            {children}
          </>
        </Wrapper>
      </ClickAwayListener>
    </Popper>
  );
}
