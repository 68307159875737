import React, { ReactElement } from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";

// shared
import CustomPopper from "#shared/components/ui/Popper";

// constants

import { Backdrop, Divider, Typography } from "@mui/material";
import { MenuItemProps } from "#customTypes/menu";
import colors from "#shared/config/theme/colors";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { BottomSheet } from "react-spring-bottom-sheet";
import useMenuDropdown from "#components/common/Dropdown/useMenuDropdown";
import { MODAL_DEFAULT_Z_INDEX } from "#constants/common";

interface Props {
  items: MenuItemProps[];
  AnchorElement: ReactElement;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onItemClick?: (id: string) => void;
}

export default function MenuDropdown({
  items,
  AnchorElement,
  onMenuOpen,
  onMenuClose,
  onItemClick,
}: Props) {
  const { isMobile } = useMediaQueryProvider();
  const { anchorRef, open, openMenu, closeMenu, onClickMenuItem } = useMenuDropdown({
    items,
    onMenuOpen,
    onMenuClose,
    onItemClick,
  });

  if (!AnchorElement) {
    return null;
  }

  const list = (
    <Wrapper>
      <MenuList sx={{ border: "none", minWidth: 220 }}>
        {items
          .filter((i) => !i.hidden)
          .map(({ id, Icon, EndIcon, label, divided, color }) => (
            <div key={id}>
              {divided && <Divider />}
              <MenuItem
                key={id}
                className={`-${color}`}
                onClick={() => onClickMenuItem(id)}
              >
                {Icon && (
                  <ListItemIcon sx={{ width: "10px", color: colors.base.black }}>
                    <Icon />
                  </ListItemIcon>
                )}
                <ListItemText>
                  <Typography variant="label2" fontFamily="Avenir-heavy">
                    {label}
                  </Typography>
                </ListItemText>
                {EndIcon && (
                  <ListItemIcon className="endIcon">
                    <EndIcon />
                  </ListItemIcon>
                )}
              </MenuItem>
            </div>
          ))}
      </MenuList>
    </Wrapper>
  );

  return (
    <>
      <div ref={anchorRef} onClick={openMenu}>
        {AnchorElement}
      </div>

      {isMobile ? (
        <BottomSheet
          open={open}
          onDismiss={closeMenu}
          className="react-bottom-sheet -dismissable"
          blocking={false}
          sibling={
            <Backdrop
              open={open}
              onClick={closeMenu}
              sx={{ zIndex: MODAL_DEFAULT_Z_INDEX }}
            />
          }
        >
          {list}
        </BottomSheet>
      ) : (
        <CustomPopper
          anchorEl={anchorRef.current}
          open={open}
          onClickAway={closeMenu}
          placement="bottom-end"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [20, 0],
              },
            },
          ]}
        >
          {list}
        </CustomPopper>
      )}
    </>
  );
}

const Wrapper = styled("div")(
  () => `
  
  & .MuiList-root {
    padding: 0;
  }
  
  & .MuiMenuItem-root {
    max-height: 2.5rem;

    &.-gradient {
      .MuiTypography-root {
        background: ${colors.gradient.ai.value};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      svg {
        color: ${colors.gradient.ai.start};
      }
    }

    &.-error {
      .MuiListItemIcon-root,
      .MuiTypography-root {
        color: ${colors.error.default};
      }
    }
  }

  & .MuiListItemIcon-root {
    min-width: 1.25rem !important;
    width: 1.25rem;
    display: flex;
    justify-content: center;

    &:not(.endIcon) {
      margin-right: 0.5rem;
    }

    &.endIcon {
      margin-right: -0.375rem;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  
  & .MuiDivider-root {
    margin: 0 !important;
  }

  & .title {
    display: inline;
  }
`
);
