import React from "react";
import { CircleImage } from "#components/common/styled";

import DefaultUserImage from "#assets/svgs/profile.svg";
import DefaultChannelImage from "#assets/svgs/default_channel.svg";

import getResourcesURL from "../../../utils/getResourcesURL";
import { IMAGE_TYPES } from "../../../types/types";

type Props = {
  uri: string;
  type?: IMAGE_TYPES;
  diameter: number;
  className?: string;
};

export default function Avatar({
  uri,
  type = IMAGE_TYPES.CHANNEL_LOGO,
  diameter,
  className,
}: Props) {
  const logoSrc = getResourcesURL(uri, type);

  const onError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = getDefaultImage(type);
  };

  return (
    <CircleImage
      key={uri}
      className={className}
      diameter={diameter}
      background="rgba(241, 241, 242, 0.9)"
      src={logoSrc || getDefaultImage(type)}
      alt="brand_logo"
      onError={onError}
    />
  );
}

export const getDefaultImage = (type: IMAGE_TYPES) => {
  switch (type) {
    case IMAGE_TYPES.CHANNEL_LOGO:
    case IMAGE_TYPES.ORGANISATION_LOGO:
      return DefaultChannelImage;
    default:
      return DefaultUserImage;
  }
};
