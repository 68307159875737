import React from "react";

import IconArrow from "#assets/svgs/chevron-left.svg?react";
import { Box, IconButton, styled } from "@mui/material";
import { useIsMobileApp } from "#utils/useIsMobileApp";
import { ButtonIDs } from "#constants/buttonIDs";

export default function ChannelBackAction() {
  const { isMobileApp } = useIsMobileApp();

  if (!isMobileApp) {
    return null;
  }

  return (
    <Wrapper>
      <IconButton size="small" color="blured" id={ButtonIDs.channel.back}>
        <IconArrow />
      </IconButton>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  position: absolute;
  top: 10px;
  left: 10px;
`;
