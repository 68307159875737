import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";

const formControlOverrides = (theme: Theme): ComponentOverrides<"MuiFormControl"> => ({
  styleOverrides: {
    root: {
      width: "100%",
      input: {
        fontSize: theme.typography.body4.fontSize,
      },
    },
  },
});

export default formControlOverrides;
