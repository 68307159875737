import { ButtonProps } from "@mui/material/Button";
import { Button, CircularProgress } from "@mui/material";
import React, { forwardRef } from "react";
import classNames from "classnames";

interface Props extends ButtonProps {
  loading: boolean;
}

const LoadingButton = forwardRef<HTMLButtonElement, Props>(
  ({ loading, children, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        className={classNames(loading && "Mui-loading")}
        {...props}
        startIcon={!loading ? props.startIcon : undefined}
        endIcon={!loading ? props.endIcon : undefined}
        disabled={loading || props.disabled}
      >
        {loading ? <CircularProgress color="inherit" size={20} /> : children}
      </Button>
    );
  }
);

LoadingButton.displayName = "LoadingButton";

export default LoadingButton;
