const effects = {
  shadow: {
    sm: "0px 1px 2px 0px #0000000D;",
    base: "0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A",
    md: "0px 2px 4px -1px #0000000F, 0px 4px 6px -1px #0000001A",
    lg: "0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A, 0px -5px 10px -3px #0000000D",
    xl: "0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000001A",
    "2xl": "0px 25px 50px -12px #00000040",
    inner: "0px 2px 4px 0px #0000000F inset",
  },
  blur: {
    sm: "blur(2px)",
    base: "blur(4px)",
    md: "blur(6px)",
    lg: "blur(8px)",
    xl: "blur(12px)",
    "2xl": "blur(20px)",
    "3xl": "blur(32px)",
    none: "none",
  },
};

export default effects;
