import React from "react";
import { useIntl } from "react-intl";

import { AuthSeparatorWrapper } from "../styled";

const AuthSeparator = () => {
  const intl = useIntl();

  return (
    <AuthSeparatorWrapper>
      <div className="text">
        {intl.formatMessage({ id: "pages.login.content.separator" })}
      </div>
    </AuthSeparatorWrapper>
  );
};

export default AuthSeparator;
