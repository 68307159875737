import { ReactNode } from "react";

import useMergeState from "../../../utils/hooks/useMergeState";

export type UseModalProps = {
  open?: boolean;
  Content?: ReactNode | null;
};

export default function useModal({
  open = false,
  Content,
}: UseModalProps): [UseModalProps, (props: UseModalProps) => void] {
  const [modalDetails, setModalDetails] = useMergeState<UseModalProps>({
    open,
    Content,
  });
  return [modalDetails, setModalDetails];
}
