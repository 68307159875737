import React, { ReactNode } from "react";
import { ToastProvider as SharedToastProvider } from "#shared/context/ToastProvider";
import ToastContainer from "#shared/components/ui/ToastContainer";
import useTranslation from "#utils/useTranslation";

interface Props {
  children: ReactNode;
}

export default function ToastProvider({ children }: Props) {
  const { t } = useTranslation();

  return (
    <SharedToastProvider
      options={{ defaultErrorMessage: t("errors.something_went_wrong") }}
    >
      {children}
      <ToastContainer />
    </SharedToastProvider>
  );
}
