import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { FormHelperText, InputBaseComponentProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControl from "../FormControl";

// @ts-ignore
import CalendarIcon from "../../../assets/svgs/arrow-dropdown.svg?react";
import colors from "../../../config/theme/colors";
import { FormField } from "../../../components/ui/Form";
import rounding from "../../../config/theme/rounding";

const StyledDatePicker = styled(DatePicker)`
  border-radius: 0.625rem;

  input {
    height: 54px;
    border-radius: 0 !important;
    border: none !important;
    background: none !important;
    box-sizing: border-box;

    &:hover,
    &:focus {
      background: none;
    }
  }

  .Mui-focused {
    background-color: ${colors.input.backgroundFocused};
    button {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .MuiInputAdornment-positionEnd .MuiButtonBase-root {
    margin-left: -2.25rem;
  }
`;

interface CustomDatePickerProps extends DatePickerProps<any> {
  id?: string;
  name: string;
  placeholder: string;
  error?: boolean;
  errorMessage?: string | undefined;
  className?: string;
  isTextFieldDisabled?: boolean;
  inputProps?: InputBaseComponentProps | undefined;
  label?: string;
}

export default function CustomDatePicker({ label, ...props }: CustomDatePickerProps) {
  if (label) {
    return (
      <FormField label={label} name={props.name || props.id}>
        <FormControl>
          <DatePickerComponent {...props} />
        </FormControl>
      </FormField>
    );
  }

  return (
    <FormControl>
      <DatePickerComponent {...props} />
    </FormControl>
  );
}

const DatePickerComponent = (
  props: Omit<CustomDatePickerProps, "formProps" | "label">
) => {
  const [open, setOpen] = useState<boolean>(false);

  const {
    id,
    name,
    placeholder,
    value,
    defaultValue,
    error,
    errorMessage,
    format,
    isTextFieldDisabled,
    ...datePickerProps
  } = props;

  const hasError = !!(error && errorMessage);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          {...datePickerProps}
          id={id}
          name={name}
          value={value}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
          format={format || "DD/MM/YYYY"}
          slots={{
            openPickerIcon: () => (
              <CalendarIcon className="calendarIcon" width="24px" height="24px" />
            ),
          }}
          slotProps={{
            popper: {
              sx: {
                ".MuiPaper-root": {
                  marginTop: "1rem",
                  borderRadius: rounding.default,
                  border: `1px solid ${colors.base.outline1}`,
                  boxShadow: "0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000001A",

                  ".MuiPickersCalendarHeader-label": {
                    fontSize: "1rem",
                    fontFamily: "Avenir-heavy",
                    marginRight: "0.25rem",
                  },

                  ".MuiDayCalendar-weekDayLabel": {
                    fontSize: "0.75rem",
                    color: colors.text.secondaryDark,
                  },

                  ".MuiPickersDay-root": {
                    fontSize: "0.875rem",
                    borderRadius: rounding.default,
                    color: colors.text.secondaryDark,

                    "&.Mui-selected": {
                      color: colors.base.white,
                      fontWeight: 400,
                    },
                  },
                },
              },
            },
            textField: {
              id,
              name,
              defaultValue,
              error: hasError,
              placeholder,
              fullWidth: true,
              // @ts-expect-error readoly prop exists but is absent from the type definitions
              readOnly: isTextFieldDisabled,
              onKeyDown: (e) => e.preventDefault(),
              onClick: () => setOpen(true),
            },
          }}
        />
      </LocalizationProvider>
      {hasError && (
        <FormHelperText className="muiSelect" error>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};
