import { flattenArray } from "../../utils/array.utils";
import { isBrowser } from "../../utils/browser.utils";

type i18nConfig = {
  defaultLocale: string;
  availableLocales: string[];
};

class LocaleService {
  defaultLocale: string;
  availableLocales: string[];

  constructor(config: i18nConfig) {
    this.defaultLocale = config.defaultLocale;
    this.availableLocales = config.availableLocales;
  }

  async getMessages(lang: string, messages: any) {
    if (this.availableLocales.includes(lang)) {
      return flattenArray(messages);
    }
  }

  getDefaultLocale() {
    return this.defaultLocale;
  }

  getAvailableLocales() {
    return this.availableLocales;
  }

  setAvailableLocales(locales: string[]) {
    this.availableLocales = locales;
  }
}
export default new LocaleService({
  defaultLocale: isBrowser
    ? localStorage.getItem("locale") || navigator.language
    : "en",
  availableLocales: ["en"],
});
