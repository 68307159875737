import { PaginationResponse } from "src/types/pagination";

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE = 1;

type DefaultProps = Pick<PaginationResponse, "page" | "totalPages">;

interface DefaultReturn<Q> {
  data: Q[];
  pagination: PaginationResponse;
}

export async function fetchAllPages<T extends DefaultProps, Q>({
  queryFn,
  params,
  pageSize = DEFAULT_PAGE_SIZE,
  data,
  page = 1,
}: {
  queryFn: (params: T) => Promise<DefaultReturn<Q>>;
  params?: Omit<T, "page" | "pageSize">;
  data?: Q[];
  page?: number;
  pageSize?: number;
}): Promise<Q[]> {
  const prms = {
    ...params,
    page,
    pageSize,
  } as unknown as T;
  const response = await queryFn(prms);

  const items = data ? [...data, ...response.data] : response.data;

  if (response.pagination.page < (response.pagination.totalPages || DEFAULT_PAGE)) {
    return fetchAllPages({
      queryFn,
      params,
      pageSize,
      data: items,
      page: response.pagination.page + 1,
    });
  }

  return items;
}
