import useRefetchApp from "#containers/GodView/useRefetchApp";
import { AdminOrganisation } from "#customTypes/admin";
import {
  setGodViewModal,
  removeGodView,
  selectGodView,
  setGodView,
} from "#features/superAdmin/godViewSlice";
import { selectUser } from "#features/user/userSlice";
import { useSelectorTyped } from "#utils/index";
import { isUserSuperAdmin } from "#utils/userUtil";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export default function useGodView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { refetchApp } = useRefetchApp();
  const user = useSelectorTyped(selectUser);
  const { isModalOpen, selectedOrganisation } = useSelectorTyped(selectGodView);

  const organisation = isUserSuperAdmin(user.profile) ? selectedOrganisation : undefined;

  const openModal = () => dispatch(setGodViewModal(true));

  const closeModal = () => dispatch(setGodViewModal(false));

  const enterGodView = useCallback(
    (organisation: AdminOrganisation) => {
      if (selectedOrganisation?.id === organisation.id) {
        return;
      }

      navigate("/");
      refetchApp(organisation.id);
      dispatch(setGodView(organisation));
    },
    [selectedOrganisation]
  );

  const exitGodView = useCallback(() => {
    navigate("/");
    refetchApp(undefined);
    dispatch(removeGodView());
  }, []);

  return {
    isModalOpen,
    isEnabled: !!organisation,
    organisation,
    openModal,
    closeModal,
    enterGodView,
    exitGodView,
  };
}
