import { styled } from "@mui/material/styles";
import { LoginWrapperBase } from "../../../common/styled";
import { CircularProgress } from "@mui/material";

export const SubmissionForm = styled(LoginWrapperBase)(
  ({ theme }) => `
  width: 100%;
  max-width: 24rem;

  & .calendarIcon {
    margin-right: 0.125rem;
    
    path {
      fill: ${theme.palette.primary.main};
    }
  }

  .dropdown, .autocomplete {
    svg {
      color: #4F4F4F !important;
    }
  }
  .calendarIcon {
    path {
      fill: #4F4F4F !important;
    }
  }

  & .submitRegistration {
    margin-top: 2.375rem;
  }


  & .errorForm {
    margin-bottom: 1rem;
  }
`
);

export const Wrapper = styled("div")`
  width: 20.625rem;
`;

export const InputsGroup = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .row {
    display: flex;
    gap: 1.75rem;

    .split {
      flex: 1;
    }
  }
`;

export const WhiteCircularProgress = styled(CircularProgress)`
  svg {
    color: #fff;
  }
`;

export const InputNote = styled("div")`
  font-weight: 500;
  font-size: 0.85rem;
  color: #828282;
  padding: 0.3rem 1.4rem 0.2rem;
`;
