import colors from "#shared/config/theme/colors";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeaderWrapper = styled("div")(
  ({ theme }) => `
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-around;
    height: var(--header-height);
    padding: 0 1.25rem;
    width: 100%;
    box-sizing: border-box;
    
    & .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
 
      & .icons > div,  & .icons .desktop {
      display: flex;
      align-items: center;

        & .profile {
          border-radius: 50%;
          margin-top: 0.25rem;
        }
          
      }
       
      & .icons .desktop > svg {
         margin-right: 1.625rem;
      }

      .logo-link {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    & .inner.headerWithProfile {
       width: 100%;
       max-width: var(--container-width);

       a {
        display: flex;
       }
    }

    svg: {
      width: 100%;
      height: auto;
    }

    ${theme.breakpoints.down("md")} {
      padding: 0 0.5rem;
    }
`
);

export const HeaderSpacer = styled("div")`
  height: var(--header-height);
`;

export const MobileBackButton = styled(IconButton)`
  svg {
    transform: rotate(90deg);
    path {
      fill: ${colors.base.black};
    }
  }
`;
