import { Theme } from "@mui/material";
import { ComponentOverrides } from "../types";

const snackbarOverrides = (theme: Theme): ComponentOverrides<"MuiSnackbar"> => ({
  styleOverrides: {
    root: {
      "& .MuiAlert-root": {
        maxWidth: "620px",
        minWidth: "420px",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          minWidth: "inherit",
          width: "100%",
        },
      },
    },
    anchorOriginTopCenter: {
      top: "70px !important",
      [theme.breakpoints.down("sm")]: {
        top: "60px !important",
      },
    },
  },
});

export default snackbarOverrides;
