import React, { useEffect, useState } from "react";
import MenuIcon from "#assets/svgs/menu.svg?react";
import CloseIcon from "#assets/svgs/close-dialog.svg?react";
import { Box, Divider, Drawer, IconButton, styled } from "@mui/material";
import Navigation from "#containers/layout/Navigation";
import PrivateChannels from "#components/pages/Feed/ChannelsSidebar/DesktopChannels/PrivateChannels";
import { toRem } from "#shared/utils";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import MyChannels from "#components/pages/Feed/ChannelsSidebar/DesktopChannels/MyChannels";
import { useLocation } from "react-router";
import { useIsMobileApp } from "#utils/useIsMobileApp";
import { ButtonIDs } from "#constants/buttonIDs";

export default function MobileMainMenu() {
  const { pathname } = useLocation();
  const { isDesktop } = useMediaQueryProvider();
  const { isMobileApp } = useIsMobileApp();

  const [open, setOpen] = useState(false);

  const closeDrawer = () => setOpen(false);
  const openDrawer = () => setOpen(true);

  useEffect(() => {
    closeDrawer();
  }, [pathname]);

  useEffect(() => {
    if (isDesktop) {
      closeDrawer();
    }
  }, [isDesktop]);

  return (
    <>
      {!isDesktop && (
        <IconButton onClick={openDrawer} id={ButtonIDs.header.closeButton}>
          {isMobileApp ? <CloseIcon style={{ fill: "#000" }} /> : <MenuIcon />}
        </IconButton>
      )}

      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <Wrapper>
          <Navigation onNavigationItemClick={closeDrawer} />
          <Divider sx={{ marginBlock: "1.25rem" }} />
          <Box display="flex" flexDirection="column" gap={4}>
            <Box paddingInline={3}>
              <MyChannels onNavigationItemClick={closeDrawer} />
            </Box>
            <Box paddingInline={3}>
              <PrivateChannels onNavigationItemClick={closeDrawer} />
            </Box>
          </Box>
        </Wrapper>
      </Drawer>
    </>
  );
}

const Wrapper = styled("div")`
  padding: 1rem 1.25rem;
  width: min(${toRem(280)}, calc(70vw));
`;
