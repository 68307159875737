import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useMagicButtonSmartBrief() {
  const [searchParams] = useSearchParams();
  const { approvedBusinessChannels } = useMyOwnedChannels();

  const [isAIChatModalOpen, setIsAIChatModalOpen] = useState(false);
  const [initialPrompt, setInitialPrompt] = useState<string | undefined>(undefined);

  const smartBriefEnabled = useMemo(
    () => approvedBusinessChannels.length > 0,
    [approvedBusinessChannels]
  );

  useEffect(() => {
    if (
      smartBriefEnabled &&
      searchParams.has("action") &&
      searchParams.get("action") === "smart-brief"
    ) {
      setIsAIChatModalOpen(true);

      const promptParam = searchParams.get("prompt");
      if (promptParam !== null) {
        setInitialPrompt(decodeURIComponent(promptParam));
      }
    }
  }, [searchParams, smartBriefEnabled]);

  return {
    isAIChatModalOpen,
    setIsAIChatModalOpen,
    smartBriefEnabled,
    initialPrompt,
  };
}
