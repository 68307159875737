import { CircularProgress, styled } from "@mui/material";
import React from "react";

export default function AppLoader() {
  return (
    <Wrapper>
      <CircularProgress size={48} />
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
