import useNavigateToChannel from "#components/pages/Feed/CardFeed/useNavigateToChannel";
import FeedChannelList from "#components/pages/Feed/ChannelsSidebar/DesktopChannels/FeedChannelList";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import SeeAllChannels from "#components/pages/Feed/ChannelsSidebar/DesktopChannels/SeeAllChannels";
import { Box, Button, Typography } from "@mui/material";
import colors from "#shared/config/theme/colors";
import PlusIcon from "#assets/svgs/plus.svg?react";
import CreateChannelModal from "#components/pages/Feed/ChannelsSidebar/CreateChannel/CreateChannelModal";
import { toRem } from "#shared/utils";
import ConfirmDialog from "#shared/components/ui/Dialog/ConfirmDialog";
import useChannelPermissions from "#components/pages/ChannelDetails/useChannelPermissions";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectNavigation,
  setMyChannelsLimit,
} from "#features/navigation/navigationSlice";
import useTranslation from "#utils/useTranslation";
import { useGlobalAppProvider } from "#containers/Global/GlobalAppProvider";
import useWlaOrganisation from "#utils/useWlaOrganisation";

interface Props {
  onNavigationItemClick?: () => void;
}

export default function MyChannels({ onNavigationItemClick }: Props) {
  const { t, p } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isWla } = useWlaOrganisation();

  const { canCreateOrRequestChannel } = useChannelPermissions();
  const { ownedChannels: channels } = useGlobalAppProvider();

  const hasChannels = !!channels?.length;

  const [isCreateChannelModalOpen, setIsCreateChannelModalOpen] =
    useState<boolean>(false);
  const [isCreateOrganisationModalOpen, setIsCreateOrganisationModalOpen] =
    useState<boolean>(false);

  const { myChannelsLimit } = useSelector(selectNavigation);

  const setLimit = (val: number) => {
    dispatch(setMyChannelsLimit(val));
  };

  const navigateToChannel = useNavigateToChannel();

  const handleNavigateToChannel = (channelRef: string) => {
    onNavigationItemClick?.();
    navigateToChannel(channelRef);
  };

  const handleAddClick = () => {
    if (!canCreateOrRequestChannel) {
      setIsCreateOrganisationModalOpen(true);
      return;
    }

    setIsCreateChannelModalOpen(true);
  };

  const handleCloseCreateOrganisationModal = (success: boolean) => {
    if (success) {
      navigate("/business-lab");
    }

    setIsCreateOrganisationModalOpen(false);
  };

  const content = () => {
    if (!hasChannels) {
      return (
        <>
          <Typography variant="label3" color={colors.text.secondaryDark} sx={{ mb: 4 }}>
            <FormattedMessage id="pages.feed.filters.your_channels.empty" />
          </Typography>
          <Button
            size="small"
            variant="outlined"
            startIcon={<PlusIcon />}
            onClick={handleAddClick}
            sx={{ minWidth: 0, width: "fit-content", mb: 4, paddingBlock: toRem(4) }}
          >
            <FormattedMessage id="pages.feed.filters.buttons.create_channel" />
          </Button>
        </>
      );
    }

    return (
      <>
        <div key={`filters_feed_private`} className="private">
          <FeedChannelList
            channels={channels.slice(0, myChannelsLimit)}
            showOrganisationName
            onClickChannel={handleNavigateToChannel}
          />
        </div>
        <SeeAllChannels
          total={channels.length}
          visible={myChannelsLimit}
          setLimit={setLimit}
        />
      </>
    );
  };

  if (isWla && !channels.length) {
    return null;
  }

  return (
    <>
      <Box display="flex" gap={3} alignItems="center" mb={4}>
        <Typography
          variant="label3"
          fontFamily="Avenir-heavy"
          color={colors.text.secondaryDark}
        >
          <FormattedMessage id="pages.feed.filters.your_channels.title" />
        </Typography>
        {hasChannels && (
          <Button
            size="extraSmall"
            variant="outlined"
            startIcon={<PlusIcon />}
            sx={{
              px: 2,
              py: 1,
              height: 24,
              minWidth: 0,
              ".MuiButton-startIcon": { mr: 0.5 },
            }}
            onClick={handleAddClick}
          >
            <FormattedMessage id="pages.feed.filters.buttons.create" />
          </Button>
        )}
      </Box>

      {content()}

      <CreateChannelModal
        isOpen={isCreateChannelModalOpen}
        onClose={() => setIsCreateChannelModalOpen(false)}
      />

      <ConfirmDialog
        type="info"
        title={t("pages.feed.filters.modals.create_organisation.title")}
        content={p("pages.feed.filters.modals.create_organisation.description")}
        confirmButtonText={t("pages.feed.filters.modals.create_organisation.button")}
        isOpen={isCreateOrganisationModalOpen}
        onClose={handleCloseCreateOrganisationModal}
      />
    </>
  );
}
