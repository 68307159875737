import { toRem } from "../../utils";

const button = {
  width: {
    xs: toRem(80),
    s: toRem(112),
    m: toRem(156),
    l: toRem(192),
    fit: "100%",
  },
};

export default button;
