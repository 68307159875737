import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { Box, styled } from "@mui/material";

export default styled(Box)`
  padding: 1rem;
  border: 2px solid ${colors.base.outline1};
  background: ${colors.grey[50]};
  border-radius: ${rounding.default};
`;
