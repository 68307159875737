import { BriefBuilderRoutes, BriefBuilderStep } from "#customTypes/briefBuilder";
import { matchPath } from "react-router";
import IconPaintbrushPencil from "#assets/svgs/paintbrush-pencil.svg?react";
import IconPaintbrushPencilSolid from "#assets/svgs/paintbrush-pencil-solid.svg?react";
import IconCircleInfo from "#assets/svgs/circle-info.svg?react";
import IconCircleInfoSolid from "#assets/svgs/circle-info-solid.svg?react";
import IconUsers from "#assets/svgs/users.svg?react";
import IconUsersSolid from "#assets/svgs/users-solid.svg?react";
import IconSquarePollHorizontal from "#assets/svgs/square-poll-horizontal.svg?react";
import IconSquarePollHorizontalSolid from "#assets/svgs/square-poll-horizontal-solid.svg?react";
import IconRocketLaunch from "#assets/svgs/rocket-launch.svg?react";
import IconRocketLaunchSolid from "#assets/svgs/rocket-launch-solid.svg?react";
import { Step } from "#shared/types/steps";
import { TranslationKey } from "#customTypes/translation";

export const BRIEF_BUILDER_ROUTES: BriefBuilderRoutes = {
  [BriefBuilderStep.Brief]: "/brief-builder/:briefRef/brief",
  [BriefBuilderStep.BriefEditor]: "/brief-builder/:briefRef/brief/content",
  [BriefBuilderStep.General]: "/brief-builder/:briefRef/general",
  [BriefBuilderStep.Audience]: "/brief-builder/:briefRef/audience",
  [BriefBuilderStep.Survey]: "/brief-builder/:briefRef/survey",
  [BriefBuilderStep.Launch]: "/brief-builder/:briefRef/launch",
  [BriefBuilderStep.Share]: "/brief-builder/:briefRef/share",
};

export const BRIEF_BUILDER_STEPS: Step<BriefBuilderStep, TranslationKey>[] = [
  {
    id: BriefBuilderStep.Brief,
    title: "pages.brief_builder.steps.brief",
    Icon: IconPaintbrushPencil,
    IconCompleted: IconPaintbrushPencilSolid,
  },
  {
    id: BriefBuilderStep.BriefEditor,
    title: "pages.brief_builder.steps.brief",
    hidden: true,
  },
  {
    id: BriefBuilderStep.General,
    title: "pages.brief_builder.steps.general.title",
    Icon: IconCircleInfo,
    IconCompleted: IconCircleInfoSolid,
  },
  {
    id: BriefBuilderStep.Audience,
    title: "pages.brief_builder.steps.audience.title",
    Icon: IconUsers,
    IconCompleted: IconUsersSolid,
  },
  {
    id: BriefBuilderStep.Survey,
    title: "pages.brief_builder.steps.survey.title",
    Icon: IconSquarePollHorizontal,
    IconCompleted: IconSquarePollHorizontalSolid,
  },
  {
    id: BriefBuilderStep.Launch,
    title: "pages.brief_builder.steps.launch.short_title",
    Icon: IconRocketLaunch,
    IconCompleted: IconRocketLaunchSolid,
  },
  {
    id: BriefBuilderStep.Share,
    title: "pages.brief_builder.steps.share.title",
    hidden: true,
  },
];

export const isBriefBuilderStepActive = (
  step: BriefBuilderStep,
  currentStep: BriefBuilderStep = BriefBuilderStep.Brief
): boolean => {
  if (step === BriefBuilderStep.Brief && currentStep === BriefBuilderStep.BriefEditor) {
    return true;
  }

  return step === currentStep;
};

export const isBriefBuilderStepCompleted = (
  step: BriefBuilderStep,
  currentStep: BriefBuilderStep = BriefBuilderStep.Brief
): boolean => {
  if (step === BriefBuilderStep.Brief && currentStep === BriefBuilderStep.BriefEditor) {
    return false;
  }

  const stepIndex = BRIEF_BUILDER_STEPS.findIndex((s) => s.id === step);
  const currentStepIndex = BRIEF_BUILDER_STEPS.findIndex((s) => s.id === currentStep);

  return stepIndex < currentStepIndex;
};

export const isBriefBuilderStepAvailable = (
  step: BriefBuilderStep,
  steps: BriefBuilderStep[] = [],
  currentStep: BriefBuilderStep = BriefBuilderStep.Brief
): boolean => {
  if (!steps.includes(step)) {
    return false;
  }

  const stepIndex = BRIEF_BUILDER_STEPS.findIndex((s) => s.id === step);
  const currentStepIndex = BRIEF_BUILDER_STEPS.findIndex((s) => s.id === currentStep);

  return stepIndex <= currentStepIndex;
};

export const getCurrentStepFromPathname = (pathname: string): BriefBuilderStep => {
  const matchingStep = Object.keys(BRIEF_BUILDER_ROUTES).find((step) =>
    matchPath(BRIEF_BUILDER_ROUTES[step as BriefBuilderStep], pathname)
  );

  return matchingStep ? (matchingStep as BriefBuilderStep) : BriefBuilderStep.Brief;
};

export const getBriefBuilderUrl = ({
  step = BriefBuilderStep.Brief,
  briefRef,
}: {
  step?: BriefBuilderStep;
  briefRef: string;
}) => {
  const path = BRIEF_BUILDER_ROUTES[step];
  return path ? path.replace(":briefRef", briefRef) : `/brief-builder/${briefRef}`;
};
