import React from "react";
import { PrimitiveType, FormatXMLElementFn } from "intl-messageformat";
import { useIntl } from "react-intl";
import { ReactNode, useCallback } from "react";
import { TranslationKey } from "#customTypes/translation";

export default function useTranslation() {
  const intl = useIntl();

  /**
   * Translates the message with the given key
   * @param key - translation key
   * @param values - values to be replaced in the translation
   * @returns translated string
   */
  const t = useCallback(
    (
      key: TranslationKey,
      values?: Record<string, PrimitiveType | FormatXMLElementFn<string, ReactNode>>
    ) => {
      return intl.formatMessage(
        { id: key },
        values as Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
      );
    },
    [intl]
  );

  /**
   * Translates the paragraph with the given key
   * @param key - translation key
   * @param values - values to be replaced in the translation
   * @returns react element with translated paragraph
   */
  const p = useCallback(
    (
      key: TranslationKey,
      values?: Record<string, PrimitiveType | FormatXMLElementFn<string, ReactNode>>
    ) => {
      return intl.formatMessage(
        { id: key, defaultMessage: key },
        {
          b: (val) => <b>{val}</b>,
          br: () => <br />,
          ...(values as Record<
            string,
            PrimitiveType | FormatXMLElementFn<string, string>
          >),
        }
      );
    },
    [intl]
  );

  return { t, p };
}
