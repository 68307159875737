import React, { useEffect } from "react";
import { BriefLinkQRCodeWrapper } from "./styled";
import { Skeleton } from "@mui/material";
import { displayAppLinkBriefQRCode } from "./applink";

interface Props {
  link: string | null;
  qrWrapperId: string;
}

const BriefLinkQRCode = (props: Props) => {
  /* only render the QR code when the app is mounted and we have the link */
  useEffect(() => {
    if (props.link) {
      displayAppLinkBriefQRCode(props.qrWrapperId);
    }
  }, [props.link]);

  return (
    <BriefLinkQRCodeWrapper id={props.qrWrapperId}>
      <Skeleton animation="wave" className="skeleton" height="100%" />
    </BriefLinkQRCodeWrapper>
  );
};

export default BriefLinkQRCode;
