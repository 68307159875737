import { ComponentOverrides } from "../types";

const typographyOverrides = (): ComponentOverrides<"MuiTypography"> => ({
  styleOverrides: {
    root: {
      fontFamily: "'Avenir', sans-serif",
    },
  },
  defaultProps: {
    variantMapping: {
      body1: "p",
      body2: "p",
      body3: "p",
      body4: "p",
      label1: "p",
      label2: "p",
      label3: "p",
    },
  },
});

export default typographyOverrides;
