import { Theme } from "@mui/material";
import colors from "../../../theme/colors";
import { ComponentOverrides } from "../../types";

const tabOverrides = (theme: Theme): ComponentOverrides<"MuiTab"> => ({
  styleOverrides: {
    root: {
      color: "rgba(0, 0, 0)",
      fontFamily: "Avenir-heavy",
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      borderBottom: `1px solid ${colors.base.outline1}`,
      position: "relative",
      "&:Mui-selected": {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "0.188rem",
          backgroundColor: theme.palette.primary.main,
          borderRadius: "0.625rem 0.625rem 0 0",
        },
      },
    },
    textColorPrimary: {
      color: colors.text.secondaryDark,
      borderColor: "transparent",
    },
  },
});

export default tabOverrides;
