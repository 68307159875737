import ShareChannelModal from "#components/pages/ChannelCommunity/ShareChannel/components/modals/ShareChannelModal";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import IconShare from "#assets/svgs/share-nodes.svg?react";
import useChannelPermissions from "#components/pages/ChannelDetails/useChannelPermissions";
import { ButtonIDs } from "#constants/buttonIDs";

export default function ChannelShareButton() {
  const { canShareChannel } = useChannelPermissions();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const openModal = () => {
    setIsShareModalOpen(true);
  };

  const closeModal = () => {
    setIsShareModalOpen(false);
  };

  if (!canShareChannel) {
    return null;
  }

  return (
    <>
      <IconButton
        id={ButtonIDs.channel.share}
        color="primary"
        size="small"
        onClick={openModal}
      >
        <IconShare />
      </IconButton>
      <ShareChannelModal isOpen={isShareModalOpen} onClose={closeModal} />
    </>
  );
}
