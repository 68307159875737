import React from "react";
import Avatar from "#components/common/Avatar";
import SelectCard from "#shared/components/ui/SelectCard";
import { Box } from "@mui/material";
import { IMAGE_TYPES } from "#customTypes/types";
import getResourcesURL from "#utils/getResourcesURL";
import DefaultChannelImage from "#assets/svgs/default_channel.svg";
import colors from "#shared/config/theme/colors";
import useTranslation from "#utils/useTranslation";
import TextEllipsis from "#components/common/Text/TextEllipsis";

interface ChannelProps {
  id: number;
  name: string;
  organisationName?: string;
  logo: string;
}

interface Props {
  channel: ChannelProps;
  selected?: number;
  setSelected: (channelId: number) => void;
}

export default function SelectChannelCard({ channel, selected, setSelected }: Props) {
  const { t } = useTranslation();
  const logoSrc = getResourcesURL(channel.logo, IMAGE_TYPES.CHANNEL_LOGO);

  return (
    <SelectCard
      selected={selected === channel.id}
      onClick={() => setSelected(channel.id)}
    >
      <Box display="flex" alignItems="center" gap={[2, 4]}>
        <Box display="flex" flexDirection="row" pr={4} width="100%">
          <Avatar
            uri={logoSrc || DefaultChannelImage}
            diameter={2.5}
            className="circle"
          />
          <Box display="flex" flexDirection="column" ml={4} flex="1" minWidth="0">
            <TextEllipsis variant="body4" fontFamily="Avenir-heavy" mb={0.5}>
              {channel.name}
            </TextEllipsis>
            <TextEllipsis variant="label3" color={colors.text.secondaryDark}>
              {channel.organisationName
                ? channel.organisationName
                : t("pages.feed.filters.personal")}
            </TextEllipsis>
          </Box>
        </Box>
      </Box>
    </SelectCard>
  );
}
