import { ChannelsApi } from "#apis/channels/api";
import { ChannelsQueryKeys } from "#apis/channels/queryKeys";
import { QUERY_TAGS } from "#constants/query";
import {
  ChannelListItem,
  GetMyFollowedChannelsResponse,
  JoinLeaveChannelRequest,
} from "#customTypes/channel";
import { AppMutationOptions } from "#customTypes/reactQuery";
import { channelsApi } from "#features/channel/channelsAPI";
import { BriefQueryKeys } from "#utils/queryKeys";
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";
import { useStore } from "react-redux";
import { RootState } from "src/store";

export default function useJoinChannelMutation(
  options?: AppMutationOptions<JoinLeaveChannelRequest & { channel?: ChannelListItem }>
) {
  const queryClient = useQueryClient();
  const store = useStore<RootState>();

  return useMutation({
    mutationFn: (params) => ChannelsApi.joinChannel({ channelRef: params.channelRef }),
    ...options,
    onSuccess: (data, params, context) => {
      options?.onSuccess?.(data, params, context);

      store.dispatch(
        channelsApi.util.invalidateTags([
          { type: QUERY_TAGS.Channel, id: params.channelRef },
        ])
      );

      queryClient.removeQueries({ queryKey: BriefQueryKeys.details() });
      queryClient.invalidateQueries({
        queryKey: BriefQueryKeys.list(),
      });

      // If the channel is not provided, invalidate the followed channels query
      if (!params.channel) {
        queryClient.invalidateQueries({ queryKey: ChannelsQueryKeys.followed });
        return;
      }

      queryClient.setQueriesData<InfiniteData<GetMyFollowedChannelsResponse>>(
        {
          queryKey: ChannelsQueryKeys.followed,
        },
        (oldData) => {
          if (!oldData || !params.channel) {
            return oldData;
          }

          const newFirstPage = {
            ...oldData.pages[0],
            data: [params.channel, ...oldData.pages[0].data],
          };

          return {
            ...oldData,
            pages: [newFirstPage, ...oldData.pages.slice(1)],
          };
        }
      );
    },
  });
}
