import { Theme } from "@mui/material";
import colors from "../theme/colors";

export const getBaseInputStyles = (theme: Theme) => ({
  base: {
    border: `1px solid ${colors.input.border}`,
    transition: "border-color 0.2s ease-in-out",
  },
  focused: {
    borderColor: theme.palette.primary.main,
    borderWidth: "1px",
  },
  disabled: {
    borderColor: colors.base.outline1,
  },
});
