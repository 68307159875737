import { css, styled } from "@mui/material/styles";

export const WrapperDesktop = styled("div")<{
  businessCenter?: boolean;
  godView?: boolean;
}>(({ theme, businessCenter, godView }) => {
  const godViewHeight = godView ? "40px" : "0px";

  const businessCenterStyles = css`
    position: sticky;
    width: var(--side-navigation-width);
    top: 5rem;
    overflow: hidden auto;
    box-sizing: border-box;
    padding-bottom: 1rem;
    align-self: flex-start;
  `;

  const residentialStyles = css`
    display: flex;
    flex-direction: column;
    width: var(--side-navigation-width);
    position: fixed;
    top: calc(var(--side-navigation-top) + ${godViewHeight});
    overflow: hidden auto;
    box-sizing: border-box;
    height: calc(100% - calc(var(--side-navigation-top) + ${godViewHeight}));
    padding-top: 3.225rem;
    padding-bottom: 1rem;

    ${theme.breakpoints.down("lg")} {
      margin-left: 1.25rem;
    }
  `;

  return css`
    --side-navigation-top: 2.25rem;
    --side-navigation-width: 268px;

    ${businessCenter ? businessCenterStyles : residentialStyles}

    ${theme.breakpoints.down("lg")} {
      --side-navigation-width: 240px;
    }

    ${theme.breakpoints.down("md")} {
      display: none;
    }

    & hr.MuiDivider-root {
      margin-bottom: 0.9rem;
      border-width: 0.5px !important;
    }

    & > .private {
      width: 100%;
    }
  `;
});
