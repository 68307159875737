import colors from "#shared/config/theme/colors";
import { Box, styled } from "@mui/material";
import React from "react";

export default function AIChatMessageLoading() {
  return (
    <Wrapper>
      {Array.from({ length: 3 }).map((_, index) => (
        <Dot key={index} className="dot" />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  align-items: center;
  display: flex;
  gap: 0.25rem;
  position: relative;
  padding: 0.75rem 0.25rem 0.25rem 0.25rem;

  @keyframes wave {
    0% {
      transform: translateY(0px);
      opacity: 0;
    }
    50% {
      transform: translateY(-0.2rem);
      opacity: 0.8;
    }
    100% {
      transform: translateY(0px);
      opacity: 0;
    }
  }

  .dot:nth-of-type(1) {
    animation-delay: 0.3333s;
  }
  .dot:nth-of-type(2) {
    animation-delay: 0.6666s;
  }
  .dot:nth-of-type(3) {
    animation-delay: 0.9999s;
  }
`;

const Dot = styled(Box)`
  border-radius: 9999px;
  height: 0.5rem;
  width: 0.5rem;

  background: ${colors.grey.default};
  animation: wave 1.3s infinite;
`;
