import { Theme } from "@mui/material";
import colors from "../../theme/colors";
import { ComponentOverrides } from "../types";
import { getBaseInputStyles } from "../utils";

const selectOverrides = (theme: Theme): ComponentOverrides<"MuiSelect"> => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      borderRadius: "0.625rem",

      "&.MuiInputBase-adornedStart > svg:first-of-type": {
        marginRight: "0.5rem",
      },

      // Outlined border styles

      ".MuiOutlinedInput-notchedOutline": {
        ...getBaseInputStyles(theme).base,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        ...getBaseInputStyles(theme).focused,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        ...getBaseInputStyles(theme).focused,
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        ...getBaseInputStyles(theme).disabled,
      },
    },
    select: {
      "& > *": {
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    standard: {
      backgroundColor: colors.input.background,
    },
    outlined: {
      minHeight: "21px",
      backgroundColor: colors.input.background,
    },
  },
});

export default selectOverrides;
