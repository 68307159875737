import { styled } from "@mui/material/styles";

const Wrapper = styled("div")`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export default {
  Wrapper,
};
