import { useEffect } from "react";
import { useLocation } from "react-router";
import useJoinBrief from "#components/pages/BriefDetails/JoinBriefButton/useJoinBrief";

import { getQueryParams, useSelectorTyped } from "../../utils";
import { selectJoinBriefId } from "#features/joinBriefId/joinBriefIdSlice";

export default function useSetJoinBriefFromURL() {
  const joinBriefId = useSelectorTyped(selectJoinBriefId);

  const location = useLocation();
  const deepLinkValue = getQueryParams(location, "deep_link_value");
  const briefRef = deepLinkValue?.replace("brief/", "");
  const isDownloadPage = location.pathname.includes("download");
  const onJoinBrief = useJoinBrief();

  /* handle CSR for the /Download page: set state from the url */
  useEffect(() => {
    if (isDownloadPage && briefRef !== joinBriefId) {
      onJoinBrief(briefRef);
    }
  }, [isDownloadPage, briefRef, joinBriefId]);
}
