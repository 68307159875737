import React from "react";
import { nFormatter } from "../../../../../utils";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";

const Wrapper = styled("div")`
  font-size: 0.75rem;
`;

type MembersAndBriefsCountsProps = {
  briefsCount: number;
};

function MembersAndBriefsCounts({ briefsCount }: MembersAndBriefsCountsProps) {
  const prefixIntl = "pages.feed";

  const briefsLabelId =
    briefsCount > 0
      ? `${prefixIntl}.channel.active_brief${briefsCount === 1 ? "" : "s"}`
      : `${prefixIntl}.channel.no_active_brief`;

  return (
    <Wrapper>
      <>
        {briefsCount > 0 && <>{nFormatter(briefsCount)} </>}
        <FormattedMessage id={briefsLabelId} />
      </>
    </Wrapper>
  );
}

export default MembersAndBriefsCounts;
