/* eslint-disable @typescript-eslint/no-empty-function */
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import React, { ReactNode, createContext, useContext, useMemo, useState } from "react";

interface ContextProps {
  isAiLoading: boolean;
  aiError: string | null;
  isActive: boolean;
  isMobileView: boolean;
  setIsAiLoading: (value: boolean) => void;
  setAiError: (value: string | null) => void;
}

const EditorContext = createContext<ContextProps>({
  aiError: null,
  isAiLoading: false,
  isActive: false,
  isMobileView: false,
  setIsAiLoading: () => {},
  setAiError: () => {},
});

interface ProviderProps {
  isActive: boolean;
  children: ReactNode;
}

export function TiptapEditorProvider({ isActive, children }: ProviderProps) {
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [aiError, setAiError] = useState<string | null>(null);

  const { isTabletOrMobile } = useMediaQueryProvider();

  const providerValue = useMemo(
    () => ({
      isAiLoading,
      aiError,
      isActive,
      isMobileView: isTabletOrMobile,
      setIsAiLoading,
      setAiError,
    }),
    [isAiLoading, aiError, isTabletOrMobile, isActive, setIsAiLoading, setAiError]
  );

  return (
    <EditorContext.Provider value={providerValue}>{children}</EditorContext.Provider>
  );
}

export function useTiptapEditorProvider(): ContextProps {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error("useTiptapEditorProvider must be used within a TiptapEditorProvider");
  }
  return context;
}
