import rounding from "../../theme/rounding";
import { ComponentOverrides } from "../types";

const dialogOverrides = (): ComponentOverrides<"MuiDialog"> => ({
  styleOverrides: {
    paper: {
      borderRadius: rounding.default,
      border: "none",
      overflow: "hidden",
      width: "100%",
    },
  },
});

export default dialogOverrides;
