import React from "react";
import { Checkbox as MuiCheckbox, CheckboxProps } from "@mui/material";
import IconCheckOff from "../../../assets/svgs/check-off.svg?react";
import IconCheckOn from "../../../assets/svgs/check-on.svg?react";
import IconCheckMid from "../../../assets/svgs/check-mid.svg?react";

export default function Checkbox(props: CheckboxProps) {
  return (
    <MuiCheckbox
      {...props}
      icon={<IconCheckOff />}
      indeterminateIcon={<IconCheckMid />}
      checkedIcon={<IconCheckOn />}
    />
  );
}
