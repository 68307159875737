import { styled } from "@mui/material";
import { toRem } from "#shared/utils";

export const BusinessLabForm = styled("div")`
  .submitForm {
    margin-bottom: 1rem;
  }

  .MuiDivider-root {
    margin: ${toRem(28)} 0rem;
  }

  b {
    font-weight: 500;
  }
`;

export const FileDropzonePlaceholderWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
