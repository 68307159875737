import EditorButton from "#components/common/Editor/components/EditorButton";
import EditorMenuDropdown, {
  EditorListWrapper,
} from "#components/common/Editor/components/EditorMenuDropdown";
import {
  EditorMenuItems,
  EditorMenuOption,
  EditorMenuSubmenu,
} from "#components/common/Editor/types";
import { Typography } from "@mui/material";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import React from "react";

interface Props {
  items: EditorMenuItems;
  selectedItemId?: string;
  onClickMenuItem?: (id: string) => void;
}

export default function EditorMenuList({
  items,
  selectedItemId,
  onClickMenuItem,
}: Props) {
  const button = (option: EditorMenuOption | EditorMenuSubmenu) => {
    const { id, label, Icon } = option;

    const isAIColor = "color" in option && option.color === "gradient";

    return (
      <div key={id}>
        <EditorButton
          startIcon={Icon && <Icon />}
          color={
            "color" in option && option.color && !isAIColor ? option.color : "secondary"
          }
          selected={selectedItemId === id}
          isAIButton={isAIColor}
          onClick={() => {
            if (onClickMenuItem) {
              onClickMenuItem(id);
              return;
            }

            if ("onClick" in option) {
              option.onClick();
            }
          }}
          dropdown={option.type === "submenu"}
          sx={{
            width: "100%",
            borderRadius: rounding.md,
            justifyContent: "start",
            px: 3,
          }}
        >
          {label}
        </EditorButton>
      </div>
    );
  };

  return (
    <EditorListWrapper>
      {items.map((option) => {
        if (option.type === "category") {
          return (
            <Typography
              key={option.id}
              variant="label2"
              color={colors.text.tertiaryDark}
              px={2}
            >
              {option.label}
            </Typography>
          );
        }

        if (option.type === "submenu") {
          return (
            <EditorMenuDropdown
              key={option.id}
              items={option.items}
              placement="right-start"
              AnchorElement={button(option)}
              onItemClick={() => onClickMenuItem?.("submenu")}
            />
          );
        }

        return button(option);
      })}
    </EditorListWrapper>
  );
}
