import { Autocomplete, AutocompleteProps, FormControlProps } from "@mui/material";
import React from "react";
import { FormField, TextField } from "..";
import FormControl from "../FormControl";
import { SelectOption } from "../../../types/form";

export interface CustomAutocompleteProps
  extends Omit<AutocompleteProps<SelectOption, any, any, any>, "renderInput"> {
  label?: string;
  optional?: boolean;
  placeholder?: string;
  hasError?: boolean;
  formProps?: FormControlProps;
}

export default function CustomAutocomplete(props: CustomAutocompleteProps) {
  const { formProps, label, optional, ...rest } = props;

  if (label) {
    return (
      <FormField label={label} name={props.id} optional={optional}>
        <FormControl {...formProps}>
          <AutocompleteComponent {...rest} />
        </FormControl>
      </FormField>
    );
  }

  return (
    <FormControl {...formProps}>
      <AutocompleteComponent {...rest} />
    </FormControl>
  );
}

const AutocompleteComponent = ({
  hasError,
  placeholder,
  ...props
}: CustomAutocompleteProps) => (
  <Autocomplete
    {...props}
    renderInput={(params) => (
      <TextField
        {...params}
        inputProps={{
          ...params.inputProps,
          placeholder,
        }}
        error={hasError}
      />
    )}
  />
);
