import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from "react";

/**
 * Remove after merging PLG9
 * @deprecated
 */
export default function useVideo(play = false): {
  togglePlay: () => void;
  firstPlay: () => void;
  useIsPlaying: [boolean, Dispatch<SetStateAction<boolean>>];
  videoRef: MutableRefObject<HTMLVideoElement>;
} {
  const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;
  const [isPlaying, setIsPlaying] = useState<boolean>(play);

  const firstPlay = () => {
    if (isPlaying) {
      videoRef.current.play();
      if (!videoRef.current.paused) {
        setIsPlaying(true);
      }
    }
  };

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      videoRef.current.muted = false;
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return {
    togglePlay,
    firstPlay,
    useIsPlaying: [isPlaying, setIsPlaying],
    videoRef,
  };
}
