interface CountryEmoji {
  name: string;
  code: string;
  emoji: string;
}

export const COUNTRY_EMOJIS: Record<string, CountryEmoji | undefined> = {
  af: {
    name: "Afghanistan",
    code: "af",
    emoji: "🇦🇫",
  },
  al: {
    name: "Albania",
    code: "al",
    emoji: "🇦🇱",
  },
  dz: {
    name: "Algeria",
    code: "dz",
    emoji: "🇩🇿",
  },
  as: {
    name: "American Samoa",
    code: "as",
    emoji: "🇦🇸",
  },
  ad: {
    name: "Andorra",
    code: "ad",
    emoji: "🇦🇩",
  },
  ao: {
    name: "Angola",
    code: "ao",
    emoji: "🇦🇴",
  },
  ai: {
    name: "Anguilla",
    code: "ai",
    emoji: "🇦🇮",
  },
  ag: {
    name: "Antigua and Barbuda",
    code: "ag",
    emoji: "🇦🇬",
  },
  ar: {
    name: "Argentina",
    code: "ar",
    emoji: "🇦🇷",
  },
  am: {
    name: "Armenia",
    code: "am",
    emoji: "🇦🇲",
  },
  aw: {
    name: "Aruba",
    code: "aw",
    emoji: "🇦🇼",
  },
  au: {
    name: "Australia",
    code: "au",
    emoji: "🇦🇺",
  },
  at: {
    name: "Austria",
    code: "at",
    emoji: "🇦🇹",
  },
  az: {
    name: "Azerbaijan",
    code: "az",
    emoji: "🇦🇿",
  },
  bs: {
    name: "Bahamas",
    code: "bs",
    emoji: "🇧🇸",
  },
  bh: {
    name: "Bahrain",
    code: "bh",
    emoji: "🇧🇭",
  },
  bd: {
    name: "Bangladesh",
    code: "bd",
    emoji: "🇧🇩",
  },
  bb: {
    name: "Barbados",
    code: "bb",
    emoji: "🇧🇧",
  },
  by: {
    name: "Belarus",
    code: "by",
    emoji: "🇧🇾",
  },
  be: {
    name: "Belgium",
    code: "be",
    emoji: "🇧🇪",
  },
  bz: {
    name: "Belize",
    code: "bz",
    emoji: "🇧🇿",
  },
  bj: {
    name: "Benin",
    code: "bj",
    emoji: "🇧🇯",
  },
  bm: {
    name: "Bermuda",
    code: "bm",
    emoji: "🇧🇲",
  },
  bt: {
    name: "Bhutan",
    code: "bt",
    emoji: "🇧🇹",
  },
  bo: {
    name: "Bolivia",
    code: "bo",
    emoji: "🇧🇴",
  },
  ba: {
    name: "Bosnia and Herzegovina",
    code: "ba",
    emoji: "🇧🇦",
  },
  bw: {
    name: "Botswana",
    code: "bw",
    emoji: "🇧🇼",
  },
  br: {
    name: "Brazil",
    code: "br",
    emoji: "🇧🇷",
  },
  io: {
    name: "British Indian Ocean Territory",
    code: "io",
    emoji: "🇮🇴",
  },
  vg: {
    name: "British Virgin Islands",
    code: "vg",
    emoji: "🇻🇬",
  },
  bn: {
    name: "Brunei",
    code: "bn",
    emoji: "🇧🇳",
  },
  bg: {
    name: "Bulgaria",
    code: "bg",
    emoji: "🇧🇬",
  },
  bf: {
    name: "Burkina Faso",
    code: "bf",
    emoji: "🇧🇫",
  },
  bi: {
    name: "Burundi",
    code: "bi",
    emoji: "🇧🇮",
  },
  cv: {
    name: "Cabo Verde",
    code: "cv",
    emoji: "🇨🇻",
  },
  kh: {
    name: "Cambodia",
    code: "kh",
    emoji: "🇰🇭",
  },
  cm: {
    name: "Cameroon",
    code: "cm",
    emoji: "🇨🇲",
  },
  ca: {
    name: "Canada",
    code: "ca",
    emoji: "🇨🇦",
  },
  ky: {
    name: "Cayman Islands",
    code: "ky",
    emoji: "🇰🇾",
  },
  cf: {
    name: "Central African Republic",
    code: "cf",
    emoji: "🇨🇫",
  },
  td: {
    name: "Chad",
    code: "td",
    emoji: "🇹🇩",
  },
  cl: {
    name: "Chile",
    code: "cl",
    emoji: "🇨🇱",
  },
  cn: {
    name: "China",
    code: "cn",
    emoji: "🇨🇳",
  },
  co: {
    name: "Colombia",
    code: "co",
    emoji: "🇨🇴",
  },
  km: {
    name: "Comoros",
    code: "km",
    emoji: "🇰🇲",
  },
  cg: {
    name: "Congo - Brazzaville",
    code: "cg",
    emoji: "🇨🇬",
  },
  cd: {
    name: "Congo - Kinshasa",
    code: "cd",
    emoji: "🇨🇩",
  },
  ck: {
    name: "Cook Islands",
    code: "ck",
    emoji: "🇨🇰",
  },
  cr: {
    name: "Costa Rica",
    code: "cr",
    emoji: "🇨🇷",
  },
  ci: {
    name: "Côte d’Ivoire",
    code: "ci",
    emoji: "🇨🇮",
  },
  hr: {
    name: "Croatia",
    code: "hr",
    emoji: "🇭🇷",
  },
  cu: {
    name: "Cuba",
    code: "cu",
    emoji: "🇨🇺",
  },
  cw: {
    name: "Curaçao",
    code: "cw",
    emoji: "🇨🇼",
  },
  cy: {
    name: "Cyprus",
    code: "cy",
    emoji: "🇨🇾",
  },
  cz: {
    name: "Czechia",
    code: "cz",
    emoji: "🇨🇿",
  },
  dk: {
    name: "Denmark",
    code: "dk",
    emoji: "🇩🇰",
  },
  dj: {
    name: "Djibouti",
    code: "dj",
    emoji: "🇩🇯",
  },
  dm: {
    name: "Dominica",
    code: "dm",
    emoji: "🇩🇲",
  },
  do: {
    name: "Dominican Republic",
    code: "do",
    emoji: "🇩🇴",
  },
  ec: {
    name: "Ecuador",
    code: "ec",
    emoji: "🇪🇨",
  },
  eg: {
    name: "Egypt",
    code: "eg",
    emoji: "🇪🇬",
  },
  sv: {
    name: "El Salvador",
    code: "sv",
    emoji: "🇸🇻",
  },
  gq: {
    name: "Equatorial Guinea",
    code: "gq",
    emoji: "🇬🇶",
  },
  er: {
    name: "Eritrea",
    code: "er",
    emoji: "🇪🇷",
  },
  ee: {
    name: "Estonia",
    code: "ee",
    emoji: "🇪🇪",
  },
  et: {
    name: "Ethiopia",
    code: "et",
    emoji: "🇪🇹",
  },
  fj: {
    name: "Fiji",
    code: "fj",
    emoji: "🇫🇯",
  },
  fi: {
    name: "Finland",
    code: "fi",
    emoji: "🇫🇮",
  },
  fr: {
    name: "France",
    code: "fr",
    emoji: "🇫🇷",
  },
  gf: {
    name: "French Guiana",
    code: "gf",
    emoji: "🇬🇫",
  },
  pf: {
    name: "French Polynesia",
    code: "pf",
    emoji: "🇵🇫",
  },
  ga: {
    name: "Gabon",
    code: "ga",
    emoji: "🇬🇦",
  },
  gm: {
    name: "Gambia",
    code: "gm",
    emoji: "🇬🇲",
  },
  ge: {
    name: "Georgia",
    code: "ge",
    emoji: "🇬🇪",
  },
  de: {
    name: "Germany",
    code: "de",
    emoji: "🇩🇪",
  },
  gh: {
    name: "Ghana",
    code: "gh",
    emoji: "🇬🇭",
  },
  gi: {
    name: "Gibraltar",
    code: "gi",
    emoji: "🇬🇮",
  },
  gr: {
    name: "Greece",
    code: "gr",
    emoji: "🇬🇷",
  },
  gl: {
    name: "Greenland",
    code: "gl",
    emoji: "🇬🇱",
  },
  gd: {
    name: "Grenada",
    code: "gd",
    emoji: "🇬🇩",
  },
  gp: {
    name: "Guadeloupe",
    code: "gp",
    emoji: "🇬🇵",
  },
  gu: {
    name: "Guam",
    code: "gu",
    emoji: "🇬🇺",
  },
  gt: {
    name: "Guatemala",
    code: "gt",
    emoji: "🇬🇹",
  },
  gn: {
    name: "Guinea",
    code: "gn",
    emoji: "🇬🇳",
  },
  gw: {
    name: "Guinea-Bissau",
    code: "gw",
    emoji: "🇬🇼",
  },
  gy: {
    name: "Guyana",
    code: "gy",
    emoji: "🇬🇾",
  },
  ht: {
    name: "Haiti",
    code: "ht",
    emoji: "🇭🇹",
  },
  hn: {
    name: "Honduras",
    code: "hn",
    emoji: "🇭🇳",
  },
  hk: {
    name: "Hong Kong SAR",
    code: "hk",
    emoji: "🇭🇰",
  },
  hu: {
    name: "Hungary",
    code: "hu",
    emoji: "🇭🇺",
  },
  is: {
    name: "Iceland",
    code: "is",
    emoji: "🇮🇸",
  },
  in: {
    name: "India",
    code: "in",
    emoji: "🇮🇳",
  },
  id: {
    name: "Indonesia",
    code: "id",
    emoji: "🇮🇩",
  },
  ir: {
    name: "Iran",
    code: "ir",
    emoji: "🇮🇷",
  },
  iq: {
    name: "Iraq",
    code: "iq",
    emoji: "🇮🇶",
  },
  ie: {
    name: "Ireland",
    code: "ie",
    emoji: "🇮🇪",
  },
  il: {
    name: "Israel",
    code: "il",
    emoji: "🇮🇱",
  },
  it: {
    name: "Italy",
    code: "it",
    emoji: "🇮🇹",
  },
  jm: {
    name: "Jamaica",
    code: "jm",
    emoji: "🇯🇲",
  },
  jp: {
    name: "Japan",
    code: "jp",
    emoji: "🇯🇵",
  },
  jo: {
    name: "Jordan",
    code: "jo",
    emoji: "🇯🇴",
  },
  kz: {
    name: "Kazakhstan",
    code: "kz",
    emoji: "🇰🇿",
  },
  ke: {
    name: "Kenya",
    code: "ke",
    emoji: "🇰🇪",
  },
  ki: {
    name: "Kiribati",
    code: "ki",
    emoji: "🇰🇮",
  },
  kw: {
    name: "Kuwait",
    code: "kw",
    emoji: "🇰🇼",
  },
  kg: {
    name: "Kyrgyzstan",
    code: "kg",
    emoji: "🇰🇬",
  },
  la: {
    name: "Laos",
    code: "la",
    emoji: "🇱🇦",
  },
  lv: {
    name: "Latvia",
    code: "lv",
    emoji: "🇱🇻",
  },
  lb: {
    name: "Lebanon",
    code: "lb",
    emoji: "🇱🇧",
  },
  ls: {
    name: "Lesotho",
    code: "ls",
    emoji: "🇱🇸",
  },
  lr: {
    name: "Liberia",
    code: "lr",
    emoji: "🇱🇷",
  },
  ly: {
    name: "Libya",
    code: "ly",
    emoji: "🇱🇾",
  },
  li: {
    name: "Liechtenstein",
    code: "li",
    emoji: "🇱🇮",
  },
  lt: {
    name: "Lithuania",
    code: "lt",
    emoji: "🇱🇹",
  },
  lu: {
    name: "Luxembourg",
    code: "lu",
    emoji: "🇱🇺",
  },
  mo: {
    name: "Macao SAR",
    code: "mo",
    emoji: "🇲🇴",
  },
  mg: {
    name: "Madagascar",
    code: "mg",
    emoji: "🇲🇬",
  },
  mw: {
    name: "Malawi",
    code: "mw",
    emoji: "🇲🇼",
  },
  my: {
    name: "Malaysia",
    code: "my",
    emoji: "🇲🇾",
  },
  mv: {
    name: "Maldives",
    code: "mv",
    emoji: "🇲🇻",
  },
  ml: {
    name: "Mali",
    code: "ml",
    emoji: "🇲🇱",
  },
  mt: {
    name: "Malta",
    code: "mt",
    emoji: "🇲🇹",
  },
  mh: {
    name: "Marshall Islands",
    code: "mh",
    emoji: "🇲🇭",
  },
  mq: {
    name: "Martinique",
    code: "mq",
    emoji: "🇲🇶",
  },
  mr: {
    name: "Mauritania",
    code: "mr",
    emoji: "🇲🇷",
  },
  mu: {
    name: "Mauritius",
    code: "mu",
    emoji: "🇲🇺",
  },
  yt: {
    name: "Mayotte",
    code: "yt",
    emoji: "🇾🇹",
  },
  mx: {
    name: "Mexico",
    code: "mx",
    emoji: "🇲🇽",
  },
  fm: {
    name: "Micronesia",
    code: "fm",
    emoji: "🇫🇲",
  },
  md: {
    name: "Moldova",
    code: "md",
    emoji: "🇲🇩",
  },
  mc: {
    name: "Monaco",
    code: "mc",
    emoji: "🇲🇨",
  },
  mn: {
    name: "Mongolia",
    code: "mn",
    emoji: "🇲🇳",
  },
  me: {
    name: "Montenegro",
    code: "me",
    emoji: "🇲🇪",
  },
  ms: {
    name: "Montserrat",
    code: "ms",
    emoji: "🇲🇸",
  },
  ma: {
    name: "Morocco",
    code: "ma",
    emoji: "🇲🇦",
  },
  mz: {
    name: "Mozambique",
    code: "mz",
    emoji: "🇲🇿",
  },
  mm: {
    name: "Myanmar",
    code: "mm",
    emoji: "🇲🇲",
  },
  na: {
    name: "Namibia",
    code: "na",
    emoji: "🇳🇦",
  },
  nr: {
    name: "Nauru",
    code: "nr",
    emoji: "🇳🇷",
  },
  np: {
    name: "Nepal",
    code: "np",
    emoji: "🇳🇵",
  },
  nl: {
    name: "Netherlands",
    code: "nl",
    emoji: "🇳🇱",
  },
  nc: {
    name: "New Caledonia",
    code: "nc",
    emoji: "🇳🇨",
  },
  nz: {
    name: "New Zealand",
    code: "nz",
    emoji: "🇳🇿",
  },
  ni: {
    name: "Nicaragua",
    code: "ni",
    emoji: "🇳🇮",
  },
  ne: {
    name: "Niger",
    code: "ne",
    emoji: "🇳🇪",
  },
  ng: {
    name: "Nigeria",
    code: "ng",
    emoji: "🇳🇬",
  },
  nu: {
    name: "Niue",
    code: "nu",
    emoji: "🇳🇺",
  },
  nf: {
    name: "Norfolk Island",
    code: "nf",
    emoji: "🇳🇫",
  },
  kp: {
    name: "North Korea",
    code: "kp",
    emoji: "🇰🇵",
  },
  mk: {
    name: "North Macedonia",
    code: "mk",
    emoji: "🇲🇰",
  },
  mp: {
    name: "Northern Mariana Islands",
    code: "mp",
    emoji: "🇲🇵",
  },
  no: {
    name: "Norway",
    code: "no",
    emoji: "🇳🇴",
  },
  om: {
    name: "Oman",
    code: "om",
    emoji: "🇴🇲",
  },
  pk: {
    name: "Pakistan",
    code: "pk",
    emoji: "🇵🇰",
  },
  pw: {
    name: "Palau",
    code: "pw",
    emoji: "🇵🇼",
  },
  ps: {
    name: "Palestinian Territories",
    code: "ps",
    emoji: "🇵🇸",
  },
  pa: {
    name: "Panama",
    code: "pa",
    emoji: "🇵🇦",
  },
  pg: {
    name: "Papua New Guinea",
    code: "pg",
    emoji: "🇵🇬",
  },
  py: {
    name: "Paraguay",
    code: "py",
    emoji: "🇵🇾",
  },
  pe: {
    name: "Peru",
    code: "pe",
    emoji: "🇵🇪",
  },
  ph: {
    name: "Philippines",
    code: "ph",
    emoji: "🇵🇭",
  },
  pn: {
    name: "Pitcairn Islands",
    code: "pn",
    emoji: "🇵🇳",
  },
  pl: {
    name: "Poland",
    code: "pl",
    emoji: "🇵🇱",
  },
  pt: {
    name: "Portugal",
    code: "pt",
    emoji: "🇵🇹",
  },
  pr: {
    name: "Puerto Rico",
    code: "pr",
    emoji: "🇵🇷",
  },
  qa: {
    name: "Qatar",
    code: "qa",
    emoji: "🇶🇦",
  },
  re: {
    name: "Réunion",
    code: "re",
    emoji: "🇷🇪",
  },
  ro: {
    name: "Romania",
    code: "ro",
    emoji: "🇷🇴",
  },
  ru: {
    name: "Russia",
    code: "ru",
    emoji: "🇷🇺",
  },
  rw: {
    name: "Rwanda",
    code: "rw",
    emoji: "🇷🇼",
  },
  bl: {
    name: "Saint Barthélemy",
    code: "bl",
    emoji: "🇧🇱",
  },
  sh: {
    name: "Saint Helena",
    code: "sh",
    emoji: "🇸🇭",
  },
  kn: {
    name: "Saint Kitts and Nevis",
    code: "kn",
    emoji: "🇰🇳",
  },
  lc: {
    name: "Saint Lucia",
    code: "lc",
    emoji: "🇱🇨",
  },
  mf: {
    name: "Saint Martin",
    code: "mf",
    emoji: "🇲🇫",
  },
  pm: {
    name: "Saint Pierre and Miquelon",
    code: "pm",
    emoji: "🇵🇲",
  },
  ws: {
    name: "Samoa",
    code: "ws",
    emoji: "🇼🇸",
  },
  sm: {
    name: "San Marino",
    code: "sm",
    emoji: "🇸🇲",
  },
  st: {
    name: "São Tomé and Príncipe",
    code: "st",
    emoji: "🇸🇹",
  },
  sa: {
    name: "Saudi Arabia",
    code: "sa",
    emoji: "🇸🇦",
  },
  sn: {
    name: "Senegal",
    code: "sn",
    emoji: "🇸🇳",
  },
  rs: {
    name: "Serbia",
    code: "rs",
    emoji: "🇷🇸",
  },
  sc: {
    name: "Seychelles",
    code: "sc",
    emoji: "🇸🇨",
  },
  sl: {
    name: "Sierra Leone",
    code: "sl",
    emoji: "🇸🇱",
  },
  sg: {
    name: "Singapore",
    code: "sg",
    emoji: "🇸🇬",
  },
  sx: {
    name: "Sint Maarten",
    code: "sx",
    emoji: "🇸🇽",
  },
  sk: {
    name: "Slovakia",
    code: "sk",
    emoji: "🇸🇰",
  },
  si: {
    name: "Slovenia",
    code: "si",
    emoji: "🇸🇮",
  },
  sb: {
    name: "Solomon Islands",
    code: "sb",
    emoji: "🇸🇧",
  },
  so: {
    name: "Somalia",
    code: "so",
    emoji: "🇸🇴",
  },
  za: {
    name: "South Africa",
    code: "za",
    emoji: "🇿🇦",
  },
  kr: {
    name: "South Korea",
    code: "kr",
    emoji: "🇰🇷",
  },
  ss: {
    name: "South Sudan",
    code: "ss",
    emoji: "🇸🇸",
  },
  es: {
    name: "Spain",
    code: "es",
    emoji: "🇪🇸",
  },
  lk: {
    name: "Sri Lanka",
    code: "lk",
    emoji: "🇱🇰",
  },
  sd: {
    name: "Sudan",
    code: "sd",
    emoji: "🇸🇩",
  },
  sr: {
    name: "Suriname",
    code: "sr",
    emoji: "🇸🇷",
  },
  sj: {
    name: "Svalbard and Jan Mayen",
    code: "sj",
    emoji: "🇸🇯",
  },
  sz: {
    name: "Swaziland",
    code: "sz",
    emoji: "🇸🇿",
  },
  se: {
    name: "Sweden",
    code: "se",
    emoji: "🇸🇪",
  },
  ch: {
    name: "Switzerland",
    code: "ch",
    emoji: "🇨🇭",
  },
  sy: {
    name: "Syria",
    code: "sy",
    emoji: "🇸🇾",
  },
  tw: {
    name: "Taiwan",
    code: "tw",
    emoji: "🇹🇼",
  },
  tj: {
    name: "Tajikistan",
    code: "tj",
    emoji: "🇹🇯",
  },
  tz: {
    name: "Tanzania",
    code: "tz",
    emoji: "🇹🇿",
  },
  th: {
    name: "Thailand",
    code: "th",
    emoji: "🇹🇭",
  },
  tl: {
    name: "Timor-Leste",
    code: "tl",
    emoji: "🇹🇱",
  },
  tg: {
    name: "Togo",
    code: "tg",
    emoji: "🇹🇬",
  },
  tk: {
    name: "Tokelau",
    code: "tk",
    emoji: "🇹🇰",
  },
  to: {
    name: "Tonga",
    code: "to",
    emoji: "🇹🇴",
  },
  tt: {
    name: "Trinidad and Tobago",
    code: "tt",
    emoji: "🇹🇹",
  },
  tn: {
    name: "Tunisia",
    code: "tn",
    emoji: "🇹🇳",
  },
  tr: {
    name: "Turkey",
    code: "tr",
    emoji: "🇹🇷",
  },
  tm: {
    name: "Turkmenistan",
    code: "tm",
    emoji: "🇹🇲",
  },
  tc: {
    name: "Turks and Caicos Islands",
    code: "tc",
    emoji: "🇹🇨",
  },
  tv: {
    name: "Tuvalu",
    code: "tv",
    emoji: "🇹🇻",
  },
  ug: {
    name: "Uganda",
    code: "ug",
    emoji: "🇺🇬",
  },
  ua: {
    name: "Ukraine",
    code: "ua",
    emoji: "🇺🇦",
  },
  ae: {
    name: "United Arab Emirates",
    code: "ae",
    emoji: "🇦🇪",
  },
  gb: {
    name: "United Kingdom",
    code: "gb",
    emoji: "🇬🇧",
  },
  us: {
    name: "United States",
    code: "us",
    emoji: "🇺🇸",
  },
  uy: {
    name: "Uruguay",
    code: "uy",
    emoji: "🇺🇾",
  },
  uz: {
    name: "Uzbekistan",
    code: "uz",
    emoji: "🇺🇿",
  },
  vu: {
    name: "Vanuatu",
    code: "vu",
    emoji: "🇻🇺",
  },
  va: {
    name: "Vatican City",
    code: "va",
    emoji: "🇻🇦",
  },
  ve: {
    name: "Venezuela",
    code: "ve",
    emoji: "🇻🇪",
  },
  vn: {
    name: "Vietnam",
    code: "vn",
    emoji: "🇻🇳",
  },
  wf: {
    name: "Wallis and Futuna",
    code: "wf",
    emoji: "🇼🇫",
  },
  eh: {
    name: "Western Sahara",
    code: "eh",
    emoji: "🇪🇭",
  },
  ye: {
    name: "Yemen",
    code: "ye",
    emoji: "🇾🇪",
  },
  zm: {
    name: "Zambia",
    code: "zm",
    emoji: "🇿🇲",
  },
  zw: {
    name: "Zimbabwe",
    code: "zw",
    emoji: "🇿🇼",
  },
};
