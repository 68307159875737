import { Box, Slider, SliderProps, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { FormField } from "../../../components/ui/Form";
import FormControl from "../../../components/ui/FormControl";
import colors from "../../../config/theme/colors";

interface Props<T> extends Omit<SliderProps, "onChange" | "defaultValue"> {
  label?: string;
  optional?: boolean;
  error?: boolean;
  errorMessage?: string;
  onChange: (value: T) => void;
  defaultValue?: T;
}

export default function CustomSlider<T extends number | [number, number]>({
  label,
  optional,
  ...props
}: Props<T>) {
  if (label) {
    return (
      <FormField label={label} optional={optional}>
        <FormControl error={!!props.errorMessage}>
          <SliderElement {...props} />
        </FormControl>
      </FormField>
    );
  }

  return (
    <FormControl error={!!props.errorMessage}>
      <SliderElement {...props} />
    </FormControl>
  );
}

const SliderElement = <T extends number | [number, number]>({
  label,
  ...props
}: Props<T>) => {
  const [value, setValue] = useState<T>(props.defaultValue ?? (0 as T));
  const isArray = Array.isArray(value);

  const handleChange = (_event: Event, value: number | number[]) => {
    setValue(value as T);
  };

  return (
    <Wrapper>
      {isArray && (
        <Typography variant="label2" color={colors.text.secondaryDark}>
          {value[0]}
        </Typography>
      )}
      <Slider
        {...props}
        getAriaLabel={() => label ?? "Select value"}
        valueLabelDisplay="auto"
        onChangeCommitted={(_event, value) => props.onChange(value as T)}
        onChange={handleChange}
        defaultValue={props.defaultValue}
      />
      <Typography variant="label2" color={colors.text.secondaryDark}>
        {isArray ? value[1] : value}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;
