import React, { useState } from "react";
import { Box, styled } from "@mui/material";
import MagicButton from "#containers/layout/MagicButton/MagicButton";
import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import SelectChannelModal from "#containers/BriefBuilder/modals/SelectChannelModal";
import useSelectedChannel from "#components/pages/ChannelDetails/useSelectedChannel";
import useChannelPermissions from "#components/pages/ChannelDetails/useChannelPermissions";
import useBuilderCreateBrief from "#containers/BriefBuilder/hooks/useCreateBrief";
import AIChatModal from "#components/common/AIChat/modals/AIChatModal";
import { AIChatProvider } from "#components/common/AIChat/context/AIChatProvider";
import useMagicButtonSmartBrief from "#containers/layout/MagicButton/useMagicButtonSmartBrief";
import useMagicButtonVisibility from "#containers/layout/MagicButton/useMagicButtonVisibility";

export default function MagicButtonWrapper() {
  const { data: selectedChannel, channelRef } = useSelectedChannel();
  const { canEditChannel } = useChannelPermissions(channelRef);
  const { approvedChannels } = useMyOwnedChannels();

  const [isSelectChannelModalOpen, setIsSelectChannelModalOpen] = useState(false);

  const { visible } = useMagicButtonVisibility();
  const { isAIChatModalOpen, setIsAIChatModalOpen, smartBriefEnabled, initialPrompt } =
    useMagicButtonSmartBrief();

  const { createBrief, isLoadingCreateBrief } = useBuilderCreateBrief();

  const handleCreateEmptyBrief = async () => {
    // If the user is on a channel page, create a brief for that channel
    if (channelRef && selectedChannel && canEditChannel) {
      createBrief(selectedChannel.organisationId, selectedChannel.channelRef);
      return;
    }

    // If the user has more than one approved channel, open select channel modal
    if (approvedChannels.length > 1) {
      setIsSelectChannelModalOpen(true);
      return;
    }

    const channel = approvedChannels[0];
    createBrief(channel.organisationId, channel.channelRef);
  };

  const handleCreateSmartBrief = () => {
    setIsAIChatModalOpen(true);
  };

  if (!visible) {
    return null;
  }

  return (
    <Wrapper>
      <MagicButton
        loading={isLoadingCreateBrief}
        onClickEmptyBrief={handleCreateEmptyBrief}
        onClickSmartBrief={smartBriefEnabled ? handleCreateSmartBrief : undefined}
      />
      <AIChatProvider>
        <AIChatModal
          isOpen={isAIChatModalOpen}
          initialPrompt={initialPrompt}
          onClose={() => setIsAIChatModalOpen(false)}
        />
      </AIChatProvider>
      <SelectChannelModal
        isOpen={isSelectChannelModalOpen}
        onClose={() => setIsSelectChannelModalOpen(false)}
        onSelectChannel={(channel) => {
          setIsSelectChannelModalOpen(false);
          createBrief(channel.organisationId, channel.channelRef);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down("md")} {
    bottom: 1rem;
    right: 1rem;
  }
`;
