import { ComponentOverrides } from "../../types";
import colors from "../../../theme/colors";
import { Theme } from "@mui/material";

const tableCellOverrides = (theme: Theme): ComponentOverrides<"MuiTableCell"> => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      borderBottom: `1px solid ${colors.grey[300]}`,
      padding: "0.5rem 1rem",
    },
  },
});

export default tableCellOverrides;
