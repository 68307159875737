import React from "react";
import {
  Autocomplete,
  AutocompleteProps,
  FormHelperText,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControl from "../FormControl";
import InputLabel from "../InputLabel";

import { SelectOption } from "../../../types";

const StyledInputLabel = styled(InputLabel)`
  transform: translate(14px, -9px) scale(0.75);
`;

const StyledAutocomplete = styled(Autocomplete)`
  & fieldset {
    border: none;
  }
` as typeof Autocomplete;

type AutocompleteInputProps<T> = {
  error?: boolean;
  errorMessage?: string | undefined;
  label?: string;
  placeholder?: string;
} & Omit<AutocompleteProps<T, any, any, any>, "renderInput">;

export default function CustomAutoComplete({
  error,
  errorMessage,
  label,
  placeholder,
  ...props
}: AutocompleteInputProps<SelectOption>) {
  const { options, value } = props;

  const hasError = !!(error && errorMessage);

  return (
    <FormControl>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledAutocomplete
        {...props}
        getOptionLabel={(option) => {
          if (typeof option !== "string") {
            return option?.label;
          }
          const selected = options.find(({ value }) => value === option);
          return selected?.label || "";
        }}
        isOptionEqualToValue={(option) =>
          props.multiple
            ? (value as SelectOption[]).some(
                ({ value }) => value === option.value
              )
            : option.value === value
        }
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder,
            }}
            error={hasError}
          />
        )}
      />
      {hasError && (
        <FormHelperText className="muiSelect" error>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}
