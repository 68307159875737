import React from "react";

import { Box, FormHelperText } from "@mui/material";
import { useIntl } from "react-intl";

import SelectInterests from "#components/common/Interests/SelectInterests";
import ChannelHeaderEdit from "#components/pages/ChannelDetails/ChannelHeader/components/ChannelHeaderEdit";
import {
  CreateChannelFormField,
  CreateChannelFormType,
} from "#components/pages/Feed/ChannelsSidebar/CreateChannel/useCreateChannelForm";
import { FormLabel, FormWrapper } from "#shared/components/ui";
import ModalContent from "#shared/components/ui/Modal/ModalContent";
import { useFormContext } from "#shared/context/FormContext";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";

export default function CreateChannelStepTwo() {
  const intl = useIntl();
  const intlPrefix = "ui.modals.create_channel";

  const { isMobile } = useMediaQueryProvider();

  const { formValues, formErrors, setValue } = useFormContext<CreateChannelFormType>();

  return (
    <ModalContent sx={{ padding: 0 }}>
      <Box position="relative">
        <ChannelHeaderEdit
          data={formValues}
          errors={formErrors}
          onChange={setValue}
          disableAutoFocus={isMobile}
        />
      </Box>
      <FormWrapper sx={{ paddingInline: 5, paddingBottom: 6 }}>
        <FormLabel
          label={intl.formatMessage({
            id: `${intlPrefix}.step_two.fields.category.label`,
          })}
          additionalLabel={intl.formatMessage({
            id: `${intlPrefix}.step_two.fields.category.sublabel`,
          })}
        />
        <SelectInterests
          value={formValues.interests}
          onChange={(val) => {
            setValue(CreateChannelFormField.interests, val);
          }}
        />
        {formErrors.interests && (
          <FormHelperText error>{formErrors.interests}</FormHelperText>
        )}
      </FormWrapper>
    </ModalContent>
  );
}
