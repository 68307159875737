import { FilterLabel } from "#containers/layout/Navigation/styled";
import { Box, styled, useTheme } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { NavigationAction } from "#constants/navigation";
import { NavigationItemProps } from "src/types/types";

interface Props {
  item: Omit<NavigationItemProps, "name" | "label"> & {
    name: string;
    label?: string;
  };
  selected?: boolean;
  color?: "primary" | "error" | "default";
  handleNavigationItemClick: (id: NavigationAction) => void;
}

export default function NavigationItem({
  item,
  selected,
  color = "default",
  handleNavigationItemClick,
}: Props) {
  const theme = useTheme();
  const { id, name, label, labelColor, LogoActive, LogoInactive, ActionIcon } = item;

  const getLogoColor = () => {
    switch (color) {
      case "primary":
        return theme.palette.primary.main;
      case "error":
        return theme.palette.error.main;
      default:
        return theme.palette.text.primary;
    }
  };

  return (
    <div
      role="button"
      onClick={() => handleNavigationItemClick(id)}
      className={classNames("filters main", selected && "selected")}
      key={id}
    >
      <div className="icon">
        {selected ? (
          <LogoActive color={theme.palette.primary.main} />
        ) : (
          <LogoInactive color={getLogoColor()} />
        )}
      </div>
      <LabelsWrapper>
        <div className="item">
          <div
            className={classNames("title", {
              selected,
              error: color === "error",
            })}
          >
            <span>{name}</span>
            {label && <FilterLabel color={labelColor}>{label}</FilterLabel>}
          </div>
        </div>
      </LabelsWrapper>
      {ActionIcon && <ActionIcon color={theme.palette.text.primary} />}
    </div>
  );
}

const LabelsWrapper = styled(Box)`
  width: calc(100% - 2rem);
  white-space: nowrap;
`;
