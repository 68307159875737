export async function convertVideoUrlToFile(url: string): Promise<File | undefined> {
  if (!url) {
    return undefined;
  }

  const fullUrl = url.includes("blob:") ? url : `${url}?timestamp=${Date.now()}`;

  const response = await fetch(fullUrl, {
    mode: "cors",
    cache: "no-cache",
    headers: {
      Origin: window.location.origin,
    },
  });

  const blob = await response.blob();
  const file = new File([blob], `video.mp4`, {
    type: `video/mp4`,
    lastModified: Date.now(),
  });
  return file;
}
