import { put } from "#shared/components/api/http";
import env from "#env";
import { getHeaders } from "#shared/components/api/utils";
import getFingerprint from "../../utils/getFingerprint";
import { ProfileDetailsForm } from "../../types/types";
import { APIErrorDetails, HttpApiCallErrorType } from "#shared/components/api/errors";

type CompleteProfileProps = {
  fingerprintId: string;
} & ProfileDetailsForm;

type UpdateProfileBodyProps = {
  fingerprintId: string;
  gender: string;
  birthday: string;
  countryId: number | null;
  displayName: string;
};
export default async function updateProfile({
  fingerprintId,
  ...profile
}: CompleteProfileProps) {
  try {
    return await put<UpdateProfileBodyProps, APIErrorDetails | { status: number }>(
      `${env.VITE_API_BASE_URL}/community/users`,
      {
        ...profile,
        fingerprintId: fingerprintId || (await getFingerprint()),
      },
      { headers: getHeaders(), credentials: "include" }
    );
  } catch (e) {
    return (e as HttpApiCallErrorType)?.errorDetails;
  }
}
