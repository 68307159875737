import {
  EditorLanguageOption,
  EditorAITextToneOption,
  EditorAIImageOption,
} from "#components/common/Editor/types";

const AI_TONE_LOCALE_PREFIX = "ui.tiptap_editor.menu.selection.ai_menu.change_tone";

export const EDITOR_AI_TONE_OPTIONS: EditorAITextToneOption[] = [
  {
    id: "academic",
    label: `${AI_TONE_LOCALE_PREFIX}.academic`,
    value: "academic",
  },
  {
    id: "business",
    label: `${AI_TONE_LOCALE_PREFIX}.business`,
    value: "business",
  },
  {
    id: "casual",
    label: `${AI_TONE_LOCALE_PREFIX}.casual`,
    value: "casual",
  },
  {
    id: "childfriendly",
    label: `${AI_TONE_LOCALE_PREFIX}.childfriendly`,
    value: "childfriendly",
  },
  {
    id: "conversational",
    label: `${AI_TONE_LOCALE_PREFIX}.conversational`,
    value: "conversational",
  },
  {
    id: "emotional",
    label: `${AI_TONE_LOCALE_PREFIX}.emotional`,
    value: "emotional",
  },
  {
    id: "humorous",
    label: `${AI_TONE_LOCALE_PREFIX}.humorous`,
    value: "humorous",
  },
  {
    id: "informative",
    label: `${AI_TONE_LOCALE_PREFIX}.informative`,
    value: "informative",
  },
  {
    id: "inspirational",
    label: `${AI_TONE_LOCALE_PREFIX}.inspirational`,
    value: "inspirational",
  },
  {
    id: "memeify",
    label: `${AI_TONE_LOCALE_PREFIX}.memeify`,
    value: "meme",
  },
  {
    id: "narrative",
    label: `${AI_TONE_LOCALE_PREFIX}.narrative`,
    value: "narrative",
  },
  {
    id: "objective",
    label: `${AI_TONE_LOCALE_PREFIX}.objective`,
    value: "objective",
  },
  {
    id: "persuasive",
    label: `${AI_TONE_LOCALE_PREFIX}.persuasive`,
    value: "persuasive",
  },
  {
    id: "poetic",
    label: `${AI_TONE_LOCALE_PREFIX}.poetic`,
    value: "poetic",
  },
];

export const EDITOR_AI_LANGUAGE_OPTIONS: EditorLanguageOption[] = [
  { id: "arabic", label: "locales.ar", value: "ar" },
  { id: "chinese", label: "locales.zh_CN", value: "zh" },
  { id: "english", label: "locales.en", value: "en" },
  { id: "french", label: "locales.fr", value: "fr" },
  { id: "german", label: "locales.de", value: "de" },
  { id: "greek", label: "locales.el", value: "el" },
  { id: "hindi", label: "locales.hi", value: "hi" },
  { id: "indonesian", label: "locales.id", value: "id" },
  { id: "italian", label: "locales.it", value: "it" },
  { id: "japanese", label: "locales.ja", value: "ja" },
  { id: "korean", label: "locales.kr", value: "ko" },
  { id: "malay", label: "locales.ms", value: "ms" },
  { id: "polish", label: "locales.pl", value: "pl" },
  { id: "portuguese", label: "locales.pt", value: "pt" },
  { id: "russian", label: "locales.ru", value: "ru" },
  { id: "spanish", label: "locales.es", value: "es" },
  { id: "swedish", label: "locales.sv", value: "sv" },
  { id: "turkish", label: "locales.tr", value: "tr" },
  { id: "ukrainian", label: "locales.uk", value: "uk" },
];

const AI_IMAGE_LOCALE_PREFIX = "ui.tiptap_editor.magic_widget.image.options";

export const EDITOR_AI_IMAGE_OPTIONS: EditorAIImageOption[] = [
  {
    id: "photorealistic",
    label: `${AI_IMAGE_LOCALE_PREFIX}.photorealistic`,
    value: "photorealistic",
  },
  {
    id: "digital_art",
    label: `${AI_IMAGE_LOCALE_PREFIX}.digital_art`,
    value: "digital_art",
  },
  {
    id: "comic_book",
    label: `${AI_IMAGE_LOCALE_PREFIX}.comic_book`,
    value: "comic_book",
  },
  { id: "neon_punk", label: `${AI_IMAGE_LOCALE_PREFIX}.neon_punk`, value: "neon_punk" },
  { id: "isometric", label: `${AI_IMAGE_LOCALE_PREFIX}.isometric`, value: "isometric" },
  { id: "line_art", label: `${AI_IMAGE_LOCALE_PREFIX}.line_art`, value: "line_art" },
  { id: "3d_model", label: `${AI_IMAGE_LOCALE_PREFIX}.3d_model`, value: "3d_model" },
];
