import { Box, styled } from "@mui/material";
import {
  DesktopTimePicker,
  LocalizationProvider,
  TimePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import colors from "../../../config/theme/colors";
import rounding from "../../../config/theme/rounding";

export default function CustomTimePicker<TDate>(props: TimePickerProps<TDate>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Wrapper>
        <DesktopTimePicker
          format="HH:mm"
          ampm={false}
          {...props}
          slotProps={{
            desktopPaper: {
              sx: {
                "&.MuiPaper-root": {
                  marginTop: "1rem",
                  borderRadius: rounding.default,
                  border: `1px solid ${colors.base.outline1}`,
                  boxShadow: "0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000001A",
                  overflow: "hidden",
                  padding: "8px 4px",

                  ".MuiMultiSectionDigitalClock-root": {
                    border: "none",

                    ".MuiMultiSectionDigitalClockSection-root": {
                      borderRadius: 0,
                      border: "none",
                      width: "48px",

                      "&::-webkit-scrollbar": {
                        display: "none",
                      },

                      ".MuiMultiSectionDigitalClockSection-item": {
                        border: "none",
                        borderRadius: rounding.default,
                        width: "40px",
                        height: "40px",

                        "&.Mui-selected": {
                          color: colors.base.white,
                          backgroundColor: `${colors.brand.default} !important`,
                        },
                      },
                    },
                  },

                  ".MuiDialogActions-root": {
                    display: "none",
                  },
                },
              },
            },
          }}
        />
      </Wrapper>
    </LocalizationProvider>
  );
}

const Wrapper = styled(Box)`
  .MuiInputAdornment-positionEnd .MuiButtonBase-root {
    margin-left: -2.25rem;
  }
`;
