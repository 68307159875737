// modules
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router";

// components
import BusinessLayout from "#containers/layout/BusinessLayout";
import useOrganisationPermissions from "#components/pages/BusinessAccount/useOrganisationPermissions";
import useSelectedOrganisation from "#components/pages/BusinessAccount/useSelectedOrganisation";

export default function WrapperBusinessCentreRoute() {
  const { pathname } = useLocation();
  const params = useParams() as { organisationId?: string };

  const { organisationId, defaultOrganisationId } = useSelectedOrganisation();
  const { canAccessOrganisation } = useOrganisationPermissions();

  useEffect(() => {
    document.body.classList.add("business-centre");
    return () => {
      document.body.classList.remove("business-centre");
    };
  }, []);

  if (organisationId === -1 && defaultOrganisationId === -1) {
    return <Navigate to="/" replace />;
  }

  if (!params.organisationId) {
    return <Navigate to={`/business-centre/${defaultOrganisationId}`} replace />;
  }

  // invalid organisationId, redirect to default organisation
  if (organisationId === -1) {
    return (
      <Navigate
        to={pathname.replace(params.organisationId, String(defaultOrganisationId))}
        replace
      />
    );
  }

  if (!canAccessOrganisation) {
    return <Navigate to="/business-lab" replace />;
  }

  return (
    <BusinessLayout>
      <React.Suspense>
        <Outlet />
      </React.Suspense>
    </BusinessLayout>
  );
}
