import { ChannelsApi } from "#apis/channels/api";
import { ChannelsQueryKeys } from "#apis/channels/queryKeys";
import { QUERY_TAGS } from "#constants/query";
import {
  ChannelListItem,
  GetMyFollowedChannelsResponse,
  JoinLeaveChannelRequest,
} from "#customTypes/channel";
import { AppMutationOptions } from "#customTypes/reactQuery";
import { channelsApi } from "#features/channel/channelsAPI";
import { BriefQueryKeys } from "#utils/queryKeys";
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";
import { useStore } from "react-redux";
import { RootState } from "src/store";

export default function useLeaveChannelMutation(
  options?: AppMutationOptions<JoinLeaveChannelRequest & { channel?: ChannelListItem }>
) {
  const queryClient = useQueryClient();
  const store = useStore<RootState>();

  return useMutation({
    mutationFn: (params) => ChannelsApi.leaveChannel(params),
    ...options,
    onSuccess: (data, params, context) => {
      options?.onSuccess?.(data, params, context);

      store.dispatch(
        channelsApi.util.invalidateTags([
          { type: QUERY_TAGS.Channel, id: params.channelRef },
        ])
      );

      queryClient.invalidateQueries({ queryKey: BriefQueryKeys.details() });
      queryClient.invalidateQueries({
        queryKey: BriefQueryKeys.list(),
      });

      queryClient.setQueriesData<InfiniteData<GetMyFollowedChannelsResponse>>(
        {
          queryKey: ChannelsQueryKeys.followed,
        },
        (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              data: page.data.filter(
                (channel) => channel.channelRef !== params.channelRef
              ),
            })),
          };
        }
      );
    },
  });
}
