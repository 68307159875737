import { client } from "#apis/axios";
import {
  GetInterestsResponse,
  Interests,
  UpdateMyInterestsRequest,
} from "#customTypes/interests";

export namespace InterestsApi {
  const BASE_PREFIX = "/community/users-interests";

  export const getInterests = async () => {
    // TODO: This is going to be unified on backend with common base prefix
    const { data } = await client.get<GetInterestsResponse>(`/community/interests`);

    return data;
  };

  export const getUserInterests = async () => {
    const { data } = await client.get<Interests>(`${BASE_PREFIX}`);

    return data;
  };

  export const updateUserInterests = async (body: UpdateMyInterestsRequest) => {
    const { data } = await client.put<Interests>(`${BASE_PREFIX}`, body);

    return data;
  };
}
