import composeHooks from "../../../utils/hooks/composeHooks";
import useVideo from "../Video/useVideo";
import Video from "./ui";
import { UseVideoOutputProps } from "../../../types";

type BriefVideoProps = {
  videoSrc: string;
  videoWidth?: string;
  videoHeight?: string;
  play?: false;
};

export default composeHooks<{ useVideo: UseVideoOutputProps }, BriefVideoProps>(
  (props: BriefVideoProps) => ({
    useVideo: () => useVideo(props.play),
  })
)(Video);
