import { IMAGE_TYPES, VIDEO_TYPES } from "../types/types";
import env from "#env";

export default (resource: string | undefined, imageType: IMAGE_TYPES | VIDEO_TYPES) => {
  if (!resource) {
    return "";
  }

  if (resource.startsWith("blob:") || resource.startsWith("http")) {
    return resource;
  }

  return `${env.VITE_RESOURCES_BASE_URL}${imageType}.html?name=${resource}`;
};
