import { styled } from "@mui/material/styles";
import colors from "#shared/config/theme/colors";

export const AuthSeparatorWrapper = styled("div")`
  width: 100%;
  height: 1.5rem;
  position: relative;
  margin: 2rem 0rem 2.2rem;

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 1rem;
    background-color: #fff;
    color: #828282;
    text-align: center;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #e4e4e4;
  }
`;

export const StrategiesWrapper = styled("div")`
  width: 100%;
  display: flex;
  gap: 0.8rem;
  justify-content: center;
`;

export const StrategyButton = styled("button")`
  display: flex;
  width: 50px;
  height: 50px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d8dae5;
  transition: 0.25s ease-in-out;

  &:hover {
    border-color: ${colors.brand.default};
  }
`;
