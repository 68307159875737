import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";

const menuOverrides = (theme: Theme): ComponentOverrides<"MuiMenu"> => ({
  styleOverrides: {
    paper: {
      borderRadius: "0.625rem",
      boxShadow: "0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000001A",

      [theme.breakpoints.up("md")]: {
        maxWidth: "360px",
      },
    },
  },
});

export default menuOverrides;
