import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";

const outlinedInputOverrides = (
  theme: Theme
): ComponentOverrides<"MuiOutlinedInput"> => ({
  styleOverrides: {
    root: {
      borderRadius: "0.625rem !important",

      input: {
        fontSize: theme.typography.body4.fontSize,
      },
    },
  },
});

export default outlinedInputOverrides;
