import React from "react";

const composeHooks =
  <T extends object, V = Record<string, unknown>>(hooks: any) =>
  (Component: React.FunctionComponent<T & V>) => {
    const ComponentV = Component as unknown as React.FunctionComponent<V>;
    if (!Component) {
      throw new Error("Component must be provided to compose");
    }
    if (!hooks) {
      return ComponentV;
    }
    return function WrapperCompWithHookProps(props: V) {
      const hooksObject = typeof hooks === "function" ? hooks(props) : hooks;
      const hooksProps = Object.entries(hooksObject).reduce(
        (acc, [hookKey, hook]) => {
          const hookFunc = hook as () => Record<keyof T, T[keyof T]>;
          return {
            ...acc,
            [hookKey]: hookFunc(),
          };
        },
        {}
      ) as T;
      return <ComponentV {...hooksProps} {...props} />;
    };
  };

export default composeHooks;
