import React from "react";
import { UnsplashPhoto } from "#customTypes/unsplash";
import { ImageListItem, ImageListItemBar, styled } from "@mui/material";
import rounding from "#shared/config/theme/rounding";
import { Link } from "react-router-dom";
import colors from "#shared/config/theme/colors";
import classNames from "classnames";
import { LANDSCAPE_THRESHOLD_RATIO } from "#shared/utils";

interface Props {
  image: UnsplashPhoto;
  onClick: (id: string) => void;
}

export default function UnsplashImagePreview({ image, onClick }: Props) {
  const { id, width, height, alt_description, urls, user } = image;

  const isLandscape = width / height >= LANDSCAPE_THRESHOLD_RATIO;

  return (
    <Wrapper key={id} onClick={() => onClick(id)}>
      <img
        srcSet={urls.thumb}
        src={urls.thumb}
        alt={alt_description}
        className="-blured-bg"
      />
      <img
        srcSet={urls.thumb}
        src={urls.thumb}
        alt={alt_description}
        className={classNames("-main", isLandscape && "-landscape")}
      />
      <ItemBar
        position="bottom"
        title={
          <Link
            to={`${user.links.html}?utm_source=bulbshare&utm_medium=referral`}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {user.name}
          </Link>
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled(ImageListItem)`
  border-radius: ${rounding.default};
  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: 1;

  .MuiImageListItem-img {
    &.-blured-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(30px);
      z-index: -1;
    }

    &.-main {
      object-fit: contain;
      height: 100%;

      &.-landscape {
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .MuiImageListItemBar-titleWrap {
    padding: 0.375rem 0.5rem;
    margin-top: 0.75rem;
  }
`;

const ItemBar = styled(ImageListItemBar)`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #222222 100%);
  height: 3rem;

  a {
    color: ${colors.base.white};
    font-size: 0.75rem;
  }
`;
