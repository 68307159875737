/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, createContext, useContext } from "react";
import { FormReturnType } from "../utils/hooks/useForm";

export type FormContextType<T> = FormReturnType<T>;

const defaultContextValue: FormReturnType<any> = {
  hasError: false,
  hasChanges: false,
  formErrors: {},
  formValues: {},
  getValue: () => {},
  setValue: () => {},
  setValues: () => {},
  setDefaultValues: () => {},
  updateDefaultValues: () => {},
  getError: () => "",
  setError: () => {},
  setErrors: () => {},
  validate: () => false,
  validateField: () => "",
  resetForm: () => {},
};

export const FormContext = createContext<FormContextType<any>>(defaultContextValue);

export function useFormContext<T>(): FormContextType<T> {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context as FormContextType<T>;
}

type ProviderProps<T> = {
  value: FormReturnType<T>;
  children: ReactNode;
};

export function FormProvider<T>({ value, children }: ProviderProps<T>) {
  return (
    <FormContext.Provider value={value as FormContextType<any>}>
      {children}
    </FormContext.Provider>
  );
}
