import { SortDirection } from "@mui/material";
import dayjs from "dayjs";
import { get } from "radash";

type DotPrefix<T extends string> = T extends "" ? "" : `.${T}`;

export type DotNestedKeys<T> = (
  T extends object
    ? {
        [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}`;
      }[Exclude<keyof T, symbol>]
    : ""
) extends infer D
  ? Extract<D, string>
  : never;

export function sortInternalData<T>(
  data: T[],
  prop: DotNestedKeys<T>,
  direction: SortDirection
) {
  if (!data.length) {
    return [];
  }

  return [...data].sort((a, b) => {
    const valueA = get(a, prop);
    const valueB = get(b, prop);

    if (valueA === undefined) {
      return 1;
    }

    if (valueB === undefined) {
      return -1;
    }
    const isNumber = isNumeric(valueA) && isNumeric(valueB);
    const isDate = isDateString(valueA) && isDateString(valueB);

    if (isNumber) {
      return direction === "asc"
        ? Number(valueA) - Number(valueB)
        : Number(valueB) - Number(valueA);
    }

    if (isDate) {
      const dateA = new Date(String(valueA));
      const dateB = new Date(String(valueB));

      return direction === "asc"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    }

    return direction === "asc"
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  });
}

const isNumeric = (value: any): boolean => {
  return (
    typeof value === "number" || (typeof value === "string" && !isNaN(Number(value)))
  );
};

const isDateString = (value: any): boolean => {
  return typeof value === "string" && dayjs(value).isValid();
};
