import React, { ReactElement } from "react";
import SelectCard from "#shared/components/ui/SelectCard";
import colors from "#shared/config/theme/colors";
import { ReactIcon } from "#shared/types";
import { Box, Typography, styled } from "@mui/material";
import IconLock from "#assets/svgs/lock.svg?react";
import Tooltip from "#shared/components/ui/Tooltip";
import classNames from "classnames";
import { Radio } from "#shared/components/ui";
import Checkbox from "#shared/components/ui/Checkbox";

interface Props {
  title: string;
  value: string;
  description?: string;
  selected?: boolean;
  disabled?: boolean;
  locked?: boolean;
  tooltip?: string;
  Icon?: ReactIcon;
  iconSize?: "small" | "large";
  type?: "radio" | "checkbox";
  children?: ReactElement;
  onClick: (value: string) => void;
}

export type BuilderSelectItemProps = Omit<Props, "type" | "onClick">;

export default function BuilderSelectItem({
  title,
  value,
  description,
  Icon,
  iconSize = "small",
  selected,
  disabled,
  locked,
  tooltip,
  type,
  children,
  onClick,
}: Props) {
  const isDisabled = locked || disabled;

  const handleSelect = () => {
    onClick(value);
  };

  const action = () => {
    if (locked) {
      return <LockIcon />;
    }

    if (type === "radio") {
      return (
        <Radio checked={selected} size="small" disableRipple onChange={handleSelect} />
      );
    }

    return (
      <Checkbox checked={selected} size="small" disableRipple onChange={handleSelect} />
    );
  };

  return (
    <Tooltip title={tooltip} placement="top">
      <SelectCard
        selected={selected}
        disabled={isDisabled}
        onClick={handleSelect}
        sx={{ p: 4, display: "flex", flexDirection: "column", height: "100%" }}
      >
        <SelectButtonWrapper className={classNames(isDisabled && "--disabled")}>
          {action()}
        </SelectButtonWrapper>

        {Icon && <IconWrapper className={`--size-${iconSize}`}>{<Icon />}</IconWrapper>}

        <Typography
          variant="label2"
          color={!isDisabled ? colors.text.primaryDark : colors.text.tertiaryDark}
          fontFamily="Avenir-heavy"
          mb={1}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            variant="label2"
            color={!isDisabled ? colors.text.secondaryDark : colors.text.tertiaryDark}
          >
            {description}
          </Typography>
        )}
        {children}
      </SelectCard>
    </Tooltip>
  );
}

const SelectButtonWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;

  &.--disabled {
    pointer-events: none;
    color: ${colors.grey[400]};
  }
`;

const LockIcon = styled(IconLock)`
  margin: 0.5rem;
`;

const IconWrapper = styled(Box)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 0.5rem;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  &.--size-small svg {
    color: ${colors.grey[600]};
    width: 1.5rem;
    height: 1.5rem;
  }
`;
