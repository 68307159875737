import { styled } from "@mui/material";

export default styled("div")<{
  height?: string;
  width?: string;
}>(
  ({ height = "100%", width = "100%" }) => `
     display: flex;
     min-height: ${height};
    align-items: stretch;
    width: ${width};
    flex-basis: ${width};
    flex-grow: 1;
    position: absolute;
    cursor: pointer;
    
    & > video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    } 
    
    & .play {
      display: flex;
        align-items: center;  
        justify-content: center;
        z-index: 1;
        width: 100%;
         &.isPlaying {
            display: none;
          }
    }
`
);
