import {
  businessNavigationItems,
  mainNavigationItems,
} from "#containers/layout/Navigation/items";
import { NavigationAction } from "#constants/navigation";

export function getDefaultNavigationConfiguration(pathname: string) {
  if (pathname.startsWith("/business-centre")) {
    return {
      items: businessNavigationItems,
      defaultSelected: NavigationAction.business_account,
    };
  }

  return {
    items: mainNavigationItems,
    defaultSelected: NavigationAction.foryou,
  };
}
