import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Box, styled } from "@mui/material";

interface Props {
  inlinePadding?: number;
  children: ReactNode;
}

export default function AIChatCarousel({ inlinePadding = 16, children }: Props) {
  const rowRef = useRef<HTMLDivElement>(null);
  const [rowWidth, setRowWidth] = useState(0);

  useEffect(() => {
    if (!rowRef.current) {
      return;
    }

    const rowWidth = rowRef.current.scrollWidth / 2;
    const offsetWidth = inlinePadding / 4;

    setRowWidth(rowWidth + offsetWidth);
  }, []);

  return (
    <Carousel className="carousel" sx={{ "--animate-width": `-${rowWidth}px` }}>
      <Row ref={rowRef}>
        {children}
        {children}
      </Row>
    </Carousel>
  );
}

const Carousel = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  animation: scroll var(--animation-duration, 60s) linear infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(var(--animate-width));
    }
  }
`;

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-right: 20px;
`;
