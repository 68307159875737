import env from "#env";
import { Cookies } from "../../types/types";
import { get } from "#shared/components/api/http";
import { Profile } from "../../types/db";
import { getHeaders } from "#shared/components/api/utils";

async function fetchProfile(cookie?: Cookies | undefined): Promise<Profile | null> {
  try {
    return await get<Profile>(`${env.VITE_API_BASE_URL}/community/users`, {
      headers: getHeaders(cookie),
      credentials: "include",
    });
  } catch (e) {
    console.log("Error when fetching user");
    return null;
  }
}

export default fetchProfile;
