import { ChannelsQueryKeys } from "#apis/channels/queryKeys";
import { InterestsApi } from "#apis/interests/api";
import { InterestsQueryKeys } from "#apis/interests/queryKeys";
import { Interests, UpdateMyInterestsRequest } from "#customTypes/interests";
import { AppMutationOptions } from "#customTypes/reactQuery";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useUpdateUserInterests(
  options?: AppMutationOptions<UpdateMyInterestsRequest, Interests>
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => InterestsApi.updateUserInterests(params),
    ...options,
    onSuccess: (data, params, context) => {
      options?.onSuccess?.(data, params, context);

      queryClient.setQueriesData<Interests>(
        {
          queryKey: InterestsQueryKeys.user,
        },
        data
      );
      queryClient.invalidateQueries({ queryKey: ChannelsQueryKeys.suggested });
    },
  });
}
