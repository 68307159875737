import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import colors from "#shared/config/theme/colors";

export const Wrapper = styled("div")(
  ({ theme }) => `
  margin: 2em auto;
  max-width: 29.375rem;
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    margin: 0 0 0.25rem 0;
  }`
);

export const StyledTabsComponent = styled(Tabs)({
  border: "none",
});

export const StyledTabComponent = styled(Tab, {
  shouldForwardProp: (name) => name !== "nonselectable",
})<{ nonselectable: boolean }>(
  ({ theme, nonselectable }) => `
  borderBottom: "none";
  flex: 1;
  gap: 0.25rem;
  color: ${colors.text.tertiaryDark};

  ${
    nonselectable &&
    `
    color: ${colors.text.disabledDark};

    & svg {
      fill: ${colors.text.disabledDark};
    }
    `
  }
  
  "&.Mui-disabled": {
    color: ${theme.palette.text.disabled};
  },
`
);
