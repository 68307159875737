export const ButtonIDs = {
  header: { closeButton: "header-closeButton" },
  bussinessSettings: { backButton: "bussiness-settings-backButton" },
  briefDetails: {
    closeButton: "briefDetails-closeButton",
    joinBriefContent: "briefDetails-joinBriefContent",
    joinBriefSidebar: "briefDetails-joinBriefSidebar",
    briefComments: "briefDetails-briefComments",
    bulbshares: "briefDetails-bulbshares",
    share: "briefDetails-share",
  },
  channel: {
    share: "share-Channel",
    back: "back-Channel",
  },
};
