import React from "react";
import useGenerateBriefMutation from "#apis/briefBuilder/hooks/useGenerateBriefMutation";
import useToast from "#shared/hooks/useToast";
import { useFormContext } from "#shared/context/FormContext";
import { AIChatTemplateForm } from "#components/common/AIChat/hooks/useAIChatTemplateForm";
import { AIChatMetadataData } from "#components/common/AIChat/types";
import { useNavigate } from "react-router";
import { useAIChatProvider } from "#components/common/AIChat/context/AIChatProvider";
import useTranslation from "#utils/useTranslation";
import AIChatButton from "#components/common/AIChat/components/AIChatButton";
import IconSparkles from "#assets/svgs/sparkles.svg?react";

interface Props {
  metadata?: AIChatMetadataData;
  onCreateBrief?: () => void;
}

export default function BriefTemplateSubmitButton({ metadata, onCreateBrief }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { channel, reset } = useAIChatProvider();
  const form = useFormContext<AIChatTemplateForm>();
  const { formValues, hasError, resetForm } = form;

  const { mutate: generateBrief, isPending } = useGenerateBriefMutation({
    onSuccess: ({ briefGenerateRequestId }) => {
      resetForm();
      reset();
      onCreateBrief?.();
      navigate(`/brief-builder/generate/${briefGenerateRequestId}`);
    },
    onError: () => toast.error(),
  });

  const handleGenerateBrief = async () => {
    if (hasError || !channel?.channelRef) {
      return;
    }

    generateBrief({
      channelRef: channel?.channelRef,
      templateData: {
        title: formValues.title,
        objectives: [formValues.prompt],
        target_audience: {
          countries: metadata?.target_audience.countries || [],
          gender: formValues.gender,
          country_ids: formValues.countries,
          clean_age_min: formValues.minAge,
          clean_age_max: formValues.maxAge,
        },
        methodology: formValues.responseType,
        response_type: formValues.responseType,
      },
    });
  };

  return (
    <AIChatButton
      size="small"
      startIcon={<IconSparkles />}
      fullWidth
      onClick={handleGenerateBrief}
      disabled={isPending || hasError}
      sx={{ mt: 4 }}
    >
      {t("ui.ai_chat.buttons.create_brief")}
    </AIChatButton>
  );
}
