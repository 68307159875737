import useTranslation from "#utils/useTranslation";
import { useMemo } from "react";

export default function useBuilderGenders() {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: "male",
        label: t("demographics.genders.male"),
      },
      {
        value: "female",
        label: t("demographics.genders.female"),
      },
      {
        value: "other",
        label: t("demographics.genders.other"),
      },
    ],
    [t]
  );
}
