import React from "react";
import { OAUTH_STRATEGIES } from "#constants/common";

import AppleLogo from "#assets/svgs/apple-logo.svg?react";
import FacebookLogo from "#assets/svgs/facebook-logo.svg?react";
import MicrosoftLogo from "#assets/svgs/microsoft-logo.svg?react";
import GoogleLogo from "#assets/svgs/google-logo.svg?react";
import { StrategiesWrapper, StrategyButton } from "../styled";
import useOauthStrategy from "../useOauthStrategy";

export const AUTHENTICATION_STRATEGIES = [
  {
    strategy: OAUTH_STRATEGIES.google,
    url: `/auth/google`,
    Logo: GoogleLogo,
  },
  {
    strategy: OAUTH_STRATEGIES.facebook,
    url: `/auth/facebook`,
    Logo: FacebookLogo,
  },
  {
    strategy: OAUTH_STRATEGIES.apple,
    url: `/auth/apple`,
    Logo: AppleLogo,
    logoStyle: { transform: "translateY(-1px)" },
  },
  {
    strategy: OAUTH_STRATEGIES.microsoft,
    url: "/auth/microsoft",
    Logo: MicrosoftLogo,
  },
];
interface Props {
  rerequestFacebookAuth: boolean;
}

export default function AuthenticationStrategies(props: Props) {
  const { onClickOauthStrategy } = useOauthStrategy(props);

  return (
    <StrategiesWrapper>
      {AUTHENTICATION_STRATEGIES.map(({ strategy, url, Logo, logoStyle }) => (
        <StrategyButton
          key={`login_button_${strategy}`}
          onClick={() =>
            onClickOauthStrategy({
              provider: true,
              url,
              oauthStrategy: strategy,
            })
          }
        >
          <Logo style={logoStyle} />
        </StrategyButton>
      ))}
    </StrategiesWrapper>
  );
}
