import { useDispatch } from "react-redux";
import { setDownloadModal } from "#features/downloadModal/downloadModalSlice";

import { setJoinBriefId } from "#features/joinBriefId/joinBriefIdSlice";

export default function useJoinBrief(): (briefRef: string | null | undefined) => void {
  const dispatch = useDispatch();

  const onJoinBrief = async (briefRef: string | null | undefined) => {
    if (briefRef) {
      dispatch(setJoinBriefId(briefRef));
    }
    dispatch(setDownloadModal(true));
  };
  return onJoinBrief;
}
