export enum ShareTarget {
  Native = "Native",
  Facebook = "Facebook",
  FacebookMessenger = "FacebookMessenger",
  Twitter = "Twitter",
  Linkedin = "Linkedin",
  Threads = "Threads",
  Whatsapp = "Whatsapp",
  Email = "Email",
}

export type ShareTargetData = {
  id: ShareTarget;
  label: string;
  url: string;
  hidden?: boolean;
  icon: React.ReactElement;
  color?: string;
  params: Record<string, string>;
};

export type ShareConfig = {
  title: string;
  url: string;
  redirectUrl?: string;
  targets: ShareTargetData[];
};
