export const flattenArray = (nestedMessages: any, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, {
        [prefixedKey]: value,
      });
    } else {
      Object.assign(messages, flattenArray(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const removeKeyFromObject = <T>(
  key: string,
  { [key]: _, ...rest }
): Omit<T, keyof T> => rest as T;

export const addObjectToArray =
  <T>(obj: T): ((items: T[]) => T[]) =>
  (items: T[]) =>
    [...items, obj];

export const updateObjInArray =
  <T>(
    index: number,
    newValues: {
      [K in keyof T]?: any;
    }
  ): ((items: T[]) => T[]) =>
  (items: T[]) =>
    items.map<T>((item, i) => {
      if (i === index) {
        return {
          ...item,
          ...newValues,
        };
      }
      return item;
    });

export const removeObjectFromArray = <T>(index: number) => {
  return (items: T[]) => items.filter((item, i) => index !== i);
};

export const updateArrayAtIndex = (
  items: any[],
  index: number,
  newValue: any
) => [...items.slice(0, index), newValue, ...items.slice(index + 1)];

export const insert = <T>(arr: T[], newItem: T, index = 0) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];
