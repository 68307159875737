import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { ToastOptions } from "../types/toast";
import { v4 as uuidv4 } from "uuid";

export type ToastContextType = {
  toasts: ToastOptions[];
  options: ToastProviderOptions;
  addToast: (options: ToastOptions) => void;
  removeToast: (id: string) => void;
  removeAllToasts: () => void;
};

interface ToastProviderOptions {
  defaultErrorMessage?: string;
}

interface Props {
  options: ToastProviderOptions;
  children: ReactNode;
}

export function ToastProvider({ options, children }: Props) {
  const [toasts, setToasts] = useState<ToastOptions[]>([]);

  const addToast = useCallback((options: ToastOptions) => {
    const id = options.id || uuidv4();
    setToasts((prevToasts) => [...prevToasts, { ...options, id }]);
  }, []);

  const removeToast = useCallback((id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const removeAllToasts = useCallback(() => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.duration === null));
  }, []);

  return (
    <ToastContext.Provider
      value={{ toasts, options, addToast, removeToast, removeAllToasts }}
    >
      {children}
    </ToastContext.Provider>
  );
}

const ToastContext = createContext<ToastContextType | null>(null);

export const useToastProvider = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToastContext must be used within a ToastProvider");
  }
  return context;
};
