import React from "react";

import { FormattedMessage } from "react-intl";
import LoginFootnoteWrapper from "../../../common/LoginComponents/LoginFootnoteWrapper";
import { AvenirAndHeavyText } from "../../../common/styled";

import { LINK_FALLBACKS } from "#constants/common";
import useWlaOrganisation from "#utils/useWlaOrganisation";

export default function FooterTextContent() {
  const { organisation } = useWlaOrganisation();

  return (
    <LoginFootnoteWrapper>
      <AvenirAndHeavyText fontSize="inherit" lineHeight="inherit">
        <FormattedMessage
          id="pages.login.content.eula_warning"
          values={{
            tos: (
              <a
                href={organisation?.termsOfUse || LINK_FALLBACKS.terms}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="footer.terms.title" />
              </a>
            ),
            pp: (
              <a
                href={organisation?.privacyPolicy || LINK_FALLBACKS.privacy}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="footer.privacy.title" />
              </a>
            ),
          }}
        />
      </AvenirAndHeavyText>
    </LoginFootnoteWrapper>
  );
}
