import { client } from "#apis/axios";
import {
  GetChannelMembersRequest,
  GetChannelMembersResponse,
  GetMyFollowedChannelsRequest,
  GetMyFollowedChannelsResponse,
  GetMySuggestedChannelsResponse,
  JoinLeaveChannelRequest,
  RemoveChannelMemberRequest,
  SearchChannelsRequest,
  SearchChannelsResponse,
} from "#customTypes/channel";
import { fetchAllPages } from "#utils/fetchAllPages";

export namespace ChannelsApi {
  const BASE_PREFIX = "/community/channels";

  export const getFollowedChannels = async (
    params: GetMyFollowedChannelsRequest
  ): Promise<GetMyFollowedChannelsResponse> => {
    const { data } = await client.get<GetMyFollowedChannelsResponse>(
      `${BASE_PREFIX}/my-followed-channels`,
      { params }
    );

    return data;
  };

  export const getOwnedChannels = async () => {
    try {
      const channels = await fetchAllPages({
        queryFn: async (params) => {
          const { data } = await client.get<GetMyFollowedChannelsResponse>(
            `${BASE_PREFIX}/my-owned-channels`,
            {
              params,
            }
          );
          return data;
        },
      });

      return channels;
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  export const getSuggestedChannels = async () => {
    const { data } = await client.get<GetMySuggestedChannelsResponse>(
      `${BASE_PREFIX}/suggested-channels`
    );

    return data;
  };

  export const searchChannels = async (params: SearchChannelsRequest) => {
    const { data } = await client.get<SearchChannelsResponse>(`${BASE_PREFIX}/search`, {
      params,
    });

    return data;
  };

  export const getMembers = async (params: GetChannelMembersRequest) => {
    const { data } = await client.get<GetChannelMembersResponse>(
      `${BASE_PREFIX}/members`,
      {
        params,
      }
    );

    return data;
  };

  export const removeMember = async (body: RemoveChannelMemberRequest) => {
    await client.post(`${BASE_PREFIX}/remove-member`, body);
  };

  export const joinChannel = async (body: JoinLeaveChannelRequest) => {
    await client.post(`${BASE_PREFIX}/join`, body);
  };

  export const leaveChannel = async (body: JoinLeaveChannelRequest) => {
    await client.post(`${BASE_PREFIX}/leave`, body);
  };
}
