import { Theme } from "@mui/material";
import { ComponentOverrides } from "../types";

const popperOverrides = (theme: Theme): ComponentOverrides<"MuiPopper"> => ({
  styleOverrides: {
    root: {
      zIndex: "1500 !important",
      li: {
        fontSize: theme.typography.body4.fontSize,
        lineHeight: theme.typography.body4.lineHeight,
      },
    },
  },
});

export default popperOverrides;
