import { styled } from "@mui/material/styles";
import colors from "#shared/config/theme/colors";
import { NavigationItemLabelColor } from "src/types/types";

export const FilterLabel = styled("span")<{ color?: NavigationItemLabelColor }>(
  ({ theme, color = "primary" }) => {
    let backgroundColor;
    let textColor;

    switch (color) {
      case "primary":
        backgroundColor = theme.palette.error.light;
        textColor = theme.palette.common.white;
        break;
      case "secondary":
        backgroundColor = colors.grey[300];
        textColor = colors.grey[700];
        break;
      default:
        backgroundColor = color;
        textColor = theme.palette.common.white;
        break;
    }

    return `
      font-family: Avenir-heavy;
      margin-left: 0.375rem;
      font-size: 0.75rem;
      padding: 0.125rem 0.5rem;
      color: ${textColor} !important;
      background-color: ${backgroundColor};
      border-radius: 1.25rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `;
  }
);
