import { WrapperFilter } from "../../../components/pages/Feed/ChannelsSidebar/commonStyled";
import React from "react";

import { NavigationAction } from "#constants/navigation";

import useNavigation from "#containers/layout/Navigation/hooks/useNavigation";
import { NavigationItems } from "#customTypes/types";
import NavigationItem from "#containers/layout/Navigation/components/NavigationItem";
import { Box, Divider } from "@mui/material";
import useNavigationAction from "#containers/layout/Navigation/hooks/useNavigationAction";

interface Props {
  navItems?: NavigationItems;
  onNavigationItemClick?: (itemId: NavigationAction) => void;
}

export default function Navigation({ navItems, onNavigationItemClick }: Props) {
  const { items, selectedItem } = useNavigation(navItems);
  const { navigateAction } = useNavigationAction();

  const handleNavigationItemClick = async (action: NavigationAction) => {
    onNavigationItemClick?.(action);
    navigateAction(action);
  };

  return (
    <WrapperFilter>
      {items.map((item) => {
        const isSelected = selectedItem === item.id;
        if (item.id === NavigationAction.logout) {
          return (
            <Box key={item.id}>
              <Divider />
              <NavigationItem
                item={item}
                color="error"
                handleNavigationItemClick={handleNavigationItemClick}
              />
            </Box>
          );
        }

        return (
          <NavigationItem
            key={item.id}
            item={item}
            selected={isSelected}
            handleNavigationItemClick={handleNavigationItemClick}
          />
        );
      })}
    </WrapperFilter>
  );
}
