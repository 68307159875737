import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import React from "react";

interface Props extends TooltipProps {
  removeLineHeight?: boolean;
}

export default function Tooltip({
  children,
  title,
  hidden,
  removeLineHeight,
  ...props
}: Props) {
  return (
    <MuiTooltip {...props} title={!hidden ? title : undefined} hidden={false}>
      <span style={{ display: "block", lineHeight: removeLineHeight ? 0 : 1 }}>
        {children}
      </span>
    </MuiTooltip>
  );
}
