import React from "react";
import BriefBuilderProvider from "#containers/BriefBuilder/BriefBuilderProvider";
import BriefBuilderHeader from "#containers/BriefBuilder/Header/BriefBuilderHeader";
import BuilderLock from "#containers/BriefBuilder/Header/Lock";
import AppLoader from "#containers/Global/AppLoader";
import {
  useFetchBuilderBriefQuery,
  useFetchBuilderLockQuery,
  useFetchBuilderStepsQuery,
} from "#features/briefBuilder/briefBuilderAPI";
import { Navigate, Outlet, useParams } from "react-router";
import useQueryLoading from "#containers/Global/useQueryLoading";
import { BriefBuilderNavigateProvider } from "#containers/BriefBuilder/BriefBuilderNavigateProvider";
import BriefBuilderGuard from "#components/routing/briefBuilder/BriefBuilderGuard";

export default function WrapperBriefBuilderRoute() {
  const { briefRef } = useParams();

  const briefQuery = useFetchBuilderBriefQuery(
    { briefRef },
    {
      skip: !briefRef,
    }
  );

  const { data: briefData, isError } = briefQuery;

  const stepsQuery = useFetchBuilderStepsQuery(briefRef, {
    skip: !briefRef,
  });

  const lockQuery = useFetchBuilderLockQuery(briefRef, { skip: !briefData });

  const { isError: isLockError } = lockQuery;

  const { data: stepsData } = stepsQuery;

  const isLoading = useQueryLoading([briefQuery, stepsQuery, lockQuery]);

  if (isLoading) {
    return <AppLoader />;
  }

  if (!briefData || !stepsData?.steps || isError || isLockError) {
    return <Navigate to="/404" replace />;
  }

  return (
    <BriefBuilderProvider brief={briefData} steps={stepsData.steps}>
      <BriefBuilderNavigateProvider>
        <BriefBuilderHeader />
        <BuilderLock>
          <React.Suspense>
            <Outlet />
          </React.Suspense>
        </BuilderLock>
        <BriefBuilderGuard />
      </BriefBuilderNavigateProvider>
    </BriefBuilderProvider>
  );
}
