import { useState, useEffect, useRef } from "react";

interface Props {
  hidden?: boolean;
}

export default function useTooltipEllipsis({ hidden }: Props = {}) {
  const ref = useRef<HTMLDivElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsEllipsisActive(element.offsetWidth < element.scrollWidth);
    }
  }, [ref, hidden]);

  return {
    ref,
    hidden: !isEllipsisActive || hidden,
  };
}
