import colors from "#shared/config/theme/colors";
import { Button, styled } from "@mui/material";

export default styled(Button)`
  background-color: ${colors.base.white};

  &:disabled {
    background-color: ${colors.base.white};
  }

  &:hover {
    background-color: ${colors.grey[100]};
  }
`;
