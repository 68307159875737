import TrackingCallbackImage from "#components/TrackingCallbackImage";
import { WrapperPage } from "#containers/layout/styled";
import React, { ReactNode } from "react";
import DesktopSideNavigation from "#components/pages/Feed/ChannelsSidebar/DesktopChannels";
import colors from "#shared/config/theme/colors";
import { toRem } from "#shared/utils";
import { styled } from "@mui/material";

interface Props {
  children: ReactNode;
}

export default function BusinessLayout({ children }: Props) {
  return (
    <WrapperPage>
      <LayoutWrapper>
        <DesktopSideNavigation isBusinessCentre />
        <ContentWrapper>{children}</ContentWrapper>
      </LayoutWrapper>
      <TrackingCallbackImage />
    </WrapperPage>
  );
}

const LayoutWrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  min-height: inherit;
  max-width: var(--container-width);
  width: 100%;
  box-sizing: border-box;
  margin: 1.25rem 1rem 1rem;
  background: ${colors.base.white};
  border: 0.5px solid ${colors.base.outline1};
  border-radius: ${toRem(10)};
  padding: ${toRem(40)};

  ${theme.breakpoints.down("md")} {
    margin-top: 1rem;
    padding: 1rem;
    margin: 0;
    border: none;
  }
`
);

const ContentWrapper = styled("div")(
  ({ theme }) => `
  box-sizing: border-box;
  margin-left: 2rem;
  width: calc(100% - 300px);

  ${theme.breakpoints.down("md")} {
    margin-left: 0;
    width: 100%;
    padding: 0.725rem 0;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`
);
