import { Theme } from "@mui/material";
import colors from "../../theme/colors";
import effects from "../../theme/effects";
import rounding from "../../theme/rounding";
import { ComponentOverrides } from "../types";

const tooltipOverrides = (theme: Theme): ComponentOverrides<"MuiTooltip"> => ({
  defaultProps: {
    placement: "top",
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      backgroundColor: colors.base.black,
      color: colors.text.tertiaryLight,
      fontSize: theme.typography.label2.fontSize,
      lineHeight: theme.typography.label2.lineHeight,
      padding: "8px 12px",
      borderRadius: rounding.lg,
      boxShadow: effects.shadow.base,
      maxWidth: "256px",
      a: {
        color: colors.text.tertiaryLight,
      },
    },
    arrow: {
      color: colors.base.black,
    },
    popper: {
      "&[data-popper-placement^='top']": {
        marginBottom: `-6px !important`,
      },
      "&[data-popper-placement^='bottom']": {
        marginTop: `-6px !important`,
      },
      "&[data-popper-placement*='-start']": {
        left: `-16px !important`,

        ".MuiTooltip-arrow": {
          left: `16px !important`,
        },
      },
      "&[data-popper-placement*='-end']": {
        right: `-16px !important`,

        ".MuiTooltip-arrow": {
          left: `-16px !important`,
        },
      },
    },
  },
});

export default tooltipOverrides;
