import { ComponentOverrides } from "../types";

const radioOverrides = (): ComponentOverrides<"MuiRadio"> => ({
  styleOverrides: {
    root: {
      padding: 6,
    },
    sizeSmall: {
      svg: {
        width: 24,
        height: 24,
      },
    },
  },
});

export default radioOverrides;
