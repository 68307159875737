import { styled } from "@mui/material/styles";
import CustomModal from "#shared/components/ui/Modal";
import DownloadContainer from "#containers/Download";
import React from "react";
import { ComposedHookDownloadModal } from "../../../../types/hooks";

const Wrapper = styled(CustomModal)`
  &.downloadModalFeed {
    z-index: 1301;
  }
`;

export default function DownloadModal({
  useDownloadModal: { open, onClose },
}: ComposedHookDownloadModal) {
  return (
    <Wrapper
      open={open}
      onClose={onClose}
      Content={<DownloadContainer />}
      className="downloadModalFeed"
    />
  );
}
