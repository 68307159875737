import { ChannelsApi } from "#apis/channels/api";
import { ChannelsQueryKeys } from "#apis/channels/queryKeys";
import { CHANNELS_PAGE_SIZE } from "#constants/channel";
import { GetMyFollowedChannelsResponse } from "#customTypes/channel";
import { AppInfiniteQueryOptions } from "#customTypes/reactQuery";
import { getInfiniteQueryOptions } from "#utils/paginationUtils";
import { useInfiniteQuery } from "@tanstack/react-query";

interface Props {
  options?: AppInfiniteQueryOptions<GetMyFollowedChannelsResponse, string[]>;
}

export default function useGetMyFollowedChannels({ options }: Props = {}) {
  return useInfiniteQuery({
    queryKey: ChannelsQueryKeys.followed,
    queryFn: ({ pageParam }) =>
      ChannelsApi.getFollowedChannels({
        page: pageParam,
        pageSize: CHANNELS_PAGE_SIZE,
      }),
    ...getInfiniteQueryOptions(),
    ...options,
  });
}
