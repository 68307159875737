import { toRem } from "../../utils";

const rounding = {
  none: "0px",
  sm: toRem(2),
  md: toRem(6),
  lg: toRem(8),
  xl: toRem(12),
  "2xl": toRem(16),
  "3xl": toRem(24),
  default: toRem(10),
  full: "9999px",
};

export default rounding;
