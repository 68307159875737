import React, { useEffect } from "react";
import { HeaderSpacer, HeaderWrapper } from "#components/common/layout/Header/styled";
import { styled } from "@mui/material";
import BriefBuilderSteps from "#containers/BriefBuilder/Header/Steps";
import BuilderActions from "#containers/BriefBuilder/Header/Actions";
import { useBriefBuilder } from "#containers/BriefBuilder/BriefBuilderProvider";
import { useBriefBuilderNavigate } from "#containers/BriefBuilder/BriefBuilderNavigateProvider";
import GodViewBanner from "#containers/GodView/GodViewBanner/GodViewBanner";
import useGodView from "#containers/GodView/useGodView";

export default function BriefBuilderHeader() {
  const { hasChanges } = useBriefBuilder();
  const { builderNavigate } = useBriefBuilderNavigate();
  const { isEnabled: isGodViewEnabled } = useGodView();

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  const handleDiscard = () => {
    builderNavigate("/");
  };

  return (
    <>
      <HeaderWrapper className="headerWrapper border">
        <Wrapper>
          <BriefBuilderSteps onDiscard={handleDiscard} />
          <BuilderActions onDiscard={handleDiscard} />
        </Wrapper>
        <GodViewBanner fullWidth />
      </HeaderWrapper>
      <HeaderSpacer />
      {isGodViewEnabled && <GodViewSpacer />}
    </>
  );
}

const Wrapper = styled(HeaderWrapper)`
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 2rem;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-inline: 1rem;
  }
`;

const GodViewSpacer = styled(HeaderSpacer)`
  height: 2.25rem;
`;
