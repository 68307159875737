import React from "react";
import AIChatMessageAvatar from "#components/common/AIChat/components/message/AIChatMessageAvatar";
import AIChatMessageContent from "#components/common/AIChat/components/message/AIChatMessageContent";
import {
  AIChatMessageProps,
  AIChatMessageType,
  AIChatRole,
} from "#components/common/AIChat/types";
import colors from "#shared/config/theme/colors";
import { Box, styled } from "@mui/material";
import classNames from "classnames";
import AIChatChannelList from "#components/common/AIChat/components/actions/AIChatChannelList";
import AIChatConfirmChannelActions from "#components/common/AIChat/components/actions/AIChatConfirmChannelActions";
import AIChatTemplateMessageContent from "#components/common/AIChat/components/message/TemplateMessage";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";

interface Props extends AIChatMessageProps {
  isLastMessage: boolean;
  onClose?: () => void;
}

export default function AIChatMessage({ isLastMessage, onClose, ...props }: Props) {
  const { role, metadata } = props;

  const { isMobile } = useMediaQueryProvider();

  const bottomActions = () => {
    switch (metadata?.type) {
      case AIChatMessageType.ChannelList:
        return <AIChatChannelList disabled={!isLastMessage} />;
      case AIChatMessageType.ConfirmChannel:
        return <AIChatConfirmChannelActions disabled={!isLastMessage} />;
      default:
        return null;
    }
  };

  const content = () => {
    switch (metadata?.type) {
      case AIChatMessageType.BriefTemplate:
        return <AIChatTemplateMessageContent onClose={onClose} {...props} />;
      case AIChatMessageType.Error:
        return <AIChatMessageContent {...props} className="-error-message" />;
      default:
        return <AIChatMessageContent {...props}>{bottomActions()}</AIChatMessageContent>;
    }
  };

  return (
    <Wrapper
      className={classNames("chat-message", role === AIChatRole.User && "user-message")}
    >
      <MessageWrapper className="chat-message-wrapper">
        {!isMobile && <AIChatMessageAvatar {...props} />}
        {content()}
      </MessageWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.25rem;

  &.user-message {
    justify-content: flex-end;

    .chat-message-wrapper {
      flex-direction: row-reverse;
    }

    .chat-message-content {
      background-color: ${colors.brand.default};
      color: ${colors.base.white};
    }
  }
`;

const MessageWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    gap: 0.5rem;
  }
`;
