import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router";

import { WrapperFilter } from "../commonStyled";
import MembersAndBriefsCounts from "#components/pages/Feed/ChannelsSidebar/DesktopChannels/MembersAndBriefsCounts";
import Avatar from "../../../../common/Avatar";
import { ChannelApprovalStatus, ChannelListItem } from "#customTypes/channel";
import { useIntl } from "react-intl";
import { getChannelUrl } from "#components/routing/utils";
import { useGlobalAppProvider } from "#containers/Global/GlobalAppProvider";
import TextEllipsis from "#components/common/Text/TextEllipsis";

type FilterFeed = {
  channels: ChannelListItem[];
  disableActive?: boolean;
  showOrganisationName?: boolean;
  onClickChannel: (channelRef: string) => void;
};

export default function FeedChannelList({
  channels,
  disableActive,
  showOrganisationName,
  onClickChannel,
}: FilterFeed) {
  const intl = useIntl();
  const { pathname } = useLocation();

  const { positions } = useGlobalAppProvider();

  const personalLabel = intl.formatMessage({ id: "pages.feed.filters.personal" });

  const getOrganisationName = (organsiationId: number) =>
    positions?.find((position) => position.organisation.id === organsiationId)
      ?.organisation.name || personalLabel;

  const isChannelSelected = (channelRef: string) => {
    const url = getChannelUrl(channelRef);

    if (!url) {
      return false;
    }

    return pathname.includes(url);
  };

  const handleItemClick = (channelRef: string, isApproved: boolean) => {
    if (!isApproved) {
      return;
    }

    onClickChannel(channelRef);
  };

  return (
    <WrapperFilter>
      {channels.map(
        ({
          id,
          channelRef,
          name,
          logo,
          activeBriefCount,
          approvedStatus,
          organisationId,
          channelsThemes,
        }) => {
          const isSelected = isChannelSelected(channelRef);
          const isApproved = approvedStatus === ChannelApprovalStatus.APPROVED;
          const primaryColor = channelsThemes?.primaryColor;

          return (
            <div
              onClick={() => handleItemClick(channelRef, isApproved)}
              className={classNames("filters private", !isApproved && "requested")}
              key={id}
            >
              <Avatar uri={logo} diameter={2} className="circle" />

              <div>
                <div className="item">
                  <div
                    className={classNames("title", {
                      selected: !disableActive && isSelected,
                    })}
                    style={
                      !disableActive && isSelected && primaryColor
                        ? { color: primaryColor }
                        : undefined
                    }
                  >
                    <span className="channel-name">{name}</span>
                  </div>
                  {showOrganisationName ? (
                    <TextEllipsis variant="label3" sx={{ maxWidth: 200 }}>
                      {getOrganisationName(organisationId)}
                    </TextEllipsis>
                  ) : (
                    <MembersAndBriefsCounts briefsCount={activeBriefCount || 0} />
                  )}
                </div>
              </div>
            </div>
          );
        }
      )}
    </WrapperFilter>
  );
}
