import React, { forwardRef, ReactNode } from "react";
import { Box, InputAdornment, SxProps, Theme, styled } from "@mui/material";
import classNames from "classnames";
import colors from "../../../config/theme/colors";
import rounding from "../../../config/theme/rounding";
import IconSend from "../../../assets/svgs/send.svg?react";
import IconCircleStop from "../../../assets/svgs/circle-stop.svg?react";

import { TextField } from "../TextField";
import AIButton from "../Button/AIButton";

const MIN_LENGTH = 2;
const MAX_LENGTH = 1000;

interface Props {
  prompt: string;
  placeholder?: string;
  loading?: boolean;
  size?: "small" | "medium";
  disabled?: boolean;
  StartAdornment?: ReactNode;
  onChange: (value: string) => void;
  onCancel: () => void;
  onSubmit: (value: string) => void;
  className?: string;
  sx?: SxProps<Theme>;
}

const AIChatInput = forwardRef<HTMLInputElement, Props>(function AIChatInput(
  {
    prompt,
    placeholder,
    loading,
    size = "small",
    disabled,
    sx,
    className,
    StartAdornment,
    onChange,
    onCancel,
    onSubmit,
  },
  ref
) {
  const sendDisabled = !loading && (!prompt || prompt.length < MIN_LENGTH);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleConfirm();
    }
  };

  const handleConfirm = () => {
    if (loading) {
      onCancel();
      return;
    }

    onSubmit(prompt);
  };

  return (
    <Wrapper
      className={classNames(
        "ai-chat-input",
        className,
        `-size-${size}`,
        loading && "-loading"
      )}
      sx={sx}
    >
      <TextField
        inputRef={ref}
        fullWidth
        maxLength={MAX_LENGTH}
        InputProps={{
          startAdornment: StartAdornment ? (
            <InputAdornment position="start">{StartAdornment}</InputAdornment>
          ) : undefined,
        }}
        onKeyDown={handleKeyDown}
        value={prompt}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        multiline
        disabled={loading || disabled}
        sx={{
          ".MuiInputBase-input": {
            maxHeight: "100px",
            overflowY: "auto !important",
          },
        }}
      />

      <SendButtonWrapper className="send-button">
        <SendButton
          variant="contained"
          color="primary"
          disabled={sendDisabled}
          onClick={handleConfirm}
        >
          {!loading ? <IconSend /> : <IconCircleStop />}
        </SendButton>
      </SendButtonWrapper>
    </Wrapper>
  );
});

AIChatInput.displayName = "AIChatInput";

export default AIChatInput;

const Wrapper = styled(Box)`
  position: relative;
  width: 100%;

  --border-width: 2px;
  --background: ${colors.base.white};
  --border-color: ${colors.grey[200]};

  @property --angle {
    syntax: "<angle>";
    inherits: false;
    initial-value: 0deg;
  }

  position: relative;
  padding: var(--border-width);
  border: var(--border-width) transparent;
  border-radius: ${rounding.default};
  background-origin: border-box;
  -webkit-background-clip: content-box, border-box;
  background-clip: content-box, border-box;
  background-image: linear-gradient(var(--background), var(--background)),
    linear-gradient(315deg, var(--border-color) 0%, var(--border-color) 100%);

  &:has(.MuiInputBase-root.Mui-focused) {
    background-image: linear-gradient(var(--background), var(--background)),
      ${colors.gradient.ai.value};
  }

  &.-loading {
    background-image: linear-gradient(var(--background), var(--background)),
      conic-gradient(
        from var(--angle),
        ${colors.gradient.ai.start},
        ${colors.gradient.ai.end},
        ${colors.gradient.ai.start}
      );
    animation: 2.5s spin linear infinite;
  }

  &:has(.MuiInputBase-root.Mui-disabled) {
    --border-color: ${colors.grey[100]};
  }

  @keyframes spin {
    from {
      --angle: 0deg;
    }
    to {
      --angle: 360deg;
    }
  }

  .MuiInputBase-root {
    background: ${colors.base.white};
    background-clip: padding-box;
    font-size: ${({ theme }) => theme.typography.body4.fontSize};
    line-height: ${({ theme }) => theme.typography.body4.lineHeight};
    min-height: 52px;

    .MuiInputBase-input {
      margin-right: 40px;
      border-radius: 0;
      cursor: inherit;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    .MuiInputAdornment-positionStart {
      align-self: flex-start;
      margin-top: 10px;

      svg {
        color: ${colors.brand.default};
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &.Mui-disabled {
      cursor: not-allowed;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    &.-size-medium {
      .MuiInputBase-root {
        min-height: 64px;
        font-size: ${({ theme }) => theme.typography.body3.fontSize};
        line-height: ${({ theme }) => theme.typography.body3.lineHeight};

        .MuiInputBase-input {
          max-height: 200px;
        }

        .MuiInputAdornment-positionStart {
          margin-top: 16px;
          margin-right: 12px;
        }
      }

      .send-button {
        bottom: 14px;
        right: 12px;
      }
    }
  }
`;

const SendButtonWrapper = styled(Box)`
  position: absolute;
  right: 12px;
  bottom: 8px;
  width: 40px;
  height: 40px;
`;

const SendButton = styled(AIButton)`
  width: 40px !important;
  height: 40px !important;
  min-width: 0;

  svg {
    width: 24px;
    height: 24px;
    color: ${colors.base.white};
    flex-shrink: 0;
  }
`;
