import {
  Box,
  LinearProgress,
  SxProps,
  Theme,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import rounding from "../../../config/theme/rounding";
import colors from "../../../config/theme/colors";

interface Props {
  value?: number;
  text?: string[] | string;
  sx?: SxProps<Theme>;
}

const TEXT_SWAP_INTERVAL_SEC = 5;

export default function ProgressBar({ value, text = "", sx }: Props) {
  const textArray = Array.isArray(text) ? text : [text];

  const [currentText, setCurrentText] = useState(textArray[0]);

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      if (!textArray.length) {
        return;
      }
      index = (index + 1) % textArray.length;
      setCurrentText(textArray[index]);
    }, TEXT_SWAP_INTERVAL_SEC * 1000);

    if (textArray.length === 1) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [text]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={4}
      sx={sx}
    >
      <StyledProgress
        variant={value !== undefined ? "determinate" : "indeterminate"}
        color="primary"
        value={value}
      />
      <Typography variant="body4" color="textSecondary" textAlign="center">
        {currentText}
      </Typography>
    </Box>
  );
}

const StyledProgress = styled(LinearProgress)({
  height: 12,
  borderRadius: 5,
  width: "100%",

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: rounding.default,
    background: "linear-gradient(96.03deg, #126AE8 2.62%, #EE8BD0 126.51%)",
  },
});
