import React, { useState } from "react";
import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import { ChannelListItem } from "#customTypes/channel";
import { BaseModalProps, ModalCloseReason } from "#customTypes/modals";
import { Box } from "@mui/material";
import ConfirmDialog from "#shared/components/ui/Dialog/ConfirmDialog";
import useTranslation from "#utils/useTranslation";
import SelectChannelCard from "#components/common/Card/SelectChannelCard";

interface Props extends BaseModalProps {
  onSelectChannel: (channel: ChannelListItem) => void;
}

export default function SelectChannelModal({ isOpen, onSelectChannel, onClose }: Props) {
  const { t } = useTranslation();
  const { approvedChannels } = useMyOwnedChannels();

  const [selected, setSelected] = useState<ChannelListItem | null>(null);

  const handleSelect = (channelId: number) => {
    setSelected(approvedChannels.find((channel) => channel.id === channelId) || null);
  };

  const handleConfirm = () => {
    if (!selected) {
      return;
    }

    setSelected(null);
    onSelectChannel(selected);
  };

  const handleClose = () => {
    setSelected(null);
    onClose(ModalCloseReason.Dismiss);
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={handleClose}
      title={t("pages.brief_builder.modals.select_channel.title")}
      content={null}
      confirmButtonText={t("pages.brief_builder.modals.select_channel.select")}
      confirmButtonDisabled={!selected}
      onConfirm={handleConfirm}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        {approvedChannels.map((channel) => (
          <SelectChannelCard
            key={channel.id}
            channel={{
              id: channel.id,
              name: channel.name,
              organisationName: channel.isVerified ? channel.organisationName : undefined,
              logo: channel.logo,
            }}
            selected={selected?.id}
            setSelected={handleSelect}
          />
        ))}
      </Box>
    </ConfirmDialog>
  );
}
