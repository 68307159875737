import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";
import colors from "../../../theme/colors";

const menuItemOverrides = (theme: Theme): ComponentOverrides<"MuiMenuItem"> => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.body4.fontSize,
      padding: "8px 16px",

      "&:not(:last-child)": {
        borderBottom: `1px solid ${colors.base.outline1}`,
      },

      "&.Mui-selected": {
        color: theme.palette.primary.main,
        backgroundColor: `${theme.palette.primary.ghost} !important`,
      },
    },
  },
});

export default menuItemOverrides;
