import { channelsApi } from "#features/channel/channelsAPI";
import { feedApi } from "#features/feed/feedAPI";
import { organisationsApi } from "#features/organisation/organisationAPI";
import { organisationPositionsApi } from "#features/organisationPositions/organisationPositionsAPI";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { configureAxiosGodViewHeader } from "#apis/axios";
import { AppDispatch } from "src/store";
import { useQueryClient } from "@tanstack/react-query";

export default function useRefetchApp() {
  const dispatch = useDispatch<AppDispatch>();

  const queryClient = useQueryClient();

  const refetchApp = useCallback(async (organisationId?: number) => {
    configureAxiosGodViewHeader(organisationId);
    queryClient.removeQueries();

    await Promise.all([
      dispatch(channelsApi.util.resetApiState()),
      dispatch(organisationPositionsApi.util.resetApiState()), // TODO: refactor this into react query
      dispatch(organisationsApi.util.resetApiState()),
      dispatch(feedApi.util.resetApiState()), // TODO: check if this is needed
      dispatch(
        organisationPositionsApi.endpoints.fetchMyOrganisationPositions.initiate({})
      ),
    ]);
  }, []);

  return { refetchApp };
}
