import { Box, css, darken, styled } from "@mui/material";
import React, { useEffect } from "react";
import {
  ColorPicker as CPicker,
  ColorService,
  IColor,
  useColor,
} from "react-color-palette";
import "react-color-palette/css";
import { alphaColor, toRem } from "../../../utils";
import CustomPopper from "../../../components/ui/Popper";
import useCustomPopper from "../../../components/ui/Popper/useCustomPopper";
import { useDebounceValue } from "usehooks-ts";
import EyedropperIcon from "../../../assets/svgs/eyedropper.svg?react";
import colors from "../../../config/theme/colors";

interface Props {
  color: string;
  setColor: (color: string) => void;
}

export default function ColorPicker({ color, setColor }: Props) {
  const [anchorRef, open, setOpen] = useCustomPopper<HTMLButtonElement>();

  const [internalColor, setInternalColor] = useColor(color);
  const [debouncedColor] = useDebounceValue(internalColor, 500);

  useEffect(() => {
    setInternalColor(ColorService.convert("hex", color));
  }, [color]);

  useEffect(() => {
    setColor(debouncedColor.hex);
  }, [debouncedColor]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [open]);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeColor = (color: IColor) => {
    setInternalColor(color);
  };

  return (
    <Box>
      <ColorPickerButton
        ref={anchorRef}
        color={internalColor.hex}
        onClick={handleOpenDialog}
      >
        <Icon />
      </ColorPickerButton>
      <CustomPopper
        anchorEl={anchorRef.current}
        open={open}
        onClickAway={handleCloseDialog}
        placement="top-end"
        modifiers={[
          {
            name: "flip",
            enabled: false,
          },
        ]}
      >
        <Wrapper>
          <CPicker
            color={internalColor}
            onChange={handleChangeColor}
            hideInput={["rgb", "hsv"]}
            hideAlpha
          />
        </Wrapper>
      </CustomPopper>
    </Box>
  );
}

const Wrapper = styled("div")`
  .rcp-root {
    padding: 10px;
    background: #fff;
    width: 200px;
  }

  .rcp-interactive {
    margin: 0.275rem 0;

    .rcp-saturation {
      border-radius: ${toRem(4)};

      &-cursor {
        border: 1px solid white;
        width: 10px;
        height: 10px;
        box-shadow: 0px 0px 5px 0px #000000b2;
      }
    }
  }

  .rcp-body {
    padding: 0;
    gap: 0.5rem;

    .rcp-hue {
      height: 10px;

      &-cursor {
        border: 1px solid white;
        width: 10px;
        height: 10px;
        box-shadow: 0px 0px 5px 0px #000000b2;
        transform: translate(-10px, -1px);
      }
    }

    .rcp-field {
      display: flex;
      flex-direction: column-reverse;

      &-label {
        text-align: left;
        margin-bottom: ${toRem(2)};
        font-size: ${toRem(10)};
        color: ${colors.base.black};
      }

      &-input {
        color: ${colors.base.black};
        background-color: ${colors.input.background};
        border: 1px solid ${colors.input.border};
        transition: background-color 0.2s ease-in-out;
        height: ${toRem(28)};

        &:hover,
        &:focus {
          background-color: ${colors.input.backgroundFocused};
        }
      }
    }
  }
`;

const Icon = styled(EyedropperIcon)`
  position: relative;
  z-index: 1;
`;

const ColorPickerButton = styled("button")<{ color: string }>(({ color }) => {
  const isWhite = color === "#ffffff";
  const borderColor = isWhite ? "transparent" : alphaColor(color, 0.2);
  const borderHover = isWhite ? "transparent" : darken(borderColor, 0.2);

  return css`
    position: relative;
    width: 2rem;
    height: 2rem;
    border: 2px solid ${borderColor};
    border-radius: 50%;
    color: ${colors.grey[100]};
    transition: border-color 0.2s ease-in-out;

    svg {
      width: 0.75rem;
      height: 100%;
      opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: ${color};
      border-radius: 50%;
      top: ${toRem(2)};
      left: ${toRem(2)};

      ${isWhite &&
      css`
        border: 1px solid ${colors.base.outline1};
      `}
    }

    &:hover {
      border-color: ${borderHover} !important;

      svg {
        opacity: 1;
      }

      &::before {
        background-color: ${darken(color, 0.15)} !important;
      }
    }
  `;
});
