import { Box, styled } from "@mui/material";
import colors from "../../../config/theme/colors";
import rounding from "../../../config/theme/rounding";

const Card = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${colors.base.outline1};
  padding: 1rem 1.5rem;
  
  background-color: ${colors.base.white};
	border-radius: ${rounding.default};
	text-align: left;
	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

	&:hover {
		background-color: ${colors.grey[50]};
	}

	${theme.breakpoints.down("sm")} {
		padding: 0.75rem;
	}
`
);

export default Card;
