import React, { ReactNode } from "react";
import { Box, IconButton, SxProps, Theme, Typography, styled } from "@mui/material";

import CloseIcon from "#shared/assets/svgs/close-dialog.svg?react";
import ChevronLeftIcon from "#shared/assets/svgs/chevron-left.svg?react";
import { toRem } from "../../../utils";
import colors from "../../../config/theme/colors";

interface Props {
  title?: string;
  inline?: boolean;
  children?: ReactNode;
  Actions?: ReactNode;
  className?: string;
  sx?: SxProps<Theme>;
  onBack?: () => void;
  onClose?: () => void;
}

export default function ModalHeader({
  title,
  inline,
  className,
  children,
  Actions,
  sx,
  onBack,
  onClose,
}: Props) {
  return (
    <Wrapper className={className} sx={sx}>
      <Box display="flex" alignItems="center" gap={2}>
        {onBack && (
          <ActionButton size="medium" onClick={onBack}>
            <ChevronLeftIcon />
          </ActionButton>
        )}
        <Box display="flex" flexDirection={inline ? "row" : "column"} sx={{ pr: 4 }}>
          <Typography variant="h5" fontWeight="500" pr={2}>
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
      <Box className="modal-actions" display="flex" alignItems="center">
        {Actions}
        {onClose && (
          <CloseButton color="secondary" size="medium" onClick={onClose} disableRipple>
            <CloseIcon />
          </CloseButton>
        )}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--modal-padding, ${toRem(20)});
  border-bottom: 1px solid #dfdfdf;
  box-sizing: border-box;
  background: var(--modal-background, ${colors.base.white});

  .modal-actions {
    .MuiIconButton-root {
      margin-block: -0.5rem;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-block: 0.75rem;
  }
`;

const ActionButton = styled(IconButton)`
  color: ${colors.base.black};
  background: transparent;
  height: fit-content;
  margin-left: -0.5rem;

  & > svg {
    flex-shrink: 0;
  }
`;

const CloseButton = styled(IconButton)`
  background: transparent;
  padding: 0.375rem;

  & > svg {
    flex-shrink: 0;
  }
`;
