import Avatar from "#components/common/Avatar";
import useChannelActions from "#components/pages/ChannelDetails/ChannelHeader/useChannelActions";
import { ChannelListItem } from "#customTypes/channel";
import { Box, Typography, styled } from "@mui/material";
import { LoadingButton } from "#shared/components/ui";
import colors from "#shared/config/theme/colors";
import rounding from "#shared/config/theme/rounding";
import { nFormatter } from "#utils/index";
import React from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  channel: ChannelListItem;
  width?: number | string;
  joined?: boolean;
  onClick: (channelRef: string) => void;
}

export default function ChannelBox({ channel, width, joined = false, onClick }: Props) {
  const { channelRef, logo, name, description } = channel;

  const { joinChannel, isLoadingJoin } = useChannelActions();

  const handleJoinClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    channel: ChannelListItem
  ) => {
    event.stopPropagation();

    if (joined) {
      onClick(channel.channelRef);
      return;
    }

    joinChannel(channel);
  };

  return (
    <ItemWrapper
      role="button"
      width={width}
      tabIndex={0}
      onClick={() => onClick(channelRef)}
    >
      <ItemHeader>
        <Box display="flex" flex="1" pr={4}>
          <Avatar uri={logo} diameter={2} className="circle" />
          <Box ml={2}>
            <ItemName variant="body4" fontFamily="Avenir-heavy">
              {name}
            </ItemName>
            <Typography variant="label3" color={colors.text.secondaryDark}>
              {nFormatter(channel.membersCount || 0)}{" "}
              <FormattedMessage
                id={`pages.feed.channel.member${channel.membersCount === 1 ? "" : "s"}`}
              />
            </Typography>
          </Box>
        </Box>
        <ItemActions>
          <LoadingButton
            variant="cta"
            size="extraSmall"
            loading={isLoadingJoin}
            disabled={joined}
            onClick={(e) => handleJoinClick(e, channel)}
            sx={{ textTransform: "uppercase", p: 0, height: "auto", minWidth: 0 }}
          >
            {joined ? (
              <FormattedMessage id="ui.channel_header.actions.joined" />
            ) : (
              <FormattedMessage id="ui.channel_header.actions.join" />
            )}
          </LoadingButton>
        </ItemActions>
      </ItemHeader>
      <Description variant="label3">{description}</Description>
    </ItemWrapper>
  );
}

const ItemWrapper = styled("div")<{ width?: number | string }>(
  ({ width }) => `
	position: relative;
  box-sizing: border-box;
  width: ${width ? `${width}px` : width};
  padding: 1rem;
  scroll-snap-align: start;
  background: ${colors.grey[50]};
  border-radius: ${rounding.default};
  cursor: pointer;
  min-height: 96px;
`
);

const ItemHeader = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const ItemActions = styled("div")`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
`;

const ItemName = styled(Typography)(
  ({ theme }) => `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 136px;

  ${theme.breakpoints.down("md")} {
		max-width: 170px;
	}
`
);

const Description = styled(Typography)`
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
`;
