import { Theme } from "@mui/material";
import { ComponentOverrides } from "../../types";

const formControlLabelOverrides = (
  theme: Theme
): ComponentOverrides<"MuiFormControlLabel"> => ({
  styleOverrides: {
    label: {
      fontSize: theme.typography.label3.fontSize,
      lineHeight: theme.typography.label3.lineHeight,
      display: "flex",
      alignItems: "center",
      gap: "4px",
      userSelect: "none",

      svg: {
        width: "1rem",
        height: "1rem",
      },
    },
  },
});

export default formControlLabelOverrides;
