import { Theme } from "@mui/material";
import colors from "../../../theme/colors";
import { ComponentOverrides } from "../../types";
import { alphaColor } from "../../../../utils";

const iconButtonOverrides = (theme: Theme): ComponentOverrides<"MuiIconButton"> => ({
  styleOverrides: {
    root: {
      transition: "background-color 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: colors.grey[100],
      },
    },
    sizeSmall: {
      padding: "0.5rem",
      svg: {
        width: "1rem",
        height: "1rem",
      },
    },
    sizeMedium: {
      padding: "0.5rem",
      svg: {
        width: "1.5rem",
        height: "1.5rem",
      },
    },
    sizeLarge: {
      padding: "0.875rem",
      svg: {
        width: "2rem",
        height: "2rem",
      },
    },
    colorPrimary: {
      color: colors.grey[900],

      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.ghost,
      },
    },
  },
  variants: [
    {
      props: {
        color: "white",
      },
      style: {
        color: colors.base.white,

        "&:hover": {
          backgroundColor: alphaColor(colors.base.black, 0.15),
        },
      },
    },
    {
      props: {
        size: "extraSmall",
      },
      style: {
        padding: "0.25rem",
        svg: {
          width: "0.75rem",
          height: "0.75rem",
        },
      },
    },
    {
      props: {
        color: "blured",
      },
      style: {
        backgroundColor: alphaColor(colors.base.black, 0.2),
        color: colors.base.white,
        backdropFilter: "blur(5px)",

        "&:hover": {
          backgroundColor: alphaColor(colors.base.black, 0.25),
        },
      },
    },
    {
      props: {
        color: "tertiary",
      },
      style: {
        backgroundColor: colors.base.surface1,
        color: colors.grey[900],

        "&:hover": {
          backgroundColor: colors.base.surface1,
        },
      },
    },
  ],
});

export default iconButtonOverrides;
