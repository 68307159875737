/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MODAL_DEFAULT_Z_INDEX } from "./modalConstants";

interface ActiveModalProps {
  id: string;
  zIndex: number;
}

interface ContextProps {
  openModal: (id: string) => number;
  closeModal: (id: string) => void;
}

const ModalContext = createContext<ContextProps>({
  openModal: () => {
    return 0;
  },
  closeModal: () => {},
});

interface ProviderProps {
  children: ReactNode;
}

export function ModalProvider({ children }: ProviderProps) {
  const [openModals, setOpenModals] = useState<ActiveModalProps[]>([]);

  useEffect(() => {
    if (openModals.length > 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [openModals]);

  const openModal = useCallback(
    (id: string): number => {
      const zIndex = openModals.length
        ? openModals[openModals.length - 1].zIndex + 1
        : MODAL_DEFAULT_Z_INDEX;

      setOpenModals((prev) => [...prev, { id, zIndex }]);
      return zIndex;
    },
    [openModals]
  );

  const closeModal = useCallback((id: string) => {
    setOpenModals((prev) => prev.filter((modal) => modal.id !== id));
  }, []);

  const providerValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );

  return <ModalContext.Provider value={providerValue}>{children}</ModalContext.Provider>;
}

export function useModalProvider(): ContextProps {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalProvider must be used within a ModalProvider");
  }
  return context;
}
