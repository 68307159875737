import { Box, SxProps, Theme, Typography, styled } from "@mui/material";
import colors from "#shared/config/theme/colors";
import { toRem } from "#shared/utils";
import React from "react";

interface Props {
  icon: React.ReactNode;
  title: string;
  color?: "primary" | "secondary" | "dark";
  description?: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

export default function EmptyState({
  icon,
  color = "secondary",
  title,
  description,
  sx,
  children,
}: Props) {
  const iconColor = () => {
    switch (color) {
      case "primary":
        return "primary";
      default:
        return colors.text.tertiaryDark;
    }
  };

  const textColor = () => {
    switch (color) {
      case "primary":
        return colors.text.secondaryDark;
      case "dark":
        return colors.text.primaryDark;
      default:
        return colors.text.tertiaryDark;
    }
  };

  return (
    <Wrapper sx={sx}>
      <IconWrapper color={iconColor()} fontSize={toRem(40)}>
        {icon}
      </IconWrapper>
      <Typography
        variant="label1"
        color={textColor()}
        textAlign="center"
        fontFamily="Avenir-heavy"
        mb={2}
      >
        {title}
      </Typography>
      <Typography variant="label2" color={textColor()} textAlign="center">
        {description}
      </Typography>

      {children && (
        <Box display="flex" justifyContent="center" width="100%" mt={8}>
          {children}
        </Box>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  margin: 0 auto;
`;

const IconWrapper = styled(Typography)`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  svg {
    width: ${toRem(32)};
    height: ${toRem(32)};
  }
`;
