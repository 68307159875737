import { styled } from "@mui/material/styles";
import { LoginWrapperBase } from "../../../../common/styled";
import { Link as RouterLink } from "react-router-dom";

export const Wrapper = styled(LoginWrapperBase)`
  text-align: center;
`;

export const Link = styled(RouterLink)(
  ({ theme }) => `
font-family: "Avenir-Heavy";
font-size: 0.75rem;
font-weight: 800;
color: ${theme.palette.primary.main};
text-decoration: underline;

&:hover {
  opacity: 0.75;
  transition: 0.25s all;
}
`
);

export const CheckYourEmail = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.125rem;
  font-family: Avenir-heavy;
  font-size: 0.9375rem;
  border-radius: 0.625rem;
  background: #f4f3f3;
`;
