import { Theme } from "@mui/material";
import colors from "../../../theme/colors";
import { ComponentOverrides } from "../../types";

const tabsOverrides = (theme: Theme): ComponentOverrides<"MuiTabs"> => ({
  styleOverrides: {
    root: {
      minHeight: "40px",
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: "1px",
      marginTop: "30px",
    },
    scroller: {
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1px",
        background: colors.base.outline1,
      },
    },
  },
});

export default tabsOverrides;
