import useBriefBuilderSteps from "#containers/BriefBuilder/hooks/useBriefBuilderSteps";
import { BriefBuilderStep } from "#customTypes/briefBuilder";
import { Steps } from "#shared/components/ui";
import React from "react";

interface Props {
  onStepClick: (step: BriefBuilderStep) => void;
}

export default function BuilderDesktopSteps({ onStepClick }: Props) {
  const { steps } = useBriefBuilderSteps();

  return (
    <Steps steps={steps} onClick={(step) => onStepClick(step.id as BriefBuilderStep)} />
  );
}
