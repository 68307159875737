import React from "react";
import { StyledTabComponent, StyledTabsComponent } from "./styled";

type TabIconPosition = "bottom" | "end" | "start" | "top";

export type TabsLabel = {
  id: string;
  label: string;
  icon?: string | React.ReactElement;
  iconPosition?: TabIconPosition;
  disabled?: boolean;
  unselectable?: boolean; // not disabled but styled as such and can't be selected
  style?: React.CSSProperties;
};

type CustomTabsType = {
  tabLabels: TabsLabel[];
  selectedTab?: number;
  onChange: (selectedTabId: string) => void;
};

export default function CustomTabs({
  tabLabels,
  onChange,
  selectedTab = 0,
}: CustomTabsType) {
  const handleTabChange = (_event: React.SyntheticEvent, tabIndex: number) => {
    onChange(tabLabels[tabIndex].id);
  };

  return (
    <StyledTabsComponent value={selectedTab} onChange={handleTabChange}>
      {tabLabels.map((tab) => (
        <StyledTabComponent
          style={tab.style}
          key={tab.id}
          label={tab.label}
          icon={tab.icon}
          iconPosition={tab.iconPosition}
          disabled={tab.disabled}
          nonselectable={!!tab.unselectable}
          disableRipple
        />
      ))}
    </StyledTabsComponent>
  );
}
