import React, { ReactNode } from "react";
import { Box, styled } from "@mui/material";
import classNames from "classnames";
import { AIChatRole } from "../../../types/aiChat";
import colors from "../../../config/theme/colors";

interface Props {
  role: AIChatRole;
  error?: boolean;
  children: ReactNode;
}

export default function AIChatMessageWrapper({ role, error, children }: Props) {
  return (
    <Wrapper
      className={classNames(
        "ai-chat-message",
        error && "-error-message",
        `${role}-message`
      )}
    >
      <MessageWrapper className="ai-chat-message-wrapper">{children}</MessageWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;

  &.user-message {
    justify-content: flex-end;

    .ai-chat-message-wrapper {
      flex-direction: row-reverse;
    }

    .ai-chat-message-content-wrapper {
      background-color: ${colors.brand.default};
      color: ${colors.base.white};
    }
  }
`;

const MessageWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  width: 100%;

  .userAvatar {
    border-radius: 50%;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    gap: 0.5rem;
  }
`;
