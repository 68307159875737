import React, { useMemo } from "react";

// components
import ProfileImage from "./ProfileImage";

// constants
import useNavigation from "#containers/layout/Navigation/hooks/useNavigation";
import { profileNavigationItems } from "#containers/layout/Navigation/items";
import useNavigationAction from "#containers/layout/Navigation/hooks/useNavigationAction";

import { GaEvents, gaTrackEvent } from "#utils/useGA";
import { MenuItemProps } from "#customTypes/menu";
import MenuDropdown from "#components/common/Dropdown/MenuDropdown";
import { NavigationAction } from "#constants/navigation";

export default function ProfileMenu() {
  const { items } = useNavigation(profileNavigationItems);
  const { navigateAction } = useNavigationAction();

  const menuItems: MenuItemProps[] = useMemo(
    () =>
      items.map((item) => ({
        id: item.id,
        label: item.name,
        Icon: item.LogoInactive,
        EndIcon: item.ActionIcon,
        divided: item.id === NavigationAction.logout,
        color: item.id === NavigationAction.logout ? "error" : "default",
        onClick: () => navigateAction(item.id),
      })),
    [items, navigateAction]
  );

  const trackOpenMenu = () => {
    gaTrackEvent(GaEvents.openmyprofile, "", "");
  };

  return (
    <MenuDropdown
      items={menuItems}
      AnchorElement={<ProfileImage />}
      onMenuOpen={trackOpenMenu}
    />
  );
}
