import React from "react";
import useAIChatInput from "#components/common/AIChat/hooks/useAIChatInput";
import { Box } from "@mui/material";
import AIChatButton from "#components/common/AIChat/components/AIChatButton";
import useTranslation from "#utils/useTranslation";

interface Props {
  disabled: boolean;
}

export default function AIChatConfirmChannelActions({ disabled }: Props) {
  const { t } = useTranslation();
  const { channel, handleSelectChannel, handleChangeChannel } = useAIChatInput();

  const handleChange = () => {
    handleChangeChannel();
  };

  const handleConfirm = () => {
    if (!channel) {
      return;
    }

    handleSelectChannel(channel.channelRef, channel.channelName || "", true);
  };

  return (
    <Box display="flex" gap={2}>
      <AIChatButton size="small" disabled={disabled} onClick={handleChange}>
        {t("ui.ai_chat.buttons.no_channel")}
      </AIChatButton>
      <AIChatButton size="small" disabled={disabled} onClick={handleConfirm}>
        {t("ui.ai_chat.buttons.yes_channel")}
      </AIChatButton>
    </Box>
  );
}
