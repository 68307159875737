import composeHooks from "#shared/utils/hooks/composeHooks";
import { ComposedHookSubmissionProfileForm } from "../../types/hooks";
import useSubmissionProfileForm from "#containers/ScreenerSuccess/SubmissionProfileForm/useSubmissionProfileForm";
import SubmissionProfileForm from "#components/pages/ScreenerSuccess/SubmissionProfileForm";
import { HTTP_STATUS } from "#constants/common";
import updateProfile from "#apis/user/updateProfile";

import { updateProfileDetails } from "../../features/user/userSlice";

export default composeHooks<ComposedHookSubmissionProfileForm>({
  useSubmissionProfileForm: () =>
    useSubmissionProfileForm({
      onSubmitProfileForm: async ({
        profile,
        dispatch,
        fingerprintId,
        navigate,
        urlSearchParams,
      }) => {
        const redirect = urlSearchParams.get("redirect") || "/";
        const response = await updateProfile({
          ...profile,
          fingerprintId,
        });

        if (response && "status" in response && response.status === HTTP_STATUS.OK) {
          dispatch(updateProfileDetails(profile));

          navigate(redirect);
        }
      },
    }),
})(SubmissionProfileForm);
