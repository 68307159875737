import React from "react";
import Select, { SelectProps } from "@mui/material/Select";
import { FormHelperText, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControl, { FormControlProps } from "@mui/material/FormControl";

import RenderInput from "./RenderInput";
import { SelectOption } from "../../../types";
import InputLabel from "../InputLabel";

const StyledSelect = styled(Select)<SelectProps>(() => ({
  "&& fieldset": {
    border: "none",
  },
}));

type CustomDropdownInput = {
  id?: string;
  selectedValue: string | string[] | undefined;
  items: SelectOption[];
  errorMessage?: string | undefined;
  label?: string;
  formProps?: FormControlProps;
  onDeleteOption?: (value: string) => void;
} & SelectProps;

export default function CustomDropdown(props: CustomDropdownInput) {
  const { formProps, label, ...rest } = props;
  return (
    <FormControl {...formProps}>
      <InputLabel shrink htmlFor={props.id}>
        {label}
      </InputLabel>
      <SelectComponent {...rest} />
    </FormControl>
  );
}

const SelectComponent = (props: Omit<CustomDropdownInput, "formProps" | "label">) => {
  const {
    id,
    selectedValue,
    items,
    error,
    errorMessage,
    onDeleteOption,
    renderValue,
    ...selectProps
  } = props;

  return (
    <>
      <StyledSelect
        {...selectProps}
        displayEmpty
        renderValue={() =>
          renderValue ? (
            renderValue(selectedValue)
          ) : (
            <RenderInput
              multiple={selectProps.multiple}
              selectedValue={selectedValue}
              items={items}
              placeholder={selectProps.placeholder}
              onDeleteOption={onDeleteOption}
            />
          )
        }
        id={id}
        fullWidth
        value={selectedValue}
      >
        {items.map(({ value, label }, index) => (
          <MenuItem key={`select_${label}_${index}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
      {error && errorMessage && (
        <FormHelperText className="muiSelect" error={true}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};
