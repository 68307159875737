import { FormFields } from "#shared/types/form";
import useForm from "#shared/utils/hooks/useForm";
import { useIntl } from "react-intl";

export enum CreateChannelFormField {
  organisationId = "organisationId",
  banner = "banner",
  logo = "logo",
  name = "name",
  description = "description",
  interests = "interests",
}

export interface CreateChannelFormType extends FormFields {
  organisationId: number;
  banner: string;
  logo: string;
  name: string;
  description: string;
  interests: number[];
}

export default function useCreateChannelForm(defaultValues: CreateChannelFormType) {
  const intl = useIntl();

  const validateFn = (fieldName: keyof CreateChannelFormType, value: any) => {
    let error = "";

    if (fieldName === CreateChannelFormField.name && !value) {
      error = intl.formatMessage({ id: "ui.modals.create_channel.errors.name_required" });
    }

    if (fieldName === CreateChannelFormField.interests && !value.length) {
      error = intl.formatMessage({
        id: "ui.modals.create_channel.errors.empty_categories",
      });
    }

    return error;
  };

  return useForm<CreateChannelFormType>(defaultValues, {
    validateOnChange: true,
    validateFn,
  });
}
