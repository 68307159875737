import { ComponentOverrides } from "../../types";
import colors from "../../../theme/colors";
import rounding from "../../../theme/rounding";

const accordionOverrides = (): ComponentOverrides<"MuiAccordion"> => ({
  styleOverrides: {
    root: {
      boxShadow: "none",
      border: `1px solid ${colors.input.border}`,
      borderRadius: `${rounding.default} !important`,
      overflow: "hidden",
      margin: "1rem 0",

      "&::before": {
        opacity: 0,
      },
    },
  },
});

export default accordionOverrides;
