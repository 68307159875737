import useGodView from "#containers/GodView/useGodView";
import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import IconEye from "#assets/svgs/eye.svg?react";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import useTranslation from "#utils/useTranslation";
import IconArrowDropDown from "#assets/svgs/arrow_drop_down.svg?react";
import classNames from "classnames";
import { useLocation } from "react-router";
import colors from "#shared/config/theme/colors";
import useWlaOrganisation from "#utils/useWlaOrganisation";

interface Props {
  fullWidth?: boolean;
}

export default function GodViewBanner({ fullWidth }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isTabletOrMobile } = useMediaQueryProvider();
  const { isEnabled, organisation, openModal, exitGodView } = useGodView();
  const { isWla } = useWlaOrganisation();

  const viewMode = pathname.startsWith("/brief-builder");

  const handleOrganisationClick = () => {
    if (isWla) {
      return;
    }

    openModal();
  };

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <Banner>
        <ContentWrapper className={classNames(fullWidth && "-fullwidth")}>
          <Box display="flex" alignItems="center" flex="1">
            <IconEye />

            <Typography display="flex" alignItems="center" gap={1} variant="label2">
              {t(
                isTabletOrMobile
                  ? "ui.god_view.banner.title_short"
                  : "ui.god_view.banner.title",
                {
                  button: (val) => (
                    <StyledButton
                      variant="text"
                      color="inherit"
                      disabled={viewMode}
                      disableRipple
                      onClick={handleOrganisationClick}
                      sx={{ flex: 1, px: 0.5 }}
                    >
                      <ViewingAs variant="inherit">{val}</ViewingAs>
                      {!viewMode && !isWla && <IconArrowDropDown />}
                    </StyledButton>
                  ),
                  organisationName: organisation?.name,
                }
              )}
            </Typography>
          </Box>
          {!viewMode && (
            <StyledButton
              onClick={exitGodView}
              variant="text"
              color="inherit"
              disableRipple
            >
              {t("ui.buttons.exit")}
            </StyledButton>
          )}
        </ContentWrapper>
      </Banner>
      <ContentSpacer />
    </>
  );
}

const Banner = styled(Box)`
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  height: 40px;
  text-align: center;
  z-index: 100;

  svg {
    margin-right: 0.5rem;
  }
`;

const ContentWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--container-width);

  &.-fullwidth {
    max-width: none;
    padding: 0 2rem;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding: 0 1.25rem;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: calc(100% - 2rem);
  }
`;

const ContentSpacer = styled(Box)`
  height: 40px;
`;

const StyledButton = styled(Button)`
  &:hover {
    background-color: transparent;
  }

  &:disabled {
    color: ${colors.base.white};
  }

  svg {
    margin-right: 0rem;
  }
`;

const ViewingAs = styled(Typography)`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 500px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: calc(100vw - 210px);
  }
`;
