import env from "#env";
import { ProfileDetails } from "../../types/db";

export default async function fetchUserByFingerprint(
  fingerprintId: string
): Promise<ProfileDetails | undefined> {
  try {
    const response = await fetch(
      `${env.VITE_API_BASE_URL}/community/users/fingerprint/${fingerprintId}`
    );

    if (response.headers.get("content-type") !== null) {
      const user = await response.json();
      return user;
    }
  } catch (e) {
    console.error(e);
  }
}
