import { styled } from "@mui/material/styles";

const LoginFootnoteWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  font-size: 0.75rem;
  text-align: center;
  max-width: 24rem;

  a {
    color: #000;
  }
`;

export default LoginFootnoteWrapper;
