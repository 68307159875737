import React from "react";
import Button from "@mui/material/Button";
import { styled as MuiStyled } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button";

const StyledButton = MuiStyled(Button)<CustomButtonProps>(
  ({ width, height = "3.125rem", background }) => `
  &.MuiButton-root {
    text-transform: none;
    line-height: 1.563rem;
    height: ${height};
    box-shadow: none;
    width: ${width ? width : "100%"};
    background: ${background};
    font-family: Avenir;
    &:hover {
      transition: 0.3s all;
      opacity: 0.85;
    }
  }
`
);

export type CustomButtonProps = {
  width?: string;
  height?: string;
  background?: string;
} & ButtonProps;

export default function CustomButton(props: CustomButtonProps) {
  return <StyledButton variant="contained" {...props} />;
}
