import ConfirmDialog from "#shared/components/ui/Dialog/ConfirmDialog";
import { useBriefBuilder } from "#containers/BriefBuilder/BriefBuilderProvider";
import useBriefBuilderActions from "#containers/BriefBuilder/hooks/useBriefBuilderActions";
import { BriefBuilderStep } from "#customTypes/briefBuilder";
import useTranslation from "#utils/useTranslation";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import { NavigateOptions, To, useNavigate } from "react-router";

interface BuilderNavigateContextProps {
  builderNavigate: (to: To, options?: NavigateOptions) => void;
}

const BuilderNavigateContext = createContext<BuilderNavigateContextProps | undefined>(
  undefined
);

interface Props {
  children: ReactNode;
}

export function BriefBuilderNavigateProvider({ children }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeStep, hasChanges } = useBriefBuilder();
  const { handleSave, isLoading } = useBriefBuilderActions();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [navigateTo, setNavigateTo] = useState<To | undefined>(undefined);
  const [navigateOptions, setNavigateOptions] = useState<NavigateOptions | undefined>(
    undefined
  );

  const builderNavigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      if (!hasChanges || activeStep === BriefBuilderStep.Share) {
        navigate(to, options);
        return;
      }

      setNavigateTo(to);
      setNavigateOptions(options);
      setIsModalOpen(true);
    },
    [hasChanges, activeStep, navigate]
  );

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = async (save: boolean) => {
    if (!navigateTo) {
      throw new Error("No navigation target set");
    }

    if (save) {
      await handleSave();
    }

    handleClose();
    navigate(navigateTo, navigateOptions);
  };

  const value = useMemo(() => ({ builderNavigate }), [builderNavigate]);

  return (
    <BuilderNavigateContext.Provider value={value}>
      {children}
      <ConfirmDialog
        isOpen={isModalOpen}
        onClose={handleClose}
        title={t(
          "pages.brief_builder.steps.launch.notification.modals.unsaved_changes.title"
        )}
        content={t(
          "pages.brief_builder.steps.launch.notification.modals.unsaved_changes.content"
        )}
        confirmButtonText={t("ui.buttons.save")}
        cancelButtonText={t("ui.buttons.discard")}
        disableDismiss
        isLoading={isLoading}
        onCancel={() => handleConfirm(false)}
        onConfirm={() => handleConfirm(true)}
      />
    </BuilderNavigateContext.Provider>
  );
}

// Custom hook to use the PromptContext
export const useBriefBuilderNavigate = () => {
  const context = useContext(BuilderNavigateContext);
  if (context === undefined) {
    throw new Error(
      "useBriefBuilderNavigate must be used within a BuilderNavigateProvider"
    );
  }
  return context;
};
