import { FileDropzonePlaceholderWrapper } from "#components/pages/BusinessLab/styled";
import React from "react";
import IconUpload from "#assets/svgs/upload-solid.svg?react";
import { Typography } from "@mui/material";

export default function EditorImagePlaceholder() {
  return (
    <FileDropzonePlaceholderWrapper>
      <IconUpload />
      <Typography
        variant="body4"
        fontWeight="500"
        mt={2}
        mb={0}
        component="span"
        sx={{ userSelect: "none" }}
      >
        Drag and drop or click to upload
      </Typography>
      <Typography
        variant="label3"
        color="text.secondary"
        textAlign="center"
        component="span"
        sx={{ userSelect: "none" }}
      >
        JPG or PNG; less than 5MB
      </Typography>
    </FileDropzonePlaceholderWrapper>
  );
}
