import { Button, useTheme } from "@mui/material";
import { TextField } from "#shared/components/ui";
import colors from "#shared/config/theme/colors";
import React from "react";
import IconCopy from "#assets/svgs/copy.svg?react";
import useCopyToClipboard from "#utils/useCopyToClipboard";

interface Props {
  url: string;
}

export default function ShareField({ url }: Props) {
  const copyToClipboard = useCopyToClipboard();
  const theme = useTheme();

  return (
    <TextField
      size="small"
      value={url}
      disabled
      autoFocus={false}
      InputProps={{
        endAdornment: (
          <Button
            variant="cta"
            sx={{
              ml: 2,
              width: 24,
              height: 24,
              padding: "3px",
              borderRadius: 1,
              color: colors.grey[300],
              "&:hover": {
                background: `${colors.grey[100]} !important`,
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => copyToClipboard(url)}
          >
            <IconCopy />
          </Button>
        ),
      }}
    />
  );
}
