import React from "react";

const QuestionMarkIcon = ({ background }: { background: string }) => (
  <svg
    width="2.125rem"
    height="2.125rem"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888
             0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM17.4583 20.1092C17.7361 19.8678
             17.875 19.5728 17.875 19.2241V18.2586C17.875 17.91 18.0278 17.5881 18.3333 17.2931C18.6667
             16.9713 19.0556 16.6494 19.5 16.3276C20.1389 15.8716 20.7083 15.3352 21.2083 14.7184C21.7361
             14.1015 22 13.2299 22 12.1034C22 10.8966 21.5139 9.91762 20.5417 9.16667C19.5694 8.38889
             18.3472 8 16.875 8C15.9861 8 15.1667 8.17433 14.4167 8.52299C13.6944 8.87165 13.1111
             9.341 12.6667 9.93104C12.2222 10.4943 12 11.1245 12 11.8218C12 12.1705 12.1389 12.4789
             12.4167 12.7471C12.7222 13.0153 13.0556 13.1494 13.4167 13.1494C13.7778 13.1494 14.0833
             13.0421 14.3333 12.8276C14.6111 12.5862 14.7639 12.318 14.7917 12.023C14.8472 11.4866
             15.0556 11.0709 15.4167 10.7759C15.7778 10.4808 16.2639 10.3333 16.875 10.3333C17.5972
             10.3333 18.1667 10.5211 18.5833 10.8966C19.0278 11.2452 19.25 11.7146 19.25 12.3046C19.25
             12.9215 19.0417 13.4579 18.625 13.9138C18.2361 14.3429 17.8056 14.7318 17.3333 15.0805C16.8333
             15.4559 16.3333 15.8851 15.8333 16.3678C15.3611 16.8506 15.125 17.4808 15.125 18.2586V19.2241C15.125
             19.5728 15.25 19.8678 15.5 20.1092C15.7778 20.3506 16.1111 20.4713 16.5 20.4713C16.8889 20.4713
             17.2083 20.3506 17.4583 20.1092ZM15.4583 24.977C15.8472 25.3257 16.3056 25.5 16.8333 25.5C17.3333
             25.5 17.7639 25.3257 18.125 24.977C18.4861 24.6552 18.6667 24.2663 18.6667 23.8103C18.6667 23.3276 18.4861 22.9253 18.125 22.6034C17.7639 22.2548 17.3333 22.0805 16.8333 22.0805C16.3056 22.0805 15.8472 22.2548 15.4583 22.6034C15.0972 22.9253 14.9167 23.3276 14.9167 23.8103C14.9167 24.2663 15.0972 24.6552 15.4583 24.977Z"
      fill={background}
    />
  </svg>
);

export default QuestionMarkIcon;
