import React from "react";
import { useBriefBuilder } from "#containers/BriefBuilder/BriefBuilderProvider";
import useBriefBuilderActions from "#containers/BriefBuilder/hooks/useBriefBuilderActions";
import { Button, CircularProgress, IconButton, styled } from "@mui/material";
import { BriefBuilderStep } from "#customTypes/briefBuilder";
import IconChevronRight from "#assets/svgs/chevron-right.svg?react";
import IconClose from "#assets/svgs/close.svg?react";
import IconFloppyDisk from "#assets/svgs/floppy-disk.svg?react";
import IconEye from "#assets/svgs/eye.svg?react";
import { LoadingButton } from "#shared/components/ui";

export default function BuilderMobileActions() {
  const { isStepValid, activeStep } = useBriefBuilder();
  const { handleViewBrief, handleContinue, handleSave, mainAction, isLoading } =
    useBriefBuilderActions();
  const showContinue = mainAction === "continue";

  if (activeStep === BriefBuilderStep.Share) {
    return (
      <IconButton onClick={handleViewBrief}>
        <IconClose />
      </IconButton>
    );
  }

  if (showContinue) {
    return (
      <ContinueButton
        variant="contained"
        disabled={!isStepValid || isLoading}
        loading={isLoading}
        onClick={handleContinue}
      >
        <IconChevronRight />
      </ContinueButton>
    );
  }

  return (
    <>
      {activeStep === BriefBuilderStep.Survey && (
        <Button
          sx={{ padding: "7px", minWidth: "unset" }}
          variant="outlined"
          disabled={!isStepValid || isLoading}
          onClick={() => handleSave({ isSurveyPreviewInit: true })}
        >
          {isLoading ? <CircularProgress size={24} /> : <IconEye />}
        </Button>
      )}
      <Button
        color="secondary"
        disabled={!isStepValid || isLoading}
        onClick={() => handleSave()}
      >
        <IconFloppyDisk />
      </Button>
    </>
  );
}

const ContinueButton = styled(LoadingButton)`
  width: 40px;
  height: 40px;
  min-width: 40px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
