import React from "react";
import { FormattedMessage } from "react-intl";
import { styled } from "@mui/material/styles";

import { Button } from "#shared/components/ui/Button";
import { AvenirAndHeavyText } from "../../../../common/styled";
import useOauthStrategy from "../useOauthStrategy";
import colors from "#shared/config/theme/colors";

const GuestButton = styled(Button)`
  flex: 1;
  background: transparent;
  border: 1px solid #000;

  &:hover {
    background: ${colors.input.backgroundFocused};
  }
`;

const GuestLogin = () => {
  const { onClickOauthStrategy, shouldAllowGuests } = useOauthStrategy();

  if (!shouldAllowGuests) {
    return null;
  }
  return (
    <GuestButton onClick={() => onClickOauthStrategy()}>
      <AvenirAndHeavyText className="guest">
        <FormattedMessage id={`pages.login.guest`} />
      </AvenirAndHeavyText>
    </GuestButton>
  );
};

export default GuestLogin;
