import { css, styled } from "@mui/material";

type AvenirAndHeavyTextProps = {
  width?: string;
  fontSize?: string;
  fontFamily?: string;
  color?: string;
  textAlign?: string;
  lineHeight?: string;
  fontWeight?: number;
  padding?: string;
  margin?: string;
  maxWidth?: string;
};

const Link = styled("a")(
  ({ theme }) => `
font-family: "Avenir-Heavy";
font-size: 0.938rem;
font-weight: 520;
color: ${theme.palette.primary.main};
text-decoration: none;

&:hover {
  opacity: 0.75;
  transition: 0.25s all;
}
`
);

const AvenirAndHeavyText = styled("div")<AvenirAndHeavyTextProps>(
  ({
    fontFamily = "Avenir",
    fontSize,
    fontWeight = 400,
    width,
    color = "black",
    textAlign = "center",
    padding = "inherit",
    lineHeight = "1.5rem",
    margin = "0",
  }) => `
    text-align: ${textAlign};
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
    color: ${color};
    width: ${width};
    font-size: ${fontSize || "1.063rem"};
    padding: ${padding};
    margin: ${margin};
    
    &.heavy, .heavy {
      font-family: Avenir-Heavy;
    }
  })
`
);

const AvenirTitle = styled("div")<AvenirAndHeavyTextProps>(
  ({
    fontFamily = "Avenir",
    fontSize,
    fontWeight = 800,
    width,
    color = "black",
    textAlign = "center",
    lineHeight,
    padding,
    margin,
  }) => `
  font-size: ${fontSize};
  font-family: ${fontFamily};
  text-align: ${textAlign};
  color: ${color};
  font-weight: ${fontWeight};
  width: ${width};
  line-height: ${lineHeight};
  padding: ${padding};
  margin: ${margin};
`
);

const centeredWrapperBase = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  padding: 0rem;

  .buttonContainer {
    display: flex;
    gap: 0.625rem;
    position: relative;
    width: 100%;

    .circularProgress {
      color: white;
    }
  }

  .headerWrapper {
    position: relative;
  }
`;

const WrapperCentered = styled("div")(centeredWrapperBase);
const WrapperCenteredForm = styled("form")(centeredWrapperBase);

type CircleProps = {
  diameter: number;
  background?: string;
  border?: string;
};
const Circle = styled("div")<CircleProps>(
  ({ diameter = 2, background = "white", border }) => `
    width: ${diameter}rem;
    height: ${diameter}rem;
    border: ${border};
    border-radius: 50%;
    background: ${background};
    transition: all 0.3s;
`
);

type CircleImageProps = {
  diameter: number;
  background?: string;
  border?: string;
};
const CircleImage = styled("img")<CircleImageProps>(
  ({ diameter = 2, background = "white", border = "none" }) => `
    width: ${diameter}rem;
    height: ${diameter}rem;
    border: ${border};
    border-radius: 50%;
    position: relative;
    background: ${background};
    object-fit: cover;
`
);

const PopperMenuWrapper = styled("div")`
  .title {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  & .MuiList-root {
    padding: 0;
  }

  & .MuiMenuItem-root {
    padding-bottom: 0;
    padding-top: 0;

    & .MuiListItemText-root {
      flex: 0;
    }
  }
`;

const LoginWrapperBase = styled("div")`
  max-width: 24rem;
`;

export {
  AvenirAndHeavyText,
  WrapperCentered,
  WrapperCenteredForm,
  AvenirTitle,
  Link,
  Circle,
  CircleImage,
  PopperMenuWrapper,
  LoginWrapperBase,
};
