import ReportDialog from "#components/common/Dialog/ReportDialog";
import { ReportBriefError } from "#customTypes/brief";
import { BaseModalProps } from "#customTypes/modals";
import { useReportChannelMutation } from "#features/channel/channelsAPI";
import { getAxiosErrorMessage } from "#utils/serviceHelpers";
import useToast from "#shared/hooks/useToast";
import useTranslation from "#utils/useTranslation";
import React from "react";

interface Props extends BaseModalProps {
  channelRef: string;
}

export default function ReportChannelModal({ channelRef, isOpen, onClose }: Props) {
  const { t, p } = useTranslation();
  const toast = useToast();

  const [reportChannel, { isLoading: isLoadingReport }] = useReportChannelMutation();

  const handleConfirmReport = async (description: string) => {
    if (!channelRef || !description) {
      return;
    }

    try {
      await reportChannel({ channelRef, description }).unwrap();
      toast.success({ message: t("ui.toasts.report_success") });
      onClose();
    } catch (err) {
      const error = getAxiosErrorMessage(err);

      if (error?.includes(ReportBriefError.RateLimit)) {
        toast.error({ message: t("ui.toasts.report_rate_limit") });
        return;
      }

      toast.error();
    }
  };

  return (
    <ReportDialog
      title={t("ui.channel_actions.modals.report_modal.title")}
      content={p("ui.channel_actions.modals.report_modal.description")}
      isOpen={isOpen}
      label={t("ui.channel_actions.modals.report_modal.label")}
      placeholder={t("ui.channel_actions.modals.report_modal.placeholder")}
      isLoading={isLoadingReport}
      onConfirm={handleConfirmReport}
      onClose={onClose}
    />
  );
}
