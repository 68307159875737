import { styled } from "@mui/material/styles";

const WrapperDownload = styled("div")(
  ({ theme }) => `
  ${theme.breakpoints.up("md")} {
    border: .063rem solid #bdbdbd;
   }  
  border-radius: 0.625rem;
`
);

const DownloadHeader = styled("div")(
  ({ theme }) => `
  font-family: Avenir-Heavy;
  font-size: 1.625rem;
  line-height: 3.5rem;
  text-align: center;

  ${theme.breakpoints.up("md")} {
    border-bottom: .063rem solid #bdbdbd;
   } 
  width: 100%;
`
);

const DownloadContent = styled("div")`
  padding: 1.25rem 1.875rem 1.25rem 1.875rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;

  .qrCode {
    width: 12.5rem;
    height: 12.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const DownloadText = styled("div")`
  font-size: 1rem;
  font-family: Avenir-Heavy;
`;

const BriefLinkQRCodeWrapper = styled("div")`
  width: 12.5rem;
  height: 12.5rem;
  position: relative;

  > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .skeleton {
    transform: none;
    border-radius: 0;

    &::before {
      display: none;
    }
  }
`;

const ButtonsWrapper = styled("div")`
  display: flex;
  column-gap: 0.5rem;
`;

const Login = {
  Wrapper: styled("div")`
    display: flex;
    flex-direction: column;
    padding: 0.625rem 0.313rem;
    row-gap: 1rem;

    border-top: 0.063rem solid #bdbdbd;
    font-family: Avenir-Roman;
    text-align: center;
    line-height: 3.5rem;
  `,

  Header: styled("div")`
    font-family: Avenir-Heavy;
    font-size: 1.0625rem;
  `,

  Subheader: styled("div")`
    font-family: Avenir-Roman;
    font-size: 0.875rem;
  `,

  Property: styled("div")`
    font-size: 1.063rem;
    font-family: Avenir-Heavy;
  `,
};

export {
  WrapperDownload,
  DownloadHeader,
  DownloadContent,
  DownloadText,
  Login,
  BriefLinkQRCodeWrapper,
  ButtonsWrapper,
};
