import React, { ReactNode } from "react";

import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

import colors from "../../../config/theme/colors";
import FormLabel from "./FormLabel";

interface Props {
  label?: string;
  name?: string;
  helperText?: ReactNode;
  optional?: boolean;
  children?: ReactNode;
}

export default function FormField({
  label,
  name,
  helperText,
  optional,
  children,
}: Props) {
  return (
    <Wrapper>
      <Box>
        {label && <FormLabel label={label} name={name} optional={optional} />}
        {helperText && (
          <Typography variant="label2" color={colors.text.tertiaryDark}>
            {helperText}
          </Typography>
        )}
      </Box>
      {children}
    </Wrapper>
  );
}

export const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
