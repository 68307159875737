import { styled } from "@mui/material/styles";

type CircleProps = {
  diameter: number;
  background?: string;
  border?: string;
};
const Circle = styled("div")(
  ({ diameter = 2, background = "white", border }: CircleProps) => `
    width: ${diameter}rem;
    height: ${diameter}rem;
    border: ${border};
    border-radius: 50%;
    background: ${background};
`
);

export default Circle;
