import React from "react";
import AIChatWelcomeScreen from "#components/common/AIChat/components/AIChatWelcomeScreen";
import AIChatMessage from "#components/common/AIChat/components/message";
import { AIChatMessageProps, AIChatRole } from "#components/common/AIChat/types";
import { Box, styled } from "@mui/material";
import classNames from "classnames";

interface Props {
  messages: AIChatMessageProps[];
  loading?: boolean;
  onSelectTemplate: (template: string) => void;
  onClose?: () => void;
}

export default function AIChatContent({
  messages,
  loading,
  onSelectTemplate,
  onClose,
}: Props) {
  const content = () => {
    if (!messages.length) {
      return <AIChatWelcomeScreen onSelectTemplate={onSelectTemplate} />;
    }

    return (
      <>
        {messages.map((message, index) => (
          <AIChatMessage
            key={index}
            {...message}
            onClose={onClose}
            isLastMessage={index === messages.length - 1}
          />
        ))}
        {loading && (
          <AIChatMessage
            role={AIChatRole.Assistant}
            content=""
            loading
            isLastMessage={false}
          />
        )}
      </>
    );
  };

  return (
    <Wrapper className={classNames("chat-messages", !messages.length && "-empty")}>
      {content()}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 500px;
  justify-content: flex-end;
  flex: 1;

  &.-empty {
    justify-content: center;
    align-items: center;
  }
`;
