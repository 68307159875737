import { Theme } from "@mui/material";
import { ComponentOverrides } from "../types";
import colors from "../../theme/colors";
import rounding from "../../theme/rounding";

const alertOverrides = (theme: Theme): ComponentOverrides<"MuiAlert"> => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      background: colors.base.white,
      border: `1px solid ${colors.base.outline1}`,
      borderRadius: rounding.lg,
      padding: "12px 16px",
      alignItems: "center",
    },
    message: {
      padding: `2px 0`,
      textAlign: "center",
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      flex: 1,
    },
    standardSuccess: {
      border: `1px solid ${colors.success.default}`,
      backgroundColor: colors.success[100],
    },
    standardError: {
      border: `1px solid ${colors.error.default}`,
      backgroundColor: colors.error[100],
    },
    standardWarning: {
      border: `1px solid ${colors.warning.default}`,
      backgroundColor: colors.warning[100],
    },
    filled: {
      color: colors.text.primaryDark,
      border: "none",
      fontWeight: 400,
    },
    filledSuccess: {
      backgroundColor: colors.success[100],
    },
    filledError: {
      backgroundColor: colors.error[100],
    },
    filledWarning: {
      backgroundColor: colors.warning[100],
    },
  },
});

export default alertOverrides;
