import { AIChatTemplateForm } from "#components/common/AIChat/hooks/useAIChatTemplateForm";
import {
  AIChatMessageMetadata,
  AIChatMessageType,
  AIImageGenerationError,
} from "#components/common/AIChat/types";
import { Country } from "#customTypes/db";
import { TranslationKey } from "#customTypes/translation";

const BOLDED_PHRASES = [
  "Brief title:",
  "Objectives:",
  "Target audience:",
  "Methodology:",
];

export const formatAIChatMessage = (
  message: string,
  metadata?: AIChatMessageMetadata
) => {
  // Bold certain phrases
  let transformed = message.replace(
    new RegExp(BOLDED_PHRASES.join("|"), "g"),
    (match) => `**${match}**\n`
  );

  // Remove quotes from brief template title
  if (metadata?.type === AIChatMessageType.BriefTemplate && metadata.data?.title) {
    transformed = transformed.replace(`"${metadata.data.title}"`, metadata.data.title);
  }

  return transformed;
};

export const formatAIChatTemplatePrompt = (
  data: AIChatTemplateForm,
  countries: Country[]
) => {
  return `Change the following data (don't change the response format):\n
  Brief title: ${data.title}
  Objectives:
  ${data.prompt}
  Age: ${data.minAge} - ${data.maxAge}
  Genders: ${data.gender.join(", ") || "All"}
  Countries: ${
    countries
      .filter((c) => data.countries.includes(c.countryId))
      .map((c) => c.name)
      .join(", ") || "Global"
  }
  Methodology: ${data.responseType}
  `;
};

export const mapAIImageError = (error: string): TranslationKey | undefined => {
  if (error.includes(AIImageGenerationError.InappropriatePrompt)) {
    return "errors.ai_generation_inaproppriate_prompt";
  }

  if (error.includes(AIImageGenerationError.RateLimit)) {
    return "errors.ai_generation_rate_limit";
  }

  return undefined;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
