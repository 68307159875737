import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../utils";
import {
  selectDownloadModal,
  setDownloadModal,
} from "#features/downloadModal/downloadModalSlice";
import { setJoinBriefId } from "../../features/joinBriefId/joinBriefIdSlice";

export default function useDownloadModal() {
  const dispatch = useDispatch();
  const open = useSelectorTyped(selectDownloadModal);
  const onClose = () => {
    dispatch(setDownloadModal(false));
    dispatch(setJoinBriefId(null));
  };

  return {
    open,
    onClose,
  };
}
