import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import colors from "#shared/config/theme/colors";
import commonTheme from "#shared/config/ui/theme";
import { createColorShades } from "#shared/utils";
import { componentsOverrides } from "#shared/config/ui/components";

export const getDefaultPrimary = (color?: string): string => {
  /* temporarily implemented to allow testing of different colors */
  if (typeof window !== "undefined" && sessionStorage.getItem("primaryColor")) {
    const primary = sessionStorage.getItem("primaryColor");
    if (typeof primary === "string" && primary.length) {
      return primary;
    }
  }
  return color || colors.brand.default;
};

interface Args {
  primaryColor?: string;
}

export const useCreateTheme = (args: Args) => {
  const theme = useMemo(() => {
    const primaryColor = createColorShades(args.primaryColor || colors.brand.default);

    const baseTheme = createTheme({
      ...commonTheme,
      palette: {
        ...commonTheme.palette,
        primary: primaryColor,
        action: {
          disabledBackground: `${primaryColor.main}ca`,
        },
      },
    });

    return createTheme({
      ...baseTheme,
      components: {
        ...baseTheme.components,
        ...componentsOverrides(baseTheme),
      },
    });
  }, [args]);

  return theme;
};
