import React from "react";
import { styled } from "@mui/material/styles";

import BulbshareHeaderLogo from "#assets/svgs/bulbshare-header-logo.svg?react";
import { Link } from "react-router-dom";
import { useSelectorTyped } from "#utils/index";
import { selectUser } from "#features/user/userSlice";
import useWlaOrganisation from "#utils/useWlaOrganisation";

const BespokeLogo = styled("img")`
  height: 30px;
  width: auto;
  max-width: 300px;
`;

const HeaderLogo = () => {
  const { organisation } = useWlaOrganisation();
  const user = useSelectorTyped(selectUser);

  return (
    <Link className="logo-link" to={user.isLoggedIn ? "/" : "/signin"}>
      {organisation?.logo ? (
        <BespokeLogo src={organisation?.logo} alt="Organisation logo" />
      ) : (
        <BulbshareHeaderLogo />
      )}
    </Link>
  );
};

export default HeaderLogo;
