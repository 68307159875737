import {
  Button,
  CircularProgress,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import CloseIcon from "#assets/svgs/close-dialog.svg?react";

interface Props {
  value: string;
  placeholder?: string;
  debounce?: number;
  loading?: boolean;
  sx?: SxProps<Theme>;
  onChange: (value: string) => void;
}

export default function SearchField({
  value,
  placeholder,
  debounce = 600,
  loading = true,
  sx,
  onChange,
}: Props) {
  const [internalValue, setInternalValue] = useState(value);
  const [debouncedValue] = useDebounceValue(internalValue, debounce);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
  };

  const handleClear = () => {
    if (loading) {
      return;
    }

    setInternalValue("");
  };

  return (
    <TextField
      id="search"
      name="search"
      autoComplete="off"
      placeholder={placeholder}
      value={internalValue}
      InputProps={{
        endAdornment: (
          <Button
            variant="cta"
            onClick={handleClear}
            sx={{ display: internalValue ? "block" : "none" }}
          >
            {loading ? (
              <StyledLoader size={20} />
            ) : (
              <InputAdornment position="end">
                <DeleteIcon />
              </InputAdornment>
            )}
          </Button>
        ),
      }}
      onChange={handleSearch}
      sx={sx}
    />
  );
}

const DeleteIcon = styled(CloseIcon)`
  margin-right: -4px;
  g {
    path {
      fill: #00000090;
    }
  }
`;

const StyledLoader = styled(CircularProgress)`
  margin-right: 0.5rem;
  margin-top: 0.375rem;
`;
