import { useBriefBuilder } from "#containers/BriefBuilder/BriefBuilderProvider";
import { useGlobalAppProvider } from "#containers/Global/GlobalAppProvider";
import { AdminBriefStatus } from "#customTypes/brief";
import { useMemo } from "react";

export default function useBriefBuilderPermissions() {
  const { brief } = useBriefBuilder();
  const { positions } = useGlobalAppProvider();

  const isBusinessOrganisation = useMemo(() => {
    const position = positions.find(
      (position) => position.organisation.id === brief?.organisationId
    );

    if (!position) {
      return false;
    }

    return !position.organisation.isPersonalOrganisation;
  }, [brief, positions]);

  const isDraftBrief = useMemo(() => {
    if (!brief) {
      return true;
    }

    return brief.status === AdminBriefStatus.Draft;
  }, [brief?.status]);

  const isScheduledBrief = useMemo(() => {
    if (!brief) {
      return false;
    }

    return brief.status === AdminBriefStatus.Scheduled;
  }, [brief?.status]);

  return {
    isBusinessOrganisation,
    isDraftBrief,
    isScheduledBrief,
    isLaunchedBrief: !isDraftBrief && !isScheduledBrief,
  };
}
