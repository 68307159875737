import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { ReactNode, createContext, useContext, useMemo } from "react";

interface Props {
  isMobile: boolean;
  isTabletOrMobile: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
}

const MediaQueryContext = createContext<Props>({
  isMobile: false,
  isTabletOrMobile: false,
  isDesktop: false,
  isLargeDesktop: false,
});

export function useMediaQueryProvider(): Props {
  const context = useContext(MediaQueryContext);
  if (!context) {
    throw new Error("useMediaQueryProvider must be used within a MediaQueryProvider");
  }
  return context;
}

type ProviderProps = {
  children: ReactNode;
};

export function MediaQueryProvider({ children }: ProviderProps) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const providerValue = useMemo(
    () => ({
      isMobile,
      isTabletOrMobile,
      isDesktop,
      isLargeDesktop,
    }),
    [isMobile, isTabletOrMobile, isDesktop, isLargeDesktop]
  );

  return (
    <MediaQueryContext.Provider value={providerValue}>
      {children}
    </MediaQueryContext.Provider>
  );
}

export const MediaQueryConsumer = MediaQueryContext.Consumer;
