/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BriefBuilderStep,
  BriefBuilderData,
  SurveyPreviewStep,
} from "#customTypes/briefBuilder";
import { useLocation, useParams } from "react-router";
import { getCurrentStepFromPathname } from "#containers/BriefBuilder/Header/Steps/helper";

interface ContextProps {
  brief?: BriefBuilderData;
  briefRef: string;
  steps: BriefBuilderStep[];
  activeStep: BriefBuilderStep;
  formValues: object;
  hasChanges: boolean;
  isStepValid: boolean;
  isSavingMedia: boolean;
  isSavingSurvey: boolean;
  surveyPreviewStep: SurveyPreviewStep;
  setIsSavingMedia: (val: boolean) => void;
  setIsSavingSurvey: (val: boolean) => void;
  setIsStepValid: (val: boolean) => void;
  setFormValues: (val: object) => void;
  setHasChanges: (val: boolean) => void;
  openDiscardModal?: () => void;
  setSurveyPreviewStep: (val: SurveyPreviewStep) => void;
}

const BriefBuilderContext = createContext<ContextProps>({
  brief: undefined,
  briefRef: "",
  formValues: {},
  steps: [],
  activeStep: BriefBuilderStep.Brief,
  hasChanges: false,
  isStepValid: false,
  isSavingMedia: false,
  isSavingSurvey: false,
  surveyPreviewStep: SurveyPreviewStep.idle,
  setHasChanges: () => {},
  setIsSavingMedia: () => {},
  setIsSavingSurvey: () => {},
  setIsStepValid: () => {},
  setFormValues: () => {},
  setSurveyPreviewStep: () => {},
});

interface ProviderProps {
  brief?: BriefBuilderData;
  steps?: BriefBuilderStep[];
  children: ReactNode;
}

export default function BriefBuilderProvider({ brief, steps, children }: ProviderProps) {
  const { pathname } = useLocation();
  const { briefRef } = useParams();

  const [formValues, setFormValues] = useState<object>({});
  const [isSavingMedia, setIsSavingMedia] = useState<boolean>(false);
  const [isSavingSurvey, setIsSavingSurvey] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isStepValid, setIsStepValid] = useState<boolean>(false);
  const [surveyPreviewStep, setSurveyPreviewStep] = useState(SurveyPreviewStep.idle);

  const [activeStep, setActiveStep] = useState<BriefBuilderStep>(
    getCurrentStepFromPathname(pathname)
  );

  useEffect(() => {
    // Reset step
    const newStep = getCurrentStepFromPathname(pathname);
    setActiveStep(newStep);

    if (newStep === BriefBuilderStep.Survey) {
      setIsStepValid(false);
    }
  }, [pathname]);

  useEffect(() => {
    // Fallback if we receive no event
    if (isSavingSurvey) {
      setTimeout(() => {
        setIsSavingSurvey(false);
      }, 5000);
    }
  }, [isSavingSurvey]);

  const providerValue = useMemo(
    () => ({
      brief,
      briefRef,
      steps: steps || [],
      activeStep,
      formValues,
      hasChanges,
      isStepValid,
      isSavingMedia,
      isSavingSurvey,
      surveyPreviewStep,
      setFormValues,
      setHasChanges,
      setIsSavingMedia,
      setIsSavingSurvey,
      setIsStepValid,
      setSurveyPreviewStep,
    }),
    [
      brief,
      steps,
      briefRef,
      activeStep,
      formValues,
      hasChanges,
      isStepValid,
      isSavingMedia,
      isSavingSurvey,
      surveyPreviewStep,

      setFormValues,
      setHasChanges,
      setIsStepValid,
      setIsSavingMedia,
      setIsSavingSurvey,
      setSurveyPreviewStep,
    ]
  );

  return (
    <BriefBuilderContext.Provider value={providerValue}>
      {children}
    </BriefBuilderContext.Provider>
  );
}

export function useBriefBuilder(): ContextProps {
  const context = useContext(BriefBuilderContext);
  if (!context) {
    throw new Error("useBriefBuilder must be used within a BriefBuilderProvider");
  }
  return context;
}
