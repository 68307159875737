import { styled } from "@mui/material";
import { toRem } from "../../../utils";
import colors from "../../../config/theme/colors";

const ModalContent = styled("div")`
  padding: var(--modal-padding, ${toRem(20)});
  background: var(--modal-background, ${colors.base.white});
  overflow-y: auto;
  flex: 1;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-height: calc(90vh - 12rem);
  }
`;

export default ModalContent;
