import { LoadingButton } from "#shared/components/ui";
import colors from "#shared/config/theme/colors";
import { styled } from "@mui/material";

export default styled(LoadingButton)`
  color: ${colors.base.white};
  background: ${colors.gradient.ai.start};
  background-image: ${colors.gradient.ai.value};
  background-size: "100%";

  &:disabled {
    background-color: ${colors.grey[300]};
    color: ${colors.base.white};
    background-image: none;
  }
`;
