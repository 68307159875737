import InfoDialog from "#shared/components/ui/Dialog/InfoDialog";
import SelectGodViewOrganisation from "#containers/GodView/GodViewModal/SelectGodViewOrganisation";
import useGodView from "#containers/GodView/useGodView";
import useTranslation from "#utils/useTranslation";
import React from "react";

export default function GodViewGlobalListener() {
  const { t } = useTranslation();
  const { isModalOpen, organisation, closeModal } = useGodView();

  return (
    <InfoDialog
      title={t("ui.god_view.select_organisation.title")}
      content={null}
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <SelectGodViewOrganisation value={organisation?.id} />
    </InfoDialog>
  );
}
