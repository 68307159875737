import { uploadEditorImage } from "#components/common/Editor/utils/imageUpload";
import useTranslation from "#utils/useTranslation";
import { useState } from "react";
import { useParams } from "react-router";

export default function useEditorImageUpload() {
  const { t } = useTranslation();
  const { briefRef } = useParams();

  const [image, setImage] = useState<{ url: string; id: string } | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleImageChange = async (files: File[]) => {
    try {
      setIsLoading(true);

      const imageUrl = URL.createObjectURL(files[0]);
      const { id, url } = await uploadEditorImage(imageUrl, briefRef);

      setImage({
        id,
        url,
      });

      removeError();
    } catch (error) {
      setError(t("errors.image_upload_error"));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeImage = () => {
    setImage(undefined);
  };

  const removeError = () => {
    setError("");
  };

  const handleInternalError = () => {
    removeImage();
    setError(t("errors.file_not_supported"));
  };

  const handleRemoveImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    removeImage();
    removeError();
  };

  return {
    image,
    error,
    isLoading,
    handleImageChange,
    handleInternalError,
    handleRemoveImage,
  };
}
