import IconAtomSimple from "#assets/svgs/atom-simple.svg?react";
import IconChevronRight from "#assets/svgs/chevron-right.svg?react";
import IconEye from "#assets/svgs/eye.svg?react";
import Feedback from "#assets/svgs/feedback.svg?react";
import HomeEmpty from "#assets/svgs/home-empty.svg?react";
import Home from "#assets/svgs/home.svg?react";
import Logout from "#assets/svgs/logout.svg?react";
import IconPuzzle from "#assets/svgs/puzzle-solid.svg?react";
import Settings from "#assets/svgs/settings.svg?react";
import StarEmpty from "#assets/svgs/star-empty.svg?react";
import Star from "#assets/svgs/star.svg?react";
import StoreEmpty from "#assets/svgs/store-empty.svg?react";
import Store from "#assets/svgs/store.svg?react";
import Team from "#assets/svgs/team.svg?react";
import Profile from "#assets/svgs/view_profile.svg?react";
import { NavigationAction } from "#constants/navigation";
import { NavigationItems } from "../../../types/types";
import { styled } from "@mui/material/styles";

const activeStyles = `
  path {
    fill: currentColor;
  }
`;

const ActiveHome = styled(Home)(activeStyles) as React.FC<React.SVGProps<SVGSVGElement>>;
const ActiveStar = styled(Star)(activeStyles) as React.FC<React.SVGProps<SVGSVGElement>>;
const ActiveStore = styled(Store)(activeStyles) as React.FC<
  React.SVGProps<SVGSVGElement>
>;
const ActiveTeam = styled(Team)(activeStyles) as React.FC<React.SVGProps<SVGSVGElement>>;

export const mainNavigationItems: NavigationItems = [
  {
    id: NavigationAction.foryou,
    LogoActive: ActiveHome,
    LogoInactive: HomeEmpty,
    name: "navigation.menu.for_you",
  },
  {
    id: NavigationAction.following,
    LogoActive: ActiveStar,
    LogoInactive: StarEmpty,
    name: "navigation.menu.following",
  },
  {
    id: NavigationAction.businesslab,
    LogoActive: ActiveStore,
    LogoInactive: StoreEmpty,
    name: "navigation.menu.business_lab",
    label: "navigation.labels.apply",
  },
  {
    id: NavigationAction.businesslab_external,
    LogoActive: ActiveStore,
    LogoInactive: StoreEmpty,
    name: "navigation.menu.business_lab",
  },
  {
    id: NavigationAction.knowledgeAi,
    LogoActive: IconAtomSimple,
    LogoInactive: IconAtomSimple,
    name: "navigation.menu.knowledge_ai",
    label: "navigation.labels.beta",
  },
];

export const profileNavigationItems: NavigationItems = [
  {
    id: NavigationAction.profile,
    LogoActive: Profile,
    LogoInactive: Profile,
    name: "navigation.menu.my_profile",
  },
  {
    id: NavigationAction.business_settings,
    LogoActive: ActiveStore,
    LogoInactive: StoreEmpty,
    name: "navigation.menu.business_settings",
  },
  {
    id: NavigationAction.business_centre,
    LogoActive: ActiveStore,
    LogoInactive: StoreEmpty,
    name: "navigation.menu.business_centre",
  },
  {
    id: NavigationAction.settings,
    LogoActive: Settings,
    LogoInactive: Settings,
    name: "navigation.menu.settings",
  },
  {
    id: NavigationAction.god_view,
    LogoActive: IconEye,
    LogoInactive: IconEye,
    ActionIcon: IconChevronRight,
    name: "navigation.menu.view_as",
  },
  {
    id: NavigationAction.god_view_wla,
    LogoActive: IconEye,
    LogoInactive: IconEye,
    name: "navigation.menu.view_as_wla",
  },
  {
    id: NavigationAction.feedback_help,
    LogoActive: Feedback,
    LogoInactive: Feedback,
    name: "navigation.menu.feedback_help",
  },
  {
    id: NavigationAction.logout,
    LogoActive: Logout,
    LogoInactive: Logout,
    name: "navigation.menu.logout",
  },
];

export const businessNavigationItems: NavigationItems = [
  {
    id: NavigationAction.business_account,
    LogoActive: ActiveStore,
    LogoInactive: StoreEmpty,
    name: "navigation.menu.business_account",
  },
  {
    id: NavigationAction.team_management,
    LogoActive: ActiveTeam,
    LogoInactive: ActiveTeam,
    name: "navigation.menu.team_management",
  },
  {
    id: NavigationAction.business_integrations,
    LogoActive: IconPuzzle,
    LogoInactive: IconPuzzle,
    name: "navigation.menu.integrations",
  },
];
