import { ComponentOverrides } from "../../types";

const accordionDetailsOverrides = (): ComponentOverrides<"MuiAccordionDetails"> => ({
  styleOverrides: {
    root: {
      padding: "1rem",
    },
  },
});

export default accordionDetailsOverrides;
