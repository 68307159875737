import { BriefBuilderApi } from "#apis/briefBuilder/api";
import { mapAIImageError } from "#components/common/AIChat/utils";
import {
  GenerateBriefImageRequest,
  GenerateBriefImageResponse,
} from "#customTypes/briefBuilder";
import { AppMutationOptions } from "#customTypes/reactQuery";
import { getAxiosErrorMessage } from "#utils/serviceHelpers";
import useToast from "#shared/hooks/useToast";
import useTranslation from "#utils/useTranslation";
import { useMutation } from "@tanstack/react-query";

export default function useGenerateBriefImageMutation(
  options?: AppMutationOptions<GenerateBriefImageRequest, GenerateBriefImageResponse>
) {
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (params) => BriefBuilderApi.generateImage(params),
    ...options,
    onError: (err, variables, context) => {
      options?.onError?.(err, variables, context);
      const axiosError = getAxiosErrorMessage(err);
      const error = mapAIImageError(axiosError || "");
      toast.error({ message: error ? t(error) : "" });
    },
  });
}
