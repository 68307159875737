type QueryResult = {
  isLoading: boolean;
  isFetching: boolean;
  data?: any;
};

export default function useQueryLoading(queryResults: QueryResult[]): boolean {
  return queryResults.some(
    ({ isLoading, isFetching, data }) => isLoading || (isFetching && !data)
  );
}
