import { ComponentOverrides } from "../../types";

const paginationOverrides = (): ComponentOverrides<"MuiPagination"> => ({
  styleOverrides: {
    ul: {
      justifyContent: "center",
    },
  },
});

export default paginationOverrides;
