export const INTERESTS = [
  {
    value: 1,
    label: "demographics.interests.music",
  },
  {
    value: 2,
    label: "demographics.interests.acting",
  },
  {
    value: 3,
    label: "demographics.interests.photography",
  },
  {
    value: 4,
    label: "demographics.interests.illustration",
  },
  {
    value: 5,
    label: "demographics.interests.social_impact",
  },
  {
    value: 6,
    label: "demographics.interests.technology",
  },
  {
    value: 7,
    label: "demographics.interests.gaming",
  },
  {
    value: 8,
    label: "demographics.interests.writing",
  },
  {
    value: 9,
    label: "demographics.interests.films_tv",
  },
  {
    value: 10,
    label: "demographics.interests.partying",
  },
  {
    value: 11,
    label: "demographics.interests.media",
  },
  {
    value: 12,
    label: "demographics.interests.filmmaking",
  },
  {
    value: 13,
    label: "demographics.interests.networking",
  },
  {
    value: 14,
    label: "demographics.interests.graphic_design",
  },
  {
    value: 15,
    label: "demographics.interests.art",
  },
  {
    value: 16,
    label: "demographics.interests.politics",
  },
  {
    value: 17,
    label: "demographics.interests.animation",
  },
  {
    value: 18,
    label: "demographics.interests.sports",
  },
  {
    value: 19,
    label: "demographics.interests.blogging",
  },
  {
    value: 20,
    label: "demographics.interests.cooking",
  },
  {
    value: 21,
    label: "demographics.interests.festivals",
  },
  {
    value: 22,
    label: "demographics.interests.reading",
  },
  {
    value: 23,
    label: "demographics.interests.architecture",
  },
  {
    value: 24,
    label: "demographics.interests.fitness",
  },
  {
    value: 25,
    label: "demographics.interests.spirituality",
  },
  {
    value: 26,
    label: "demographics.interests.science",
  },
  {
    value: 27,
    label: "demographics.interests.vlogging",
  },
  {
    value: 28,
    label: "demographics.interests.fashion",
  },
  {
    value: 29,
    label: "demographics.interests.presenting",
  },
  {
    value: 30,
    label: "demographics.interests.travel",
  },
];
