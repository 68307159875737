export enum NavigationAction {
  foryou = "feed/foryou",
  following = "feed/following",
  businesslab = "business-lab",
  businesslab_external = "https://admin.bulbshare.<tld>/2/projects/index.html?orgId=:organisationId",
  knowledgeAi = "https://knowledgeai.bulbshare.<tld>/",
  business_centre = "business-centre",
  business_account = "business-centre/:organisationId",
  team_management = "business-centre/:organisationId/team-management",
  business_settings = "business-centre/:organisationId/settings",
  business_integrations = "business-centre/:organisationId/integrations",
  profile = "#getApp_profile",
  settings = "#getApp_settings",
  god_view = "#godView",
  god_view_wla = "#godViewWla",
  feedback_help = "#feedback_help",
  logout = "#logout",
  exit_builder = "#exit_builder",
}
