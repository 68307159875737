import React from "react";
import { WrapperDesktop } from "./styled";
import { Box, Divider } from "@mui/material";
import MenuLinks from "#components/pages/Feed/MenuLinks";
import Navigation from "#containers/layout/Navigation";

import SelectOrganisation from "#components/pages/BusinessAccount/components/SelectOrganisation";
import PrivateChannels from "#components/pages/Feed/ChannelsSidebar/DesktopChannels/PrivateChannels";
import { FormField } from "#shared/components/ui";
import { useIntl } from "react-intl";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import MyChannels from "#components/pages/Feed/ChannelsSidebar/DesktopChannels/MyChannels";
import useGodView from "#containers/GodView/useGodView";
import useMyOwnedChannels from "#components/pages/Feed/ChannelsSidebar/useMyOwnedChannels";
import useMyFollowedChannels from "#components/pages/Feed/ChannelsSidebar/useMyFollowedChannels";

interface Props {
  isBusinessCentre?: boolean;
}

export default function DesktopSideNavigation({ isBusinessCentre }: Props) {
  const intl = useIntl();
  const { isMobile } = useMediaQueryProvider();
  const { isEnabled: isGodViewEnabled } = useGodView();

  const { channels: ownedChannels } = useMyOwnedChannels();
  const { channels: followedChannels, isLoading: isLoadingFollowedChannels } =
    useMyFollowedChannels();

  const hasChannels =
    !!ownedChannels?.length ||
    !!followedChannels.length ||
    isGodViewEnabled ||
    isLoadingFollowedChannels;

  const showChannels = !isMobile && !isBusinessCentre && hasChannels;

  if (isMobile) {
    return null;
  }

  return (
    <WrapperDesktop businessCenter={isBusinessCentre} godView={isGodViewEnabled}>
      {isBusinessCentre && !isGodViewEnabled && (
        <>
          <FormField
            name="select_organisation"
            label={intl.formatMessage({
              id: "pages.business_settings.sections.your_organisation",
            })}
          >
            <SelectOrganisation outlined />
          </FormField>
          <Divider sx={{ mt: 6, mb: "1.5rem !important" }} />
        </>
      )}
      {/* default channels */}
      <div key={`filters_feed_default`} className="default">
        <Navigation />
      </div>
      <Divider />
      {/* private channels */}
      {showChannels && (
        <>
          <Box display="flex" flexDirection="column" gap={3} mb={3}>
            <div>
              <MyChannels />
            </div>
            {!isGodViewEnabled && (
              <div>
                <PrivateChannels />
              </div>
            )}
          </Box>
          <Divider />
        </>
      )}
      <MenuLinks />
    </WrapperDesktop>
  );
}
