export async function createImageUrlFromBase64(
  base64Image: string
): Promise<string | undefined> {
  const dataUrl = `data:image/jpeg;base64,${base64Image}`;

  try {
    const compressedImage = await reduceImageSize(dataUrl);
    return URL.createObjectURL(dataUrlToBlob(compressedImage));
  } catch (error) {
    console.error(error);
  }
}

function dataUrlToBlob(dataUrl: string): Blob {
  const [header, base64Data] = dataUrl.split(",");

  const binaryData = atob(base64Data);

  const len = binaryData.length;
  const uint8Array = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: header.split(":")[1].split(";")[0] });
}

const reduceImageSize = async (imageUrl: string): Promise<string> => {
  const canvas = document.createElement("canvas");
  if (!canvas) {
    throw new Error("Canvas not found");
  }
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Canvas context not found");
  }
  const image = new Image();

  const loadImage = (): Promise<void> =>
    new Promise((resolve, reject) => {
      image.onload = () => resolve();
      image.onerror = (err) => reject(err);
      image.src = imageUrl;
    });

  try {
    await loadImage();
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0);

    const compressedImageUrl = canvas.toDataURL("image/jpeg", 0.5); // 0.5 is the quality factor (0 to 1)
    return compressedImageUrl;
  } catch (error) {
    console.error("Error loading image:", error);
    throw error;
  }
};
