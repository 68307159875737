import { useCallback, useState } from "react";
import { EditorAITextTone } from "#components/common/Editor/types";
import useToast from "#shared/hooks/useToast";
import { Extension, NodeViewWrapperProps } from "@tiptap/react";
import { useTiptapEditorProvider } from "#components/common/Editor/context/TiptapEditorProvider";

export interface DataProps {
  text: string;
  addHeading: boolean;
  tone?: EditorAITextTone;
  textUnit?: string;
  textLength?: string;
  language?: string;
}

export default function useMagicWriter({
  editor,
  node,
  getPos,
  deleteNode,
}: NodeViewWrapperProps) {
  const toast = useToast();
  const { isAiLoading, setIsAiLoading } = useTiptapEditorProvider();

  const [data, setData] = useState<DataProps>({
    text: "",
    tone: undefined,
    textLength: undefined,
    addHeading: false,
    language: undefined,
  });

  const [previewText, setPreviewText] = useState<string | undefined>(undefined);

  const aiOptions = editor.extensionManager.extensions.find(
    (ext: Extension) => ext.name === "ai"
  ).options;

  const generateText = useCallback(async () => {
    const { text: prompt, tone, textLength, textUnit, addHeading, language } = data;

    const fullPrompt = `${prompt}\n\n
    USE ONLY THE FOLLOWING HTML TAGS TO FORMAT YOUR TEXT: [p, h2, strong, i, ol, ul, li, br]. DO NOT NEST LISTS. DO NOT USE <p> TAG BETWEEN <li> ELEMENTS. DO NOT USE ANY OTHER TAGS OR TEXT FORMATTING SUCH AS MARKDOWN.`;

    if (!data.text) {
      toast.error({ message: "Please enter a description" });
      return;
    }

    setIsAiLoading(true);

    try {
      const { baseUrl, appId, token } = aiOptions;
      const response = await fetch(`${baseUrl}/text/prompt`, {
        method: "POST",
        headers: {
          accept: "application.json",
          "Content-Type": "application/json",
          "X-App-Id": appId.trim(),
          Authorization: `Bearer ${token.trim()}`,
        },
        body: JSON.stringify({
          text: fullPrompt,
          textLength: textLength,
          textUnit: textUnit,
          useHeading: addHeading,
          tone,
          language,
        }),
      });

      const json = await response.json();
      const text = json.response;

      if (!text.length) {
        setIsAiLoading(false);
        return;
      }

      setPreviewText(text);
    } catch (_e) {
      toast.error();
    } finally {
      setIsAiLoading(false);
    }
  }, [data, aiOptions]);

  const handleConfirm = useCallback(() => {
    const from = getPos();
    const to = from + node.nodeSize;

    if (!previewText?.length) {
      return;
    }

    editor.chain().focus().insertContentAt({ from, to }, previewText).run();
  }, [editor, previewText, getPos, node.nodeSize]);

  const handleDiscard = useCallback(() => {
    deleteNode();
  }, [deleteNode]);

  const handlePromptChange = useCallback((text: string) => {
    setData((prevData) => ({ ...prevData, text }));
  }, []);

  const handleSelectTone = useCallback((tone: EditorAITextTone | undefined) => {
    return () => {
      setData((prevData) => ({ ...prevData, tone }));
    };
  }, []);

  return {
    data,
    isGenerating: isAiLoading,
    previewText,
    generateText,
    handleConfirm,
    handleDiscard,
    handlePromptChange,
    handleSelectTone,
  };
}
