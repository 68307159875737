import { BriefBuilderApi } from "#apis/briefBuilder/api";
import { GenerateBriefRequest, GenerateBriefResponse } from "#customTypes/briefBuilder";
import { AppMutationOptions } from "#customTypes/reactQuery";
import { useMutation } from "@tanstack/react-query";

export default function useGenerateBriefMutation(
  options?: AppMutationOptions<GenerateBriefRequest, GenerateBriefResponse>
) {
  return useMutation({
    mutationFn: (params) => BriefBuilderApi.generateBrief(params),
    ...options,
  });
}
